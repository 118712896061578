import React from 'react';
import { connect } from 'react-redux';

import history from '../services/history';
import { setMenuState } from '../actions/app-state.action';

const MenuButton = ({ name, link, isAuthorized, setMenuState }) => (
    <div>
        {
            isAuthorized &&
            <div className="row menu-btn-div">
                <button onClick={() => {
                    history.push(link);
                    setMenuState(false);
                }} className="btn btn-primary menu-btn">{name}</button>
            </div>
        }
    </div>
);

const checkIfAuthorized = (roles, userRole) => {
    if (roles.length < 0) {
        return false;
    }
    if (roles[0] === 'all') {
        return true;
    }
    return roles.includes(userRole);
};

const mapStateToProps = (state, ownProps) => ({
    isAuthorized: checkIfAuthorized(ownProps.roles, state.user.role)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    setMenuState: (menuState) => dispatch(setMenuState(menuState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);
