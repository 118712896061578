export default {
    // pages
    'admin-users': ['administrator'],
    'change-my-password': ['all'],
    'complimentary-codes': ['administrator', 'operations'],
    'customers': ['all'],
    'content-management': ['administrator', 'operations'],
    'channels': ['administrator', 'operations'],
    'channel-logos': ['administrator', 'operations'],
    'channel-tags': ['administrator', 'operations'],
    'vods': ['administrator', 'operations'],
    'customer-export': ['administrator', 'marketing'],
    'application-errors': ['administrator', 'operations'],
    'merchants': ['administrator', 'operations'],
    'reports': ['all'],
    'user-home': ['all'],
    'customer-sign-up-report': ['all'],
    'card-sales-report': ['administrator', 'finance'],
    'in-app-sales-report': ['administrator', 'finance'],
    'merchant-sales-report': ['administrator', 'finance'],
    'utm-sales-report': ['administrator', 'marketing'],
    'unverified-premium-customer-report': ['administrator', 'support'],
    'unpaid-premium-customer-report': ['administrator', 'support'],
    'view-customer': ['all'],
    // buttons
    'verify-customer': ['support', 'administrator'],
    'modify-name': ['support', 'administrator'],
    'modify-preferences': ['support', 'administrator'],
    'modify-password': ['support', 'administrator'],
    'reset-devices': ['support', 'administrator'],
    'upgrade-to-premium': ['support', 'finance', 'administrator'],
    'upgrade-to-complimentary': ['support', 'finance', 'administrator'],
    'end-complimentary': ['support', 'finance', 'administrator'],
    'suspend-customer': ['support', 'finance', 'administrator'],
    'unsuspend-customer': ['support', 'finance', 'administrator'],
    'cancel-subscription-on-last-day': ['support', 'finance', 'administrator'],
    'withdraw-cancellation': ['support', 'finance', 'administrator'],
    'cancel-subscription-without-refund': ['support', 'finance', 'administrator'],
    'cancel-subscription-with-refund': ['support', 'finance', 'administrator'],
    'add-complimentary-days': ['support', 'finance', 'administrator'],
    'change-credit-card': ['support', 'finance', 'administrator'],
    'modify-support-information': ['support', 'administrator'],
    'add-credit': ['support', 'finance', 'administrator'],
    'add-charge': ['support', 'finance', 'administrator']
};
