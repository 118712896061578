import React from 'react';
import toastr from 'toastr';
import ReactTable from 'react-table';

import * as api from '../services/api';
import history from '../services/history';

class Customers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            results: [],
            processing: false,
            formError: false,
            touched: false
        };
    }

    columns = [{
        Header: 'View',
        id: 'view',
        accessor: d => d.email,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 60,
        sortable: false,
        Cell: row => <button className="btn btn-xs btn-primary" onClick={() => {
            history.push('/view-customer/' + this.state.searchText + '/' + row.value);
        }}>View</button>
    }, {
        Header: 'Username',
        id: 'email',
        accessor: d => d.email,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 250,
        sortable: true,
        filterable: true
    }, {
        Header: 'Name',
        id: 'description',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 200,
        sortable: true,
        filterable: true,
        accessor: d => d.firstName + ' ' + d.lastName
    }, {
        Header: 'Type',
        id: 'type',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 180,
        sortable: true,
        filterable: true,
        accessor: d => d.type === 'free' ? 'Trial' : d.type === 'paid' ? 'Premium' : d.type === 'comp' ? 'Complimentary' : 'None',
        filterMethod: (filter, row) => {
            if (filter.value === "All") {
                return true;
            }
            return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) =>
            <select
                onChange={event => onChange(event.target.value)}
                className="grid-filter-dropdown"
                value={filter ? filter.value : "All"}
            >
                <option value="All"></option>
                <option value="Trial">Trial</option>
                <option value="Premium">Premium</option>
                <option value="Complimentary">Complimentary</option>
                <option value="None">None</option>
            </select>
    }, {
        Header: 'Status',
        id: 'status',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 150,
        sortable: true,
        filterable: true,
        accessor: d => d.status === 'registered' ? 'Unverified' : d.status === 'active' ? 'Verified' : 'Suspended',
        filterMethod: (filter, row) => {
            if (filter.value === "All") {
                return true;
            }
            return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) =>
            <select
                onChange={event => onChange(event.target.value)}
                className="grid-filter-dropdown"
                value={filter ? filter.value : "All"}
            >
                <option value="All"></option>
                <option value="Unverified">Unverified</option>
                <option value="Verified">Verified</option>
                <option value="Suspended">Suspended</option>
            </select>
    }];

    onSearchTextChange = (e) => {
        const searchText = e.target.value;
        this.setState(() => ({ searchText }));
        if (searchText.trim().length === 0) {
            this.setState(() => ({ formError: true }));
        } else {
            this.setState(() => ({ formError: false }));
        }
    }

    componentDidMount() {
        const searchText = this.props.match.params.searchText;
        if (searchText && searchText.trim() !== '') {
            this.setState(() => ({ searchText: searchText }), () => { this.search(); });
        }
    }

    search = () => {
        this.setState(() => ({ processing: true }));
        api.searchUsers(this.state.searchText).then((data) => {
            this.setState(() => ({ results: data }));
            this.setState(() => ({ processing: false }));
        }).catch((err) => {
            this.setState(() => ({ results: [] }));
            this.setState(() => ({ processing: false }));
            if (err.message === 'NoUsersFound') {
                toastr.error('No matching customers found');
            } else if (err.message === 'TooManyUsersFound') {
                toastr.error('Too many matching customers found. Please refine your search.');
            } else {
                toastr.error('Error fetching matching customers');
            }
        });
    }

    formSubmit = (e) => {
        e.preventDefault();
        if (this.state.searchText.trim().length === 0) {
            this.setState(() => ({ touched: true }));
            this.setState(() => ({ formError: true }));
        } else {
            this.search();
        }

    }

    onBlur = () => {
        this.setState(() => ({ touched: true }));
    }

    render() {
        const { results, processing, formError, touched, searchText } = this.state;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="container">
                        <div className="col-md-12 text-center uppercase-text bolder-text">
                            <h2 className="header-color">Customers</h2>
                        </div>
                        <div>&nbsp;</div>
                        <div className="form-box">
                            <form name="form" id="form" onSubmit={this.formSubmit} noValidate autoComplete="off">
                                <span className="label-text">Search customers by name, email address Or phone number</span>
                                <br />
                                <input autoComplete="off" type="text" value={searchText} maxLength="100" name="searchText" onBlur={this.onBlur} onChange={this.onSearchTextChange} className="text-box" />
                                <span className="label-text-info">(Phone numbers should be entered without spaces, dashes, dots and brackets)</span>
                                <br />
                                {formError && touched && <div className="error-message">Enter name, email or phone number of customer to search</div>}
                                <br />
                                <br />
                                <button disabled={processing} type="submit" className="submit-button">Search</button>
                            </form>
                        </div>
                        {results.length > 0 &&
                            <div>
                                <div>
                                    &nbsp;
                                </div>
                                <div>
                                    <div className="customers-table">
                                        <ReactTable
                                            className="-striped -highlight"
                                            data={results}
                                            columns={this.columns}
                                            defaultPageSize={10}
                                            noDataText="No matching customers found"
                                            defaultFilterMethod={
                                                (filter, row) => {
                                                    return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Customers;
