import React from 'react';

const NotSupported = () => (
    <div className="content-area full-page visible-xs">
        <div className="content-box">
            <div>&nbsp;</div>
            <div className="col-md-12 text-center uppercase-text bolder-text">
                <h2 className="header-color">
                    Resolution Not Supported
                </h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <p className="text-center">
                    This resolution is not supported by the YipTV Admin Portal. Please increase your screen resolution or browser size.
                </p>
            </div>
        </div>
    </div>
);

export default NotSupported;
