import React from 'react';
import { Field, reduxForm } from 'redux-form';
import DateTime from 'react-datetime';

import * as validations from '../services/validations';

const validate = (values) => {
    const errors = {};
    if (validations.isEmpty(values.requestedBy)) {
        errors.requestedBy = 'Enter name of employee who requested the code';
    } else if (!validations.isName(values.requestedBy)) {
        errors.requestedBy = 'Enter a valid employee name';
    }
    if (validations.isEmpty(values.department)) {
        errors.department = 'Enter employee department name';
    } else if (!validations.isName(values.department)) {
        errors.department = 'Enter a valid department name';
    }
    if (validations.isEmpty(values.reason)) {
        errors.reason = 'Enter a reason the code is needed';
    } else if (!validations.isName(values.reason)) {
        errors.reason = 'Enter a valid reason';
    }
    if (!validations.isDate(values.startDate)) {
        errors.startDate = 'Enter the start date';
    }
    if (!validations.isDate(values.endDate)) {
        errors.endDate = 'Enter the end date';
    } else if(!validations.isDateRange(values.startDate, values.endDate)) {
        errors.endDate = 'End date cannot be before start date';
    }
    if (validations.isEmpty(values.duration)) {
        errors.duration = 'Enter the duration of subscription in days';
    } else if (!validations.isInteger(values.duration)) {
        errors.duration = 'Enter a duration between 1 and 36500 days';
    } else if (parseInt(values.duration, 10) < 1 || parseInt(values.duration, 10) > 36500) {
        errors.duration = 'Enter a duration between 1 and 36500 days';
    }
    if (validations.isEmpty(values.maximumAccounts)) {
        errors.maximumAccounts = 'Enter the maximum accounts that can be created by this code';
    } else if (!validations.isInteger(values.maximumAccounts)) {
        errors.maximumAccounts = 'Enter a valid number between 1 and 1,000,000';
    } else if (parseInt(values.maximumAccounts, 10) < 1 || parseInt(values.maximumAccounts, 10) > 1000000) {
        errors.maximumAccounts = 'Enter a valid number between 1 and 1,000,000';
    }
    return errors;
};

const renderField = ({ input, type, maxLength, meta: { touched, error } }) => {
    return (
        <div>
            <input {...input} autoComplete="off" maxLength={maxLength} type={type} className={"text-box " + (touched && error ? "text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const renderDateField = ({ input, open, buttonClick, meta: { touched, error } }) => {
    return (
        <div>
            <div className="input-group date-group">
                <DateTime
                    {...input}
                    dateFormat={'MM/DD/YYYY'}
                    timeFormat={false}
                    inputProps={{ readOnly: true, required: true, className: 'text-box form-control ' + (touched && error ? "text-box-error" : "") }}
                    open={open}
                    closeOnSelect={true}
                    closeOnTab={false}
                />
                <span className="input-group-btn">
                    <button onClick={buttonClick} type="button" className={'btn btn-default btn-calendar ' + (touched && error ? "btn-calendar-error" : "")}><i className="glyphicon glyphicon-calendar"></i></button>
                </span>
            </div>
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

class CreateComplimentaryCodeForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            startDateOpen: false,
            endDateOpen: false
        };
    }

    onStartDateFocus = () => {
        this.setState(() => ({ startDateOpen: false }));
    }

    onEndDateFocus = () => {
        this.setState(() => ({ endDateOpen: false }));
    }

    toggleStartDateOpen = () => {
        this.setState(() => ({ startDateOpen: !this.state.startDateOpen }));
    }

    toggleEndDateOpen = () => {
        this.setState(() => ({ endDateOpen: !this.state.endDateOpen }));
    }

    onStartDateChange = () => {
        this.setState(() => ({ startDateOpen: false }));
    }

    onEndDateChange = () => {
        this.setState(() => ({ endDateOpen: false }));
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        const { startDateOpen, endDateOpen } = this.state;
        return (
            <form onSubmit={handleSubmit} autoComplete="off">
                <span className="label-text">Employee Requesting Code</span>
                <br />
                <Field name="requestedBy" type="text" maxLength="40" component={renderField} />
                <br />
                <span className="label-text">Department</span><br />
                <Field name="department" maxLength="40" type="text" component={renderField} />
                <br />
                <span className="label-text">Reason</span><br />
                <Field name="reason" maxLength="500" type="text" component={renderField} />
                <br />
                <span className="label-text">Start Date</span><br />
                <Field name="startDate" open={startDateOpen} onFocus={this.onStartDateFocus} onChange={this.onStartDateChange} buttonClick={this.toggleStartDateOpen} component={renderDateField} />
                <br />
                <span className="label-text">End Date</span><br />
                <Field name="endDate" open={endDateOpen} onFocus={this.onEndDateFocus} onChange={this.onEndDateChange} buttonClick={this.toggleEndDateOpen} component={renderDateField} />
                <br />
                <span className="label-text">Duration of Subscription (days)</span><br />
                <Field name="duration" maxLength="5" type="text" component={renderField} />
                <br />
                <span className="label-text">Maximum Accounts Allowed</span><br />
                <Field name="maximumAccounts" maxLength="7" type="text" component={renderField} />
                <br />
                <button type="submit" disabled={submitting} className="submit-button">Create Complimentary Code</button>
            </form>
        );
    };
}

export default reduxForm({ form: 'createComplimentaryCode', validate })(CreateComplimentaryCodeForm);
