import React from 'react';
import moment from 'moment';

import CreateComplimentaryCodeForm from '../forms/create-complimentary-code-form.component';
import toastr from '../services/toastr';
import history from '../services/history';
import * as api from '../services/api';

class CreateComplimentaryCode extends React.Component {

    back = () => {
        history.push('/complimentary-codes');
    }

    createComplimentaryCode = (complimentaryCode) => {
        complimentaryCode.startDate = this.convertDateToUtc(complimentaryCode.startDate).startOf('day');
        complimentaryCode.endDate = this.convertDateToUtc(complimentaryCode.endDate).endOf('day');
        api.createComplimentaryCode(complimentaryCode).then((data) => {
            toastr.success('New complimentary code created successfully');
            history.push('/complimentary-codes');
        }).catch((err) => {
            toastr.error('Unable to create complimentary code');
        });
    }

    convertDateToUtc = (date) => {
        const offset = date.utcOffset();
        date = date.utc();
        date.add(offset, 'minutes');
        return date;
    }

    render() {
        const initialValues = { startDate: moment(), endDate: moment() };
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="form-box">
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="header header-color center-form uppercase-text">
                            <div>New Complimentary Code</div>
                        </div>
                        <div>&nbsp;</div>
                        <CreateComplimentaryCodeForm initialValues={initialValues} onSubmit={this.createComplimentaryCode} />
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateComplimentaryCode;
