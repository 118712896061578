import * as actionTypes from '../constants/action-types';

const initialAppState = {};

const configReducer = (state = initialAppState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_CONFIG_SUCCESS:
            return action.config;
        default:
            return state;
    }
};

export default configReducer;
