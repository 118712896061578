import React from 'react';

import CreateChannelTagForm from '../forms/create-channel-tag-form.component';
import toastr from '../services/toastr';
import history from '../services/history';
import * as api from '../services/api';

class CreateChannelTag extends React.Component {

    back = () => {
        history.push('/channel-tags');
    }

    createChannelTag = (tag) => {
        api.createChannelTag(tag).then((data) => {
            toastr.success('New channel tag created successfully');
            history.push('/channel-tags');
        }).catch((err) => {
            if (err.message === 'ChannelTagExists') {
                toastr.error('Channel tag code already exists');
            } else {
                toastr.error('Unable to create channel tag');
            }
        });
    }

    render() {
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="form-box">
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="header header-color center-form uppercase-text">
                            <div>New Channel Tag</div>
                        </div>
                        <div>&nbsp;</div>
                        <CreateChannelTagForm onSubmit={this.createChannelTag} />
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateChannelTag;
