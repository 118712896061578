import React from 'react';
import { connect } from 'react-redux';

const CustomerButton = ({ name, clickHandler, isAuthorized, buttonClass }) => (
    <div style={{display: "inline-block"}}>
        {
            isAuthorized &&
            <button onClick={clickHandler} className={'btn btn-primary ' + buttonClass}>{name}</button>
        }
    </div>
);

const checkIfAuthorized = (roles, userRole) => {
    if (roles.length < 0) {
        return false;
    }
    if (roles[0] === 'all') {
        return true;
    }
    return roles.includes(userRole);
};

const mapStateToProps = (state, ownProps) => ({
    isAuthorized: checkIfAuthorized(ownProps.roles, state.user.role)
});

export default connect(mapStateToProps)(CustomerButton);
