import React from 'react';

import CreateMerchantForm from '../forms/create-merchant-form.component';
import toastr from '../services/toastr';
import history from '../services/history';
import * as api from '../services/api';

class CreateMerchant extends React.Component {

    back = () => {
        history.push('/merchants');
    }

    createMerchant = (merchant) => {
        api.createMerchant(merchant).then((data) => {
            toastr.success('New merchant created successfully');
            history.push('/merchants');
        }).catch((err) => {
            if (err.message === 'MerchantExists') {
                toastr.error('Merchant code already exists');
            } else {
                toastr.error('Unable to create merchant');
            }
        });
    }

    render() {
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="form-box">
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="header header-color center-form uppercase-text">
                            <div>New Merchant</div>
                        </div>
                        <div>&nbsp;</div>
                        <CreateMerchantForm onSubmit={this.createMerchant} />
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateMerchant;
