import React from 'react';
import { Field, reduxForm } from 'redux-form';

const renderField = ({ input, type, maxLength, meta: { touched, error } }) => {
    return (
        <div>
            <input {...input} autoComplete="off" maxLength={maxLength} type={type} className={"text-box " + (touched && error ? "text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const renderTextArea = ({ input, maxLength, meta: { touched, error, warning } }) => (
    <div>
        <div>
            <textarea {...input} maxLength={maxLength} autoComplete="off" className={"text-box text-area notes-text-area " + (touched && error ? "text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    </div>
);

const UpdateSupportInfoForm = (props) => {
    const { handleSubmit, submitting } = props;
    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <span className="label-text">Alternate Contact 1</span>
            <br />
            <Field name="altContact1" type="text" maxLength="50" component={renderField} />
            <br />
            <span className="label-text">Alternate Contact 2</span>
            <br />
            <Field name="altContact2" type="text" maxLength="50" component={renderField} />
            <br />
            <span className="label-text">Address</span>
            <br />
            <Field name="address" maxLength="100" type="text" component={renderField} />
            <br />
            <span className="label-text">City</span>
            <br />
            <Field name="city" maxLength="50" type="text" component={renderField} />
            <br />
            <span className="label-text">State</span>
            <br />
            <Field name="state" maxLength="50" type="text" component={renderField} />
            <br />
            <span className="label-text">Zip Code</span>
            <br />
            <Field name="zipCode" maxLength="10" type="text" component={renderField} />
            <br />
            <span className="label-text">Notes</span>
            <br />
            <Field name="notes" maxLength="10000" component={renderTextArea}></Field>
            <br />
            <button type="submit" disabled={submitting} className="submit-button">Submit</button>
        </form>
    );
};

export default reduxForm({ form: 'updateSupportInfo' })(UpdateSupportInfoForm);
