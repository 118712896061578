import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import appStateReducer from './app-state.reducer';
import configReducer from './config.reducer';
import userReducer from './user.reducer';
import channelLogosReducer from './channel-logos.reducer';

export default combineReducers({
    appState: appStateReducer,
    config: configReducer,
    user: userReducer,
    channelLogos: channelLogosReducer,
    form: reduxFormReducer
});
