import React from 'react';

import CreateAdminUserForm from '../forms/create-admin-user-form.component';
import toastr from '../services/toastr';
import history from '../services/history';
import * as api from '../services/api';

class CreateAdminUser extends React.Component {

    back = () => {
        history.push('/admin-users');
    }

    createAdminUser = (user) => {
        api.createAdminUser(user).then((data) => {
            toastr.success('New admin user created successfully');
            history.push('/admin-users');
        }).catch((err) => {
            if (err.message === 'UserExists') {
                toastr.error('There is already an admin user with this email address');
            } else {
                toastr.error('Unable to create admin user');
            }
        });
    }

    render() {
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="form-box">
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="header header-color center-form uppercase-text">
                            <div>New Admin User</div>
                        </div>
                        <div>&nbsp;</div>
                        <CreateAdminUserForm onSubmit={this.createAdminUser} />
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateAdminUser;
