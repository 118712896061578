import React from 'react';
import { Field, reduxForm } from 'redux-form';

import * as validations from '../services/validations';
import PasswordStrengthBar from '../widgets/password-strength-bar.component';

const validate = (values) => {
    const errors = {};
    if (validations.isEmpty(values.currentPassword)) {
        errors.currentPassword = 'Enter your current password';
    }
    if (validations.isEmpty(values.newPassword)) {
        errors.newPassword = 'Enter your new password';
    } else if (!validations.isStrongPassword(values.newPassword)) {
        errors.newPassword = 'Password needs to be at least 6 characters, contain 1 uppercase letter and 1 number';
    } else if (values.currentPassword === values.newPassword) {
        errors.newPassword = 'New Password cannot be the same as the current password';
    }
    if (validations.isEmpty(values.confirmPassword)) {
        errors.confirmPassword = 'Confirm password';
    } else if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match';
    }
    return errors;
};

const renderField = ({ input, type, maxLength, passwordStrengthBar, meta: { touched, error } }) => {
    return (
        <div>
            <input {...input} autoComplete="off" maxLength={maxLength} type={type} className={"text-box " + (touched && error ? "text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
            {passwordStrengthBar && <PasswordStrengthBar barForm={passwordStrengthBar[0]} barField={passwordStrengthBar[1]} />}
        </div>
    );
};

const ChangeMyPasswordForm = (props) => {
    const { handleSubmit, submitting } = props;
    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <span className="label-text">Current Password</span>
            <br />
            <Field name="currentPassword" maxLength="20" type="password" component={renderField} />
            <br />
            <span className="label-text">New Password</span><br />
            <Field name="newPassword" maxLength="20" type="password" component={renderField} passwordStrengthBar={['changeMyPassword', 'newPassword']} />
            <br />
            <span className="label-text">Confirm Password</span><br />
            <Field name="confirmPassword" maxLength="20" type="password" component={renderField} />
            <br />
            <button type="submit" disabled={submitting} className="submit-button">Submit</button>
        </form>
    );
};

export default reduxForm({ form: 'changeMyPassword', validate })(ChangeMyPasswordForm);
