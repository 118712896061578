import React from 'react';
import toastr from 'toastr';
import { connect } from 'react-redux';

import * as api from '../services/api';
import * as validations from '../services/validations';

class CustomerExport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paid: true,
            comp: true,
            free: true,
            email: true,
            phone: true,
            optIn: true,
            optOut: true,
            sendToEmailAddress: props.loggedInUserEmail,
            processing: false,
            sendToEmailAddressError: '',
            typeError: '',
            usernameError: '',
            notificationError: '',
            touched: false
        };
    }

    formSubmit = (e) => {
        e.preventDefault();
        if (this.state.sendToEmailAddressError || this.state.typeError || this.state.usernameError || this.state.notificationError) {
            return;
        }
        this.setState(() => ({ processing: true }));
        const data = { paid: this.state.paid, free: this.state.free, comp: this.state.comp, email: this.state.email, phone: this.state.phone, optIn: this.state.optIn, optOut: this.state.optOut, sendToEmailAddress: this.state.sendToEmailAddress };
        api.emailUserList(data).then(() => {
            this.setState(() => ({ processing: false }));
            toastr.success('Customer CSV file will be emailed shortly');
        }).catch((err) => {
            this.setState(() => ({ processing: false }));
            toastr.error('Error requesting customer list');
        });
    }

    onSendToEmailAddressChange = (e) => {
        const sendToEmailAddress = e.target.value;
        this.setState(() => ({ sendToEmailAddress }));
        if (sendToEmailAddress.trim().length === 0) {
            this.setState(() => ({ sendToEmailAddressError: 'Enter email address' }));
        } else if (!validations.isEmail(sendToEmailAddress)) {
            this.setState(() => ({ sendToEmailAddressError: 'Enter a valid email address' }));
        } else {
            this.setState(() => ({ sendToEmailAddressError: '' }));
        }
    }

    onFreeChange = (e) => {
        const free = e.target.checked;
        this.setState(() => ({ free }), () => { this.onTypeChange(); });
    }

    onPaidChange = (e) => {
        const paid = e.target.checked;
        this.setState(() => ({ paid }), () => { this.onTypeChange(); });
    }

    onCompChange = (e) => {
        const comp = e.target.checked;
        this.setState(() => ({ comp }), () => { this.onTypeChange(); });
    }

    onTypeChange = () => {
        if (!this.state.paid && !this.state.free && !this.state.comp) {
            this.setState(() => ({ typeError: 'Select at least one' }));
        } else {
            this.setState(() => ({ typeError: '' }));
        }
    }

    onEmailChange = (e) => {
        const email = e.target.checked;
        this.setState(() => ({ email }), () => { this.onUsernameChange(); });
    }

    onPhoneChange = (e) => {
        const phone = e.target.checked;
        this.setState(() => ({ phone }), () => { this.onUsernameChange(); });
    }

    onUsernameChange = () => {
        if (!this.state.email && !this.state.phone) {
            this.setState(() => ({ usernameError: 'Select at least one' }));
        } else {
            this.setState(() => ({ usernameError: '' }));
        }
    }

    onOptInChange = (e) => {
        const optIn = e.target.checked;
        this.setState(() => ({ optIn }), () => { this.onNotifictionChange(); });
    }

    onOptOutChange = (e) => {
        const optOut = e.target.checked;
        this.setState(() => ({ optOut }), () => { this.onNotifictionChange(); });
    }

    onNotifictionChange = () => {
        if (!this.state.optIn && !this.state.optOut) {
            this.setState(() => ({ notificationError: 'Select at least one' }));
        } else {
            this.setState(() => ({ notificationError: '' }));
        }
    }

    onBlur = () => {
        this.setState(() => ({ touched: true }));
    }

    render() {
        const { processing, sendToEmailAddressError, sendToEmailAddress, paid, free, comp, email, phone, optIn, optOut, touched, typeError, usernameError, notificationError } = this.state;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="container">
                        <div className="col-md-12 text-center uppercase-text bolder-text">
                            <h2 className="header-color">Customer Export</h2>
                        </div>
                        <div>&nbsp;</div>
                        <div className="form-box">
                            <form name="form" id="form" onSubmit={this.formSubmit} noValidate autoComplete="off">
                                <div className="row customer-export-div">
                                    <div className="col-md-4 col-sm-4">
                                        <label className="text-underline">Type</label><br />
                                        <input type="checkbox" onChange={this.onPaidChange} checked={paid} className="check-box" name="paid" id="paid" />
                                        <label className="purple" htmlFor="paid">
                                            <span>Premium</span>
                                        </label>
                                        <br />
                                        <input type="checkbox" onChange={this.onFreeChange} checked={free} className="check-box" name="free" id="free" />
                                        <label className="purple" htmlFor="free">
                                            <span>Trial</span>
                                        </label>
                                        <br />
                                        <input type="checkbox" onChange={this.onCompChange} checked={comp} className="check-box" name="comp" id="comp" />
                                        <label className="purple" htmlFor="comp">
                                            <span>Complimentary</span>
                                        </label>
                                        {typeError && <div className="error-message">{typeError}</div>}
                                    </div>
                                    <div className="col-md-4 col-sm-4">
                                        <label className="text-underline">Username</label><br />
                                        <input type="checkbox" onChange={this.onEmailChange} checked={email} className="check-box" name="email" id="email" />
                                        <label className="purple" htmlFor="email">
                                            <span>Email</span>
                                        </label>
                                        <br />
                                        <input type="checkbox" onChange={this.onPhoneChange} checked={phone} className="check-box" name="phone" id="phone" />
                                        <label className="purple" htmlFor="phone">
                                            <span>Phone</span>
                                        </label>
                                        <div>&nbsp;</div>
                                        {usernameError && <div className="error-message">{usernameError}</div>}
                                    </div>
                                    <div className="col-md-4 col-sm-4">
                                        <label className="text-underline">Notification</label><br />
                                        <input type="checkbox" onChange={this.onOptInChange} checked={optIn} className="check-box" name="optIn" id="optIn" />
                                        <label className="purple" htmlFor="optIn">
                                            <span>Opt-In</span>
                                        </label>
                                        <br />
                                        <input type="checkbox" onChange={this.onOptOutChange} checked={optOut} className="check-box" name="optOut" id="optOut" />
                                        <label className="purple" htmlFor="optOut">
                                            <span>Opt-Out</span>
                                        </label>
                                        <div>&nbsp;</div>
                                        {notificationError && <div className="error-message" >{notificationError}</div>}
                                    </div>
                                </div>
                                <br />
                                <br />
                                <span className="label-text">Email address to send CSV file</span>
                                <br />
                                <input autoComplete="off" type="text" maxLength="50" name="sendToEmailAddress" value={sendToEmailAddress} onBlur={this.onBlur} onChange={this.onSendToEmailAddressChange} className="text-box" />
                                {sendToEmailAddressError && touched && <div className="error-message">{sendToEmailAddressError}</div>}
                                <br />
                                <br />
                                <button disabled={processing} type="submit" className="submit-button">Email Customer List</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedInUserEmail: state.user.email
});

export default connect(mapStateToProps)(CustomerExport);
