import * as actionTypes from '../constants/action-types';

const initialAppState = {
    menuState: false
};

const appStateReducer = (state = initialAppState, action) => {
    switch (action.type) {
        case actionTypes.SET_MENU_STATE:
            return { ...state, menuState: action.menuState };
        default:
            return state;
    }
};

export default appStateReducer;
