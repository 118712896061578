import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import AppRouter from './routers/app-router.component';
import Loading from './pages/loading.component';
import { loadConfig } from './actions/config.action';
import { loadChannelLogos } from './actions/channel-logos.action';
import { loadUser, signInFailure } from './actions/user.action';
import reduxStore from './services/redux-store';
import toastr from './services/toastr';
import history from './services/history';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import 'toastr/build/toastr.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-table/react-table.css';
import 'react-widgets/dist/css/react-widgets.css';
import './styles/index.css';

ReactDOM.render(<Loading />, document.getElementById('root'));

const store = reduxStore();
let hasRendered = false;

const jsx = (
    <Provider store={store}>
        <AppRouter />
    </Provider>
);

const renderApp = () => {
    if (!hasRendered) {
        ReactDOM.render(jsx, document.getElementById('root'));
        hasRendered = true;
    }
};

store.dispatch(loadConfig()).catch(() => {
    toastr.error('Unable to load application configuration');
});

store.dispatch(loadChannelLogos()).catch(() => {
    toastr.error('Unable to load channel logos');
});

if (sessionStorage.getItem('token')) {
    store.dispatch(loadUser()).then(() => {
        renderApp();
        if (history.location.pathname === '/' || history.location.pathname === '/sign-in') {
            history.push('/user-home');
        }
    }).catch(() => {
        renderApp();
        store.dispatch(signInFailure()).catch(() => {
            // do nothing
        });
    });
} else {
    renderApp();
}
