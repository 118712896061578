import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducers from '../reducers/index';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = process.env.NODE_ENV === 'development' ? [require('redux-immutable-state-invariant').default(), thunk] : [thunk];

const store = () => {
    const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));
    return store;
};

export default store;
