import React from 'react';
import ReactTable from 'react-table';
import toastr from 'toastr';
import { connect } from 'react-redux';
import WindowSizeListener from 'react-window-size-listener';

import { loadChannelLogos } from '../actions/channel-logos.action';
import * as api from '../services/api';
import history from '../services/history';
import ConfirmModal from '../widgets/confirm-modal.component';

class ChannelLogos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            selectedLogo: null
        };
    }

    columns = [{
        Header: 'Delete',
        id: 'delete',
        accessor: d => d.Key,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 70,
        sortable: false,
        Cell: row => <button className="btn btn-xs btn-primary" onClick={() => {
            this.showDeleteModal(row.value);
        }}>Delete</button>
    }, {
        Header: 'Logo',
        id: 'key',
        accessor: d => d.Key,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 275,
        sortable: false,
        filterable: false,
        Cell: row => (<div>
            <img width="100%" alt="" src={this.props.channelLogoUrl + row.value} />
        </div>)
    }, {
        Header: 'File Name',
        id: 'fileName',
        accessor: d => d.Key,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 150,
        sortable: true,
        filterable: true
    }];

    capitalize(value) {
        if (value && value.length > 1) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        if (value && value.length === 1) {
            return value.charAt(0).toUpperCase();
        }
        return value;
    }

    back = () => {
        history.push('/content-management');
    }

    onFileSelect = (e) => {
        const fileName = this.uploadInput.files[0].name;
        const fileSize = this.uploadInput.files[0].size;
        if (fileName.length !== 13 || !fileName.endsWith('.png') || !fileName.startsWith('logo-') || fileSize > (1024 * 100)) {
            toastr.error('File name should be in the format "logo-&lt;channel-code&gt;.png" and file size should be less than 100kB');
            return;
        }
        const data = new FormData();
        data.append('file', this.uploadInput.files[0]);
        this.setState(() => ({ processing: true }));
        api.uploadChannelLogo(data).then((data) => {
            toastr.success('Channel logo uploaded successfully');
            this.props.loadChannelLogos();
            this.setState(() => ({ processing: false }));
        }).catch((err) => {
            if (err.message === 'InvalidImage') {
                toastr.error('File uploaded is not a valid channel logo image');
            } else {
                toastr.error('Error uploading channel logo');
            }
            this.props.loadChannelLogos();
            this.setState(() => ({ processing: false }));
        });
    }

    deleteChannelLogo = () => {
        api.deleteChannelLogo(this.state.selectedLogo).then((data) => {
            toastr.success('Channel logo deleted successfully');
            this.hideDeleteModal();
            this.props.loadChannelLogos();
        }).catch(() => {
            toastr.error('Unable to change merchant status');
            this.hideDeleteModal();
            this.props.loadChannelLogos();
        });
    }

    showDeleteModal = (key) => {
        this.setState(() => ({ deleteModalState: true }));
        this.setState(() => ({ selectedLogo: key }));
    }

    hideDeleteModal = () => {
        this.setState(() => ({ deleteModalState: false }));
        this.setState(() => ({ selectedLogo: null }));
    }

    render() {
        const { processing, deleteModalState } = this.state;
        const { logos } = this.props;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="container">
                        <div>&nbsp;</div>
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="header header-color center-form uppercase-text">
                            <div>Channel Logos</div>
                        </div>
                        <div className="sub-header header-color">
                            &nbsp;
                        </div>
                        <div className="col-lg-12 text-center">
                            <input disabled={processing} ref={(ref) => { this.uploadInput = ref; }} type="file" onChange={this.onFileSelect} name="channelLogo" id="channelLogo" className="input-file" />
                            <label htmlFor="channelLogo">Upload Channel Logo</label>
                        </div>
                        <div>&nbsp;</div>
                        {processing && <div className="progress progress-bar-width channel-logos-table">
                            <div className="progress-bar progress-bar-striped active progress-bar-completion-width" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>}
                        <div>
                            &nbsp;
                        </div>
                        <div>
                            <div className="channel-logos-table">
                                <ReactTable
                                    className="-striped -highlight"
                                    data={logos}
                                    columns={this.columns}
                                    defaultPageSize={10}
                                    noDataText="No channel logos found"
                                    defaultFilterMethod={
                                        (filter, row) => {
                                            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <ConfirmModal
                            showYesButton={true}
                            showNoButton={true}
                            bsSize={'sm'}
                            show={deleteModalState}
                            onHide={this.hideDeleteModal}
                            title={'YipTV Admin Portal'}
                            body={'Are you sure you want to delete this channel logo?'}
                            yesButtonClick={this.deleteChannelLogo}
                            noButtonClick={this.hideDeleteModal}
                        />
                        <WindowSizeListener onResize={window => {
                            if (window.windowWidth <= 767) {
                                this.hideDeleteModal();
                            }
                        }} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    channelLogoUrl: state.config.channelLogoUrl,
    logos: state.channelLogos
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadChannelLogos: () => dispatch(loadChannelLogos())
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelLogos);
