import React from 'react';
import { Field, reduxForm } from 'redux-form';

import * as validations from '../services/validations';
import * as api from '../services/api';

const roles = [
    { value: 'administrator', text: 'Administrator' },
    { value: 'finance', text: 'Finance' },
    { value: 'management', text: 'Management' },
    { value: 'marketing', text: 'Marketing' },
    { value: 'operations', text: 'Operations' },
    { value: 'support', text: 'Support' },
];

const validate = (values) => {
    const errors = {};
    if (validations.isEmpty(values.email)) {
        errors.email = 'Enter email address';
    } else if (!validations.isEmail(values.email)) {
        errors.email = 'Enter a valid email address';
    }
    if (validations.isEmpty(values.firstName)) {
        errors.firstName = 'Enter first name';
    } else if (!validations.isName(values.firstName)) {
        errors.firstName = 'Enter a valid first name';
    }
    if (validations.isEmpty(values.lastName)) {
        errors.lastName = 'Enter last name';
    } else if (!validations.isName(values.lastName)) {
        errors.lastName = 'Enter a valid last name';
    }
    if (validations.isEmpty(values.roleName)) {
        errors.roleName = 'Select a role';
    }
    return errors;
};

const asyncValidate = (values) => {
    const error = { email: 'There is already an admin user with this email address' };
    return api.isEmailAvailable(values.email).then((data) => {
        if (!data) {
            throw error;
        }
    }).catch(() => {
        throw error;
    });
};

const renderField = ({ input, type, maxLength, meta: { touched, error } }) => {
    return (
        <div>
            <input {...input} autoComplete="off" maxLength={maxLength} type={type} className={"text-box " + (touched && error ? "text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const renderDropDown = ({ input, options, defaultOptionText, meta: { touched, error } }) => {
    return (
        <div>
            <select {...input} className={"text-box drop-down-list " + (touched && error ? "text-box-error" : "")}>
                <option value="">{defaultOptionText}</option>
                {
                    options.map((opt, i) => {
                        return <option value={opt.value} key={i}>{opt.text}</option>;
                    })
                }
            </select>
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const CreateAdminUserForm = (props) => {
    const { handleSubmit, submitting } = props;
    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <div className="full-form">
                <span className="label-text">Email</span>
                <br />
                <Field name="email" maxLength="50" type="text" component={renderField} />
            </div>
            <div className="form-container">
                <div className="half-form">
                    <span className="label-text">First Name</span><br />
                    <Field name="firstName" maxLength="20" type="text" component={renderField} />
                </div>
                <div className="half-form">
                    <span className="label-text">Last Name</span><br />
                    <Field name="lastName" maxLength="20" type="text" component={renderField} />
                </div>
            </div>
            <div className="full-form">
                <span className="label-text">Role</span><br />
                <Field name="roleName" options={roles} defaultOptionText="Select role" component={renderDropDown} />
            </div>
            <div className="full-form">
                <button type="submit" disabled={submitting} className="submit-button">Create Admin User</button>
            </div>
        </form>
    );
};

export default reduxForm({ form: 'createAdminUser', validate, asyncValidate })(CreateAdminUserForm);
