import React from 'react';
import { Field, reduxForm } from 'redux-form';

import * as validations from '../services/validations';

const validate = (values) => {
    const errors = {};
    if (validations.isEmpty(values.duration)) {
        errors.duration = 'Enter the duration of complimentary subscription in days';
    } else if (!validations.isInteger(values.duration)) {
        errors.duration = 'Enter a valid duration in days';
    } else if (parseInt(values.duration, 10) < 1 || parseInt(values.duration, 10) > 36500) {
        errors.duration = 'Enter a value between 1 and 36500 days';
    }
    if (validations.isEmpty(values.reason)) {
        errors.reason = 'Enter a reason';
    } else if (!validations.isName(values.reason)) {
        errors.reason = 'Enter a valid reason';
    }
    return errors;
};

const renderField = ({ input, type, maxLength, meta: { touched, error } }) => {
    return (
        <div>
            <input {...input} autoComplete="off" maxLength={maxLength} type={type} className={"text-box " + (touched && error ? "text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const ConvertToComplimentaryForm = (props) => {
    const { handleSubmit, submitting } = props;
    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <span className="label-text">Complimentary subscription duration (days)</span>
            <br />
            <Field name="duration" type="text" maxLength="5" component={renderField} />
            <br />
            <span className="label-text">Reason</span><br />
            <Field name="reason" maxLength="1000" type="text" component={renderField} />
            <br />
            <button type="submit" disabled={submitting} className="submit-button">Add</button>
        </form>
    );
};

export default reduxForm({ form: 'convertToComplimentary', validate })(ConvertToComplimentaryForm);
