import React from 'react';
import toastr from 'toastr';
import numeral from 'numeral';

import * as api from '../services/api';
import history from '../services/history';

class CustomerSignUpReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            affiliateData: [],
            nonAffiliateData: []
        };
    }

    componentDidMount() {
        api.getUserSignUpReport({ affiliate: true, complimentary: true }).then((data) => {
            this.setState(() => ({ affiliateData: data }));
        }).catch(() => {
            toastr.error('Error fetching affiliate report');
        });
        api.getUserSignUpReport({ affiliate: false, complimentary: true }).then((data) => {
            this.setState(() => ({ nonAffiliateData: data }));
        }).catch(() => {
            toastr.error('Error fetching non-affiliate report');
        });
    }

    back = () => {
        history.goBack();
    };

    render() {
        const { affiliateData, nonAffiliateData } = this.state;

        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="container">
                        <div>&nbsp;</div>
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="col-md-12 text-center uppercase-text bolder-text"><h2 className="header-color">Customer Sign Up Report</h2></div>
                        {affiliateData.length > 0 && nonAffiliateData.length > 0 ?
                            (<div className="col-md-12 table-holder">
                                <div className="text-center uppercase-text bold-text">YipTV Attribution</div>
                                <table align="center">
                                    <thead>
                                        <tr>
                                            <th className="report-row-headings"></th>
                                            <th>Today</th>
                                            <th>Yesterday</th>
                                            <th>2 Days Ago</th>
                                            <th>3 Days Ago</th>
                                            <th>7 Days Ago</th>
                                            <th>This Month</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="report-row-headings"><strong>Total Trial</strong></td>
                                            <td>{numeral(nonAffiliateData[0] + nonAffiliateData[7]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[1] + nonAffiliateData[8]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[2] + nonAffiliateData[9]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[3] + nonAffiliateData[10]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[4] + nonAffiliateData[11]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[5] + nonAffiliateData[12]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[6] + nonAffiliateData[13]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Verified Trial</strong></td>
                                            <td>{numeral(nonAffiliateData[0]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[1]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[2]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[3]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[4]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[5]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[6]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Unverified Trial</strong></td>
                                            <td>{numeral(nonAffiliateData[7]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[8]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[9]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[10]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[11]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[12]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[13]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Total Premium - Monthly</strong></td>
                                            <td>{numeral(nonAffiliateData[14] + nonAffiliateData[21]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[15] + nonAffiliateData[22]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[16] + nonAffiliateData[23]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[17] + nonAffiliateData[24]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[18] + nonAffiliateData[25]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[19] + nonAffiliateData[26]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[20] + nonAffiliateData[27]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Verified Premium - Monthly</strong></td>
                                            <td>{numeral(nonAffiliateData[14]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[15]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[16]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[17]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[18]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[19]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[20]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Unverified Premium - Monthly</strong></td>
                                            <td>{numeral(nonAffiliateData[21]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[22]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[23]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[24]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[25]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[26]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[27]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Total Premium - Annual</strong></td>
                                            <td>{numeral(nonAffiliateData[28] + nonAffiliateData[35]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[29] + nonAffiliateData[36]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[30] + nonAffiliateData[37]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[31] + nonAffiliateData[38]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[32] + nonAffiliateData[39]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[33] + nonAffiliateData[40]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[34] + nonAffiliateData[41]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Verified Premium - Annual</strong></td>
                                            <td>{numeral(nonAffiliateData[28]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[29]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[30]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[31]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[32]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[33]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[34]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Unverified Premium - Annual</strong></td>
                                            <td>{numeral(nonAffiliateData[35]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[36]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[37]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[38]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[39]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[40]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[41]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Total Complimentary</strong></td>
                                            <td>{numeral(nonAffiliateData[42] + nonAffiliateData[49]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[43] + nonAffiliateData[50]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[44] + nonAffiliateData[51]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[45] + nonAffiliateData[52]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[46] + nonAffiliateData[53]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[47] + nonAffiliateData[54]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[48] + nonAffiliateData[55]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Verified Complimentary</strong></td>
                                            <td>{numeral(nonAffiliateData[42]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[43]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[44]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[45]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[46]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[47]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[48]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Unverified Complimentary</strong></td>
                                            <td>{numeral(nonAffiliateData[49]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[50]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[51]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[52]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[53]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[54]).format('0,0')}</td>
                                            <td>{numeral(nonAffiliateData[55]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Cancellation Requests</strong></td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>{numeral(nonAffiliateData[56]).format('0,0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>&nbsp;</div>
                                <div className="text-center uppercase-text bold-text">Affiliate Attribution</div>
                                <table align="center">
                                    <thead>
                                        <tr>
                                            <th className="report-row-headings"></th>
                                            <th>Today</th>
                                            <th>Yesterday</th>
                                            <th>2 Days Ago</th>
                                            <th>3 Days Ago</th>
                                            <th>7 Days Ago</th>
                                            <th>This Month</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="report-row-headings"><strong>Total Trial</strong></td>
                                            <td>{numeral(affiliateData[0] + affiliateData[7]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[1] + affiliateData[8]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[2] + affiliateData[9]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[3] + affiliateData[10]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[4] + affiliateData[11]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[5] + affiliateData[12]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[6] + affiliateData[13]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Verified Trial</strong></td>
                                            <td>{numeral(affiliateData[0]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[1]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[2]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[3]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[4]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[5]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[6]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Unverified Trial</strong></td>
                                            <td>{numeral(affiliateData[7]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[8]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[9]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[10]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[11]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[12]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[13]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Total Premium - Monthly</strong></td>
                                            <td>{numeral(affiliateData[14] + affiliateData[21]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[15] + affiliateData[22]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[16] + affiliateData[23]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[17] + affiliateData[24]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[18] + affiliateData[25]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[19] + affiliateData[26]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[20] + affiliateData[27]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Verified Premium - Monthly</strong></td>
                                            <td>{numeral(affiliateData[14]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[15]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[16]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[17]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[18]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[19]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[20]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Unverified Premium - Monthly</strong></td>
                                            <td>{numeral(affiliateData[21]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[22]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[23]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[24]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[25]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[26]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[27]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Total Premium - Annual</strong></td>
                                            <td>{numeral(affiliateData[28] + affiliateData[35]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[29] + affiliateData[36]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[30] + affiliateData[37]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[31] + affiliateData[38]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[32] + affiliateData[39]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[33] + affiliateData[40]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[34] + affiliateData[41]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Verified Premium - Annual</strong></td>
                                            <td>{numeral(affiliateData[28]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[29]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[30]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[31]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[32]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[33]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[34]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Unverified Premium - Annual</strong></td>
                                            <td>{numeral(affiliateData[35]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[36]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[37]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[38]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[39]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[40]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[41]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Total Complimentary</strong></td>
                                            <td>{numeral(affiliateData[42] + affiliateData[49]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[43] + affiliateData[50]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[44] + affiliateData[51]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[45] + affiliateData[52]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[46] + affiliateData[53]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[47] + affiliateData[54]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[48] + affiliateData[55]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Verified Complimentary</strong></td>
                                            <td>{numeral(affiliateData[42]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[43]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[44]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[45]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[46]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[47]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[48]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Unverified Complimentary</strong></td>
                                            <td>{numeral(affiliateData[49]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[50]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[51]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[52]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[53]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[54]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[55]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Cancellation Requests</strong></td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>{numeral(affiliateData[56]).format('0,0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>&nbsp;</div>
                                <div className="text-center uppercase-text bold-text">Total Attribution</div>
                                <table align="center">
                                    <thead>
                                        <tr>
                                            <th className="report-row-headings"></th>
                                            <th>Today</th>
                                            <th>Yesterday</th>
                                            <th>2 Days Ago</th>
                                            <th>3 Days Ago</th>
                                            <th>7 Days Ago</th>
                                            <th>This Month</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="report-row-headings"><strong>Total Trial</strong></td>
                                            <td>{numeral(affiliateData[0] + affiliateData[7] + nonAffiliateData[0] + nonAffiliateData[7]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[1] + affiliateData[8] + nonAffiliateData[1] + nonAffiliateData[8]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[2] + affiliateData[9] + nonAffiliateData[2] + nonAffiliateData[9]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[3] + affiliateData[10] + nonAffiliateData[3] + nonAffiliateData[10]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[4] + affiliateData[11] + nonAffiliateData[4] + nonAffiliateData[11]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[5] + affiliateData[12] + nonAffiliateData[5] + nonAffiliateData[12]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[6] + affiliateData[13] + nonAffiliateData[6] + nonAffiliateData[13]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Verified Trial</strong></td>
                                            <td>{numeral(affiliateData[0] + nonAffiliateData[0]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[1] + nonAffiliateData[1]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[2] + nonAffiliateData[2]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[3] + nonAffiliateData[3]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[4] + nonAffiliateData[4]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[5] + nonAffiliateData[5]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[6] + nonAffiliateData[6]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Unverified Trial</strong></td>
                                            <td>{numeral(affiliateData[7] + nonAffiliateData[7]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[8] + nonAffiliateData[8]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[9] + nonAffiliateData[9]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[10] + nonAffiliateData[10]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[11] + nonAffiliateData[11]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[12] + nonAffiliateData[12]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[13] + nonAffiliateData[13]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Total Premium - Monthly</strong></td>
                                            <td>{numeral(affiliateData[14] + affiliateData[21] + nonAffiliateData[14] + nonAffiliateData[21]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[15] + affiliateData[22] + nonAffiliateData[15] + nonAffiliateData[22]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[16] + affiliateData[23] + nonAffiliateData[16] + nonAffiliateData[23]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[17] + affiliateData[24] + nonAffiliateData[17] + nonAffiliateData[24]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[18] + affiliateData[25] + nonAffiliateData[18] + nonAffiliateData[25]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[19] + affiliateData[26] + nonAffiliateData[19] + nonAffiliateData[26]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[20] + affiliateData[27] + nonAffiliateData[20] + nonAffiliateData[27]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Verified Premium - Monthly</strong></td>
                                            <td>{numeral(affiliateData[14] + nonAffiliateData[14]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[15] + nonAffiliateData[15]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[16] + nonAffiliateData[16]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[17] + nonAffiliateData[17]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[18] + nonAffiliateData[18]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[19] + nonAffiliateData[19]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[20] + nonAffiliateData[20]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Unverified Premium - Monthly</strong></td>
                                            <td>{numeral(affiliateData[21] + nonAffiliateData[21]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[22] + nonAffiliateData[22]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[23] + nonAffiliateData[23]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[24] + nonAffiliateData[24]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[25] + nonAffiliateData[25]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[26] + nonAffiliateData[26]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[27] + nonAffiliateData[27]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Total Premium - Annual</strong></td>
                                            <td>{numeral(affiliateData[28] + affiliateData[35] + nonAffiliateData[28] + nonAffiliateData[35]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[29] + affiliateData[36] + nonAffiliateData[29] + nonAffiliateData[36]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[30] + affiliateData[37] + nonAffiliateData[30] + nonAffiliateData[37]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[31] + affiliateData[38] + nonAffiliateData[31] + nonAffiliateData[38]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[32] + affiliateData[39] + nonAffiliateData[32] + nonAffiliateData[39]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[33] + affiliateData[40] + nonAffiliateData[33] + nonAffiliateData[40]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[34] + affiliateData[41] + nonAffiliateData[34] + nonAffiliateData[41]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Verified Premium - Annual</strong></td>
                                            <td>{numeral(affiliateData[28] + nonAffiliateData[28]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[29] + nonAffiliateData[29]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[30] + nonAffiliateData[30]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[31] + nonAffiliateData[31]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[32] + nonAffiliateData[32]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[33] + nonAffiliateData[33]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[34] + nonAffiliateData[34]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Unverified Premium - Annual</strong></td>
                                            <td>{numeral(affiliateData[35] + nonAffiliateData[35]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[36] + nonAffiliateData[36]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[37] + nonAffiliateData[37]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[38] + nonAffiliateData[38]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[39] + nonAffiliateData[39]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[40] + nonAffiliateData[40]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[41] + nonAffiliateData[41]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Total Complimentary</strong></td>
                                            <td>{numeral(affiliateData[42] + affiliateData[49] + nonAffiliateData[42] + nonAffiliateData[49]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[43] + affiliateData[50] + nonAffiliateData[43] + nonAffiliateData[50]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[44] + affiliateData[51] + nonAffiliateData[44] + nonAffiliateData[51]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[45] + affiliateData[52] + nonAffiliateData[45] + nonAffiliateData[52]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[46] + affiliateData[53] + nonAffiliateData[46] + nonAffiliateData[53]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[47] + affiliateData[54] + nonAffiliateData[47] + nonAffiliateData[54]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[48] + affiliateData[55] + nonAffiliateData[48] + nonAffiliateData[55]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Verified Complimentary</strong></td>
                                            <td>{numeral(affiliateData[42] + nonAffiliateData[42]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[43] + nonAffiliateData[43]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[44] + nonAffiliateData[44]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[45] + nonAffiliateData[45]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[46] + nonAffiliateData[46]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[47] + nonAffiliateData[47]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[48] + nonAffiliateData[48]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Unverified Complimentary</strong></td>
                                            <td>{numeral(affiliateData[49] + nonAffiliateData[49]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[50] + nonAffiliateData[50]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[51] + nonAffiliateData[51]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[52] + nonAffiliateData[52]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[53] + nonAffiliateData[53]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[54] + nonAffiliateData[54]).format('0,0')}</td>
                                            <td>{numeral(affiliateData[55] + nonAffiliateData[55]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr>
                                            <td className="report-row-headings"><strong>Cancellation Requests</strong></td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>{numeral(affiliateData[56] + nonAffiliateData[56]).format('0,0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>&nbsp;</div>
                                <div className="col-md-12">Note:</div>
                                <div className="col-md-12">Reports are based on Eastern Time</div>
                                <div className="col-md-12">Reports are based on current customer information</div>
                            </div>
                            ) : (
                                <div className="col-md-12 table-holder">Fetching customer sign up report. Please wait...</div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerSignUpReport;
