import React from 'react';
import { connect } from 'react-redux';

import EditAdminUserForm from '../forms/edit-admin-user-form.component';
import toastr from '../services/toastr';
import history from '../services/history';
import * as api from '../services/api';

class EditAdminUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAdminUser: {}
        };
    }

    componentDidMount() {
        api.getAdminUser(this.props.match.params.email).then((data) => {
            this.setState(() => ({ selectedAdminUser: data }));
        }).catch(() => {
            toastr.error('Error fetching admin user');
        });
    }

    back = () => {
        history.push('/admin-users');
    }

    updateAdminUser = (user) => {
        if (this.props.loggedInUserEmail === this.state.selectedAdminUser.email) {
            toastr.error('You cannot update your own account');
        } else {
            api.updateAdminUser(user).then((data) => {
                toastr.success('Admin user updated successfully');
                history.push('/admin-users');
            }).catch((err) => {
                if (err.message === 'UserNotFound') {
                    toastr.error('Admin user not found');
                } else {
                    toastr.error('Unable to update admin user');
                }
            });
        }
    }

    render() {
        const { loggedInUserEmail } = this.props;
        const { selectedAdminUser } = this.state;
        const initialValues = { email: selectedAdminUser.email, disabled: selectedAdminUser.disabled, firstName: selectedAdminUser.firstName, lastName: selectedAdminUser.lastName, roleName: selectedAdminUser.role ? selectedAdminUser.role.title : '' };
        const editLoginUser = (loggedInUserEmail !== selectedAdminUser.email) && !selectedAdminUser.disabled;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="form-box">
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="header header-color center-form uppercase-text">
                            <div>Edit Admin User</div>
                        </div>
                        <div>&nbsp;</div>
                        <EditAdminUserForm editLoginUser={editLoginUser} initialValues={initialValues} email={initialValues.email} onSubmit={this.updateAdminUser} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedInUserEmail: state.user.email
});

export default connect(mapStateToProps)(EditAdminUser);
