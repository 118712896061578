import * as actionTypes from '../constants/action-types';

const initialAppState = {};

export default (state = initialAppState, action) => {
    switch (action.type) {
        case actionTypes.SIGN_IN_SUCCESS:
            return action.user;
        case actionTypes.SIGN_OUT_SUCCESS:
            return {};
        default:
            return state;
    }
};
