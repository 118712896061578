import React from 'react';
import { Modal } from 'react-bootstrap';

const ConfirmModal = ({ show, onHide, bsSize, title, body, showOkButton = false, okButtonClick, showYesButton = false, yesButtonClick, showNoButton = false, noButtonClick }) => (
    <Modal show={show} bsSize={bsSize} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {body}
        </Modal.Body>
        <Modal.Footer>
            {showOkButton && <button onClick={okButtonClick} className="submit-button modal-button dialog-button">OK</button>}
            {showYesButton && <button onClick={yesButtonClick} className="submit-button modal-button dialog-button">Yes</button>}
            {showNoButton && <button onClick={noButtonClick} className="submit-button modal-button dialog-button">No</button>}
        </Modal.Footer>
    </Modal >
);

export default ConfirmModal;
