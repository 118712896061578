import React from 'react';
import ReactTable from 'react-table';
import toastr from 'toastr';
import WindowSizeListener from 'react-window-size-listener';

import * as api from '../services/api';
import history from '../services/history';
import ConfirmModal from '../widgets/confirm-modal.component';

class UnverifiedPremiumCustomerReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            results: [],
            verifyModalState: false,
            selectedCustomer: null
        };
    }

    componentDidMount() {
        api.getUnverifiedPremiumUsers().then((data) => {
            this.setState(() => ({ results: data }));
        }).catch(() => {
            toastr.error('Error fetching report');
        });
    }

    back = () => {
        history.push('/reports');
    }

    verifyCustomer = () => {
        api.verifyUser(this.state.selectedCustomer).then(() => {
            this.hideVerifyModal();
            toastr.success('Customer has been verified successfully');
            this.componentDidMount();
        }).catch((err) => {
            this.hideVerifyModal();
            toastr.error('Error verifying customer');
            this.componentDidMount();
        });
    }

    viewCustomer = (email) => {
        history.push(`/view-customer/${email}/${email}?source=uvpcr`);
    }

    showVerifyModal = (email) => {
        this.setState(() => ({ verifyModalState: true }));
        this.setState(() => ({ selectedCustomer: email }));
    }

    hideVerifyModal = (email) => {
        this.setState(() => ({ verifyModalState: false }));
        this.setState(() => ({ selectedCustomer: null }));
    }

    columns = [{
        Header: 'View',
        id: 'view',
        accessor: d => d.email,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 50,
        sortable: false,
        Cell: row => <button className="btn btn-xs btn-primary" onClick={() => this.viewCustomer(row.value)}>View</button>
    }, {
        Header: 'Verify',
        id: 'verify',
        accessor: d => d.email,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 60,
        sortable: false,
        Cell: row => <button className="btn btn-xs btn-primary" onClick={() => this.showVerifyModal(row.value)}>Verify</button>
    }, {
        Header: 'Username',
        accessor: 'email',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 350,
        sortable: true,
        filterable: true
    }, {
        Header: 'Name',
        id: 'fullName',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 350,
        sortable: true,
        filterable: true,
        accessor: d => d.firstName + ' ' + d.lastName
    }];

    render() {
        const { results, verifyModalState } = this.state;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="container">
                        <div>&nbsp;</div>
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="col-md-12 text-center uppercase-text bolder-text">
                            <h2 className="header-color">Unverified Premium Customer Report</h2>
                        </div>
                        <div>
                            <div className="unverified-premium-customers-table">
                                <ReactTable
                                    className="-striped -highlight"
                                    data={results}
                                    columns={this.columns}
                                    defaultPageSize={10}
                                    noDataText="No customers found"
                                    defaultFilterMethod={
                                        (filter, row) => {
                                            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <ConfirmModal
                            showYesButton={true}
                            showNoButton={true}
                            bsSize={'sm'}
                            show={verifyModalState}
                            onHide={this.hideVerifyModal}
                            title={'YipTV Admin Portal'}
                            body={'Are you sure you want to verify this customer?'}
                            yesButtonClick={this.verifyCustomer}
                            noButtonClick={this.hideVerifyModal}
                        />
                        <WindowSizeListener onResize={window => {
                            if (window.windowWidth <= 767) {
                                this.hideVerifyModal();
                            }
                        }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default UnverifiedPremiumCustomerReport;
