import * as actionTypes from '../constants/action-types';
import * as api from '../services/api';

export const loadChannelLogosSuccess = (data = []) => (
    {
        type: actionTypes.LOAD_CHANNEL_LOGOS_SUCCESS,
        data
    }
);

export const loadChannelLogos = () => {
    return (dispatch, getState) => {
        return api.getAllChannelLogos().then((data) => {
            dispatch(loadChannelLogosSuccess(data));
        }).catch((err) => {
            throw new Error(err);
        });
    };
};
