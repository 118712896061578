import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import logo from '../images/logo.png';
import { setMenuState } from '../actions/app-state.action';
import Menu from '../widgets/menu.component';
import history from '../services/history';

class Header extends React.Component {

    onMenuClick = () => {
        this.props.setMenuState(!this.props.menuState);
    }

    onLogoClick = () => {
        if(this.props.isAuthenticated) {
            history.push('/user-home');
        } else {
            history.push('/');
        }
    }

    render() {
        const { isAuthenticated, displayName, menuState } = this.props;
        return (
            <div>
                <header>
                    <nav className="navbar navbar-default">
                        <div>
                            <div className="nav-picture">
                                <img className="img-responsive" onClick={this.onLogoClick} src={logo} alt="" />
                            </div>
                            <div className="top-nav-div">
                            </div>
                            <div className="bottom-nav-div">
                                <div className="container">
                                    <div id="navbar" className="navbar-collapse collapse">
                                        <ul className="nav navbar-nav">
                                            {isAuthenticated && <li>
                                                <span onClick={this.onMenuClick} className="glyphicon glyphicon-menu-hamburger menu-button" title="Menu" aria-hidden="true"></span>
                                            </li>}
                                        </ul>
                                        <ul className="nav navbar-nav navbar-right">
                                            {isAuthenticated ?
                                                (<li className="menu-highlight-button">
                                                    <span className="username">{displayName}</span>
                                                </li>
                                                )
                                                :
                                                (<li className="menu-highlight-button">
                                                    <Link to="/sign-in">Sign In</Link>
                                                </li>)
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
                <Menu menuState={menuState} />
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    isAuthenticated: !!(state.user && state.user.email),
    displayName: state.user ? `${state.user.firstName} ${state.user.lastName} \n [${state.user.role}]` : '',
    menuState: state.appState.menuState
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    setMenuState: (menuState) => dispatch(setMenuState(menuState))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
