import React from 'react';
import toastr from 'toastr';
import moment from 'moment';
import numeral from 'numeral';
import DateTime from 'react-datetime';

import * as api from '../services/api';
import history from '../services/history';

class CardSalesReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().startOf('day'),
            endDate: moment().endOf('day'),
            startDateOpen: false,
            endDateOpen: false,
            pageStatus: 0,
            processing: false,
            customerTotal: 0,
            amountTotal: 0,
            results: [],
            dateError: false
        };
    }

    onStartDateChange = (startDate) => {
        this.setState(() => ({ startDate: startDate.startOf('day') }));
        this.setState(() => ({ startDateOpen: false }), () => { this.checkDates(); });
    }

    onEndDateChange = (endDate) => {
        this.setState(() => ({ endDate: endDate.endOf('day') }));
        this.setState(() => ({ endDateOpen: false }), () => { this.checkDates(); });
    }

    onStartDateFocus = () => {
        this.setState(() => ({ startDateOpen: false }));
    }

    onEndDateFocus = () => {
        this.setState(() => ({ endDateOpen: false }));
    }

    checkDates = () => {
        const dateError = this.state.startDate.isAfter(this.state.endDate);
        this.setState(() => ({ dateError }));
    }

    toggleStartDateOpen = () => {
        this.setState(() => ({ startDateOpen: !this.state.startDateOpen }));
    }

    toggleEndDateOpen = () => {
        this.setState(() => ({ endDateOpen: !this.state.endDateOpen }));
    }

    back = () => {
        history.push('/reports');
    }

    formSubmit = (e) => {
        e.preventDefault();
        const data = { startDateUtc: this.getStartDate(this.convertDateToUtc(this.state.startDate)), endDateUtc: this.getEndDate(this.convertDateToUtc(this.state.endDate)) };
        this.setState(() => ({ pageStatus: 1 }));
        this.setState(() => ({ processing: true }));
        api.getCardSalesReport(data).then((data) => {
            const customerTotal = data.counts.reduce((a, b) => { return a + b; }, 0);
            const amountTotal = this.getAmountTotal(data.amounts, data.counts);
            this.setState(() => ({ results: data }));
            this.setState(() => ({ customerTotal }));
            this.setState(() => ({ amountTotal }));
            this.setState(() => ({ pageStatus: 2 }));
            this.setState(() => ({ processing: false }));
        }).catch(() => {
            this.setState(() => ({ results: [] }));
            this.setState(() => ({ customerTotal: 0 }));
            this.setState(() => ({ amountTotal: 0 }));
            this.setState(() => ({ pageStatus: 0 }));
            toastr.error('Error fetching card sales report');
            this.setState(() => ({ processing: false }));
        });
    }

    getAmountTotal = (amounts, counts) => {
        if (amounts.length !== counts.length) {
            return 0;
        }
        let total = 0;
        for (let i = 0; i < amounts.length; i++) {
            total += amounts[i] * counts[i];
        }
        return total;
    }

    getStartDate = (date) => {
        return moment(date).startOf('day').toDate().toISOString();
    }

    getEndDate = (date) => {
        return moment(date).endOf('day').toDate().toISOString();
    }

    convertDateToUtc = (date) => {
        var offset = date.utcOffset();
        date = date.utc();
        date.add(offset, 'minutes');
        return date;
    }

    render() {
        const { pageStatus, processing, startDate, startDateOpen, endDate, endDateOpen, customerTotal, amountTotal, results, dateError } = this.state;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="form-box">
                        <div>&nbsp;</div>
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="col-md-12 text-center uppercase-text bolder-text">
                            <h2 className="header-color">Card Sales Report</h2>
                        </div>
                        <form name="form" id="form" onSubmit={this.formSubmit} noValidate>
                            <div className="half-form">
                                <span className="label-text">Start Date</span>
                                <br />
                                <div className="input-group date-group">
                                    <DateTime
                                        value={startDate}
                                        dateFormat={'MM/DD/YYYY'}
                                        timeFormat={false}
                                        onChange={this.onStartDateChange}
                                        inputProps={{ readOnly: true, required: true, className: 'text-box form-control' }}
                                        closeOnSelect={true}
                                        open={startDateOpen}
                                        onFocus={this.onStartDateFocus}
                                        closeOnTab={false}
                                    />
                                    <span className="input-group-btn">
                                        <button onClick={this.toggleStartDateOpen} type="button" className="btn btn-default btn-calendar"><i className="glyphicon glyphicon-calendar"></i></button>
                                    </span>
                                </div>
                            </div>
                            <div className="half-form">
                                <span className="label-text">End Date</span>
                                <br />
                                <div className="input-group date-group">
                                    <DateTime
                                        value={endDate}
                                        dateFormat={'MM/DD/YYYY'}
                                        timeFormat={false}
                                        onChange={this.onEndDateChange}
                                        inputProps={{ readOnly: true, required: true, className: 'text-box form-control ' + (dateError ? 'text-box-error' : '') }}
                                        closeOnSelect={true}
                                        open={endDateOpen}
                                        onFocus={this.onEndDateFocus}
                                        closeOnTab={false}
                                    />
                                    <span className="input-group-btn">
                                        <button onClick={this.toggleEndDateOpen} type="button" className={'btn btn-default btn-calendar ' + (dateError ? 'btn-calendar-error' : '')}><i className="glyphicon glyphicon-calendar"></i></button>
                                    </span>
                                </div>
                                {dateError && <div className="error-message">End date cannot be before start date</div>}
                            </div>
                            <div className="full-form">
                                <button disabled={processing} type="submit" className="submit-button">Get Report</button>
                                <br /><br />
                            </div>
                            <div className="full-form"></div>
                            <div className="full-form"></div>
                        </form>
                    </div>
                    {pageStatus === 1 && <div className="col-md-12 table-holder">Fetching card sales report. Please wait...</div>}
                    {pageStatus === 2 && <div className="col-md-12 table-holder">
                        <table align="center">
                            <thead>
                                <tr>
                                    <th>Amount</th>
                                    <th>Customers</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    results.amounts.map((amount, index) =>
                                        (
                                            <tr key={index}>
                                                <td>{numeral(amount).format('$0,0.00')}</td>
                                                <td>{numeral(results.counts[index]).format('0,0')}</td>
                                                <td>{numeral(amount * results.counts[index]).format('$0,0.00')}</td>
                                            </tr>
                                        )
                                    )
                                }
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Total</th>
                                    <th>{numeral(customerTotal).format('0,0')}</th>
                                    <th>{numeral(amountTotal).format('$0,0.00')}</th>
                                </tr>
                            </thead>
                        </table>
                        <div>&nbsp;</div>
                        <div className="col-md-12">Note:</div>
                        <div className="col-md-12">Reports are based on Eastern Time</div>
                        <div className="col-md-12">Reports are based on current customer information</div>
                    </div>}
                </div>
            </div>
        );
    }
}

export default CardSalesReport;
