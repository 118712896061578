import React from 'react';
import { Modal } from 'react-bootstrap';

import UpdatePreferencesForm from '../forms/update-preferences-form.component';
import * as api from '../services/api';
import toastr from '../services/toastr';

class UpdatePreferences extends React.Component {

    updatePreferences = (data) => {
        const values = {...data, email: this.props.customer};
        api.updatePreferences(values).then((data) => {
            toastr.success('Customer\'s preferences updated successfully');
            this.props.afterAction();
        }).catch((err) => {
            toastr.error('Error updating customer\'s preferences');
            this.props.afterAction();
        });
    }

    render() {
        const { show, onHide, customerDetails } = this.props;
        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton bsClass="customer-modal-header">
                </Modal.Header>
                <Modal.Body bsClass="customer-modal-body">
                    <UpdatePreferencesForm initialValues={customerDetails} onSubmit={this.updatePreferences} />
                </Modal.Body>
                <Modal.Footer bsClass="customer-modal-footer">
                    &nbsp;
                </Modal.Footer>
            </Modal>
        );
    }
}

export default UpdatePreferences;
