import React from 'react';
import { Modal } from 'react-bootstrap';

import EmailInvoiceForm from '../forms/email-invoice-form.component';
import * as api from '../services/api';
import toastr from '../services/toastr';
import * as validations from '../services/validations';

class AddCharge extends React.Component {

    emailInvoice = (data) => {
        const values = { ...data, email: this.props.customer, invoiceNumber: this.props.invoiceNumber };
        api.emailInvoice(values).then((data) => {
            toastr.success('Invoice emailed to customer successfully');
            this.props.afterAction();
        }).catch((err) => {
            toastr.error('Error emailing invoice to customer');
            this.props.afterAction();
        });
    }

    render() {
        const { show, onHide, customerDetails } = this.props;
        let email = '';
        if (validations.isEmail(customerDetails.email)) {
            email = customerDetails.email;
        } else if (customerDetails.support && validations.isEmail(customerDetails.support.altContact1)) {
            email = customerDetails.support.altContact1;
        } else if (customerDetails.support && validations.isEmail(customerDetails.support.altContact2)) {
            email = customerDetails.support.altContact2;
        }
        const initialValues = { sendToEmail: email };
        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton bsClass="customer-modal-header">
                </Modal.Header>
                <Modal.Body bsClass="customer-modal-body">
                    <EmailInvoiceForm initialValues={initialValues} onSubmit={this.emailInvoice} />
                </Modal.Body>
                <Modal.Footer bsClass="customer-modal-footer">
                    &nbsp;
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddCharge;
