import React from 'react';

import ChangeMyPasswordForm from '../forms/change-my-password-form.component';
import toastr from '../services/toastr';
import history from '../services/history';
import * as api from '../services/api';

class ChangeMyPassword extends React.Component {

    changeMyPassword = (user) => {
        api.changeMyPassword(user).then((data) => {
            sessionStorage.setItem('token', data.token);
            toastr.success('Password has been changed successfully');
            history.push('/user-home');
        }).catch((err) => {
            if (err.message === 'Unauthorized') {
                toastr.error('Current Password entered is incorrect');
            } else {
                toastr.error('Error occurred while changing password');
            }
        });
    }

    render() {
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="form-box">
                        <div className="header header-color center-form uppercase-text">
                            <div>Change My Password</div>
                        </div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <ChangeMyPasswordForm onSubmit={this.changeMyPassword} />
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangeMyPassword;
