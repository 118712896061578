import React from 'react';
import { Field, reduxForm } from 'redux-form';

import * as validations from '../services/validations';

const validate = (values) => {
    const errors = {};
    if (validations.isEmpty(values.firstName)) {
        errors.firstName = 'Enter customer\'s first name';
    } else if (!validations.isName(values.firstName)) {
        errors.firstName = 'Enter a valid customer first name';
    }
    if (validations.isEmpty(values.lastName)) {
        errors.lastName = 'Enter customer\'s last name';
    } else if (!validations.isName(values.lastName)) {
        errors.lastName = 'Enter a valid customer last name';
    }
    return errors;
};

const renderField = ({ input, type, maxLength, meta: { touched, error } }) => {
    return (
        <div>
            <input {...input} autoComplete="off" maxLength={maxLength} type={type} className={"text-box " + (touched && error ? "text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const UpdateUserInfoForm = (props) => {
    const { handleSubmit, submitting } = props;
    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <span className="label-text">First Name</span>
            <br />
            <Field name="firstName" type="text" maxLength="20" component={renderField} />
            <br />
            <span className="label-text">Last Name</span><br />
            <Field name="lastName" maxLength="20" type="text" component={renderField} />
            <br />
            <button type="submit" disabled={submitting} className="submit-button">Submit</button>
        </form>
    );
};

export default reduxForm({ form: 'updateUserInfo', validate })(UpdateUserInfoForm);
