import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import Header from '../widgets/header.component';
import Footer from '../widgets/footer.component';

const PrivateRoute = ({ isAuthenticated, isAuthorized, component: Component, ...rest }) => (
    <Route {...rest} component={(props) => (isAuthenticated && isAuthorized ?
        (
            <div className="wrapper">
                <div className="wrapper-body">
                    <Header />
                    <Component {...props} />
                </div>
                <Footer />
            </div>
        )
        :
        (
            <Redirect to="/sign-in" />
        )
    )} />
);

const checkIfAuthorized = (roles, userRole) => {
    if (roles.length < 0) {
        return false;
    }
    if (roles[0] === 'all') {
        return true;
    }
    return roles.includes(userRole);
};

const mapStateToProps = (state, ownProps) => ({
    isAuthenticated: !!(state.user && state.user.email),
    isAuthorized: checkIfAuthorized(ownProps.roles, state.user.role)
});

export default connect(mapStateToProps)(PrivateRoute);
