import React from 'react';

import BigNavButton from '../widgets/big-nav-button.component';
import security from '../services/security';

const Reports = () => (
    <div className="content-area">
        <div className="content-box">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center uppercase-text bolder-text">
                        <h2 className="header-color">Reports</h2>
                    </div>
                </div>
                <div className="row">&nbsp;</div>
                <BigNavButton button name="Customer Sign Ups" link="/customer-sign-up-report" roles={security["customer-sign-up-report"]} />
                <BigNavButton button name="Card Sales" link="/card-sales-report" roles={security["card-sales-report"]} />
                <BigNavButton button name="In-App Sales" link="/in-app-sales-report" roles={security["in-app-sales-report"]} />
                <BigNavButton button name="Merchant Sales" link="/merchant-sales-report" roles={security["merchant-sales-report"]} />
                <BigNavButton button name="UTM Sales" link="/utm-sales-report" roles={security["utm-sales-report"]} />
                <BigNavButton button name="Unverified Premium Customers" link="/unverified-premium-customer-report" roles={security["unverified-premium-customer-report"]} />
                <BigNavButton button name="Unpaid Premium Customers" link="/unpaid-premium-customer-report" roles={security["unpaid-premium-customer-report"]} />
            </div>
        </div>
    </div>
);

export default Reports;
