import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import UpgradeSubscriptionForm from '../forms/upgrade-subscription-form.component';
import * as api from '../services/api';
import toastr from '../services/toastr';
import goDaddySeal from '../images/go-daddy-seal.gif';

class UpgradeSubscription extends React.Component {

    upgradeSubscription = (data) => {
        window.Stripe.setPublishableKey(this.props.stripePublishableKey);
        window.Stripe.card.createToken({ name: data.cardName, number: data.cardNumber, cvc: data.cvv, exp_month: data.expiryDate.substring(0, 2), exp_year: data.expiryDate.substring(5), address_line1: data.address, address_city: data.city, address_state: data.state, address_zip: data.zipCode, address_country: 'US' }, (status, response) => {
            if (status !== 200) {
                toastr.error('Unable to charge customer\'s card. Please check card details provided by the customer.');
            } else {
                const values = { ...data, email: this.props.customer };
                values.token = response.id;
                values.last4 = response.card.last4;
                values.brand = response.card.brand;
                values.funding = response.card.funding;
                values.country = 'US';
                values.cardNumber = undefined;
                values.cvv = undefined;
                values.expiryDate = undefined;
                api.upgradeSubscription(values).then((data) => {
                    toastr.success('Customer has been upgraded to premium subscription successfully');
                    this.props.afterAction();
                }).catch((err) => {
                    if (err.message === 'PremiumAccount') {
                        toastr.error('Error upgrading subscription as customer already has a premium subscription.');
                    } else if (err.message === 'PaymentFailed') {
                        toastr.error('Unable to charge customer\'s card. Please check card details provided by the customer.');
                    } else {
                        toastr.error('Error upgrading customer\'s subscription to premium');
                    }
                    this.props.afterAction();
                });
            }
        });
    }

    goDaddySealClicked = () => {
        window.open('https://seal.godaddy.com/verifySeal?sealID=xMtT6BHQGZlXGWOQ3gqbjslvJy4XngLJqKU7BeqHlM1Y56nxL3TbUii3Weaw', 'goDaddy', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=608,height=508');
    }

    render() {
        const { show, onHide, customerDetails } = this.props;
        const initialValues = { plan: 'yearly', merchant: customerDetails.merchant };
        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton bsClass="customer-modal-header">
                </Modal.Header>
                <Modal.Body bsClass="customer-modal-body">
                    <UpgradeSubscriptionForm initialValues={initialValues} customerDetails={customerDetails} onSubmit={this.upgradeSubscription} />
                    <div>&nbsp;</div>
                    <div className="full-form center-form">
                        <img className="clickable" onClick={this.goDaddySealClicked} alt="" src={goDaddySeal} />
                    </div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </Modal.Body>
                <Modal.Footer bsClass="customer-modal-footer">
                    &nbsp;
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    stripePublishableKey: state.config.stripePublishableKey
});

export default connect(mapStateToProps)(UpgradeSubscription);
