import React from 'react';
import { connect } from 'react-redux';

import EditChannelForm from '../forms/edit-channel-form.component';
import toastr from '../services/toastr';
import history from '../services/history';
import * as api from '../services/api';

class EditChannel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedChannel: {}
        };
    }

    componentDidMount() {
        api.getChannel(this.props.match.params.channelCode).then((data) => {
            this.setState(() => ({ selectedChannel: data }));
        }).catch(() => {
            toastr.error('Error fetching channel');
        });
    }

    back = () => {
        history.push('/channels');
    }

    updateChannel = (channel) => {
        var data = JSON.parse(JSON.stringify(channel));
        data.logo = data.logo.key;
        api.updateChannel(data).then((data) => {
            toastr.success('Channel updated successfully');
            history.push('/channels');
        }).catch((err) => {
            if (err.message === 'ChannelNotFound') {
                toastr.error('Channel not found');
            } else {
                toastr.error('Unable to update channel');
            }
        });
    }

    getTagCode = (tags, tagType) => {
        if (tags && tags.length > 0) {
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].startsWith(tagType)) {
                    return tags[i];
                }
            }
            return '';
        } else {
            return '';
        }
    }

    render() {
        const { selectedChannel: { code, name, url, order, metadataId, type, logo, tags, disabled } } = this.state;
        const { channelLogoUrl } = this.props;
        const initialValues = { code, name, url, order: order ? order.toString() : '', metadataId, type, logo, disabled };
        initialValues.genre = this.getTagCode(tags, 'GENR');
        initialValues.language = this.getTagCode(tags, 'LANG');
        initialValues.origin = this.getTagCode(tags, 'ORGN');
        initialValues.audience = this.getTagCode(tags, 'AUDC');
        initialValues.logo = { key: logo, text: logo };
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="form-box">
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="header header-color center-form uppercase-text">
                            <div>Edit Channel</div>
                        </div>
                        <div>&nbsp;</div>
                        <EditChannelForm initialValues={initialValues} channelLogoUrl={channelLogoUrl} onSubmit={this.updateChannel} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    channelLogoUrl: state.config.channelLogoUrl,
});

export default connect(mapStateToProps)(EditChannel);
