import React from 'react';
import toastr from 'toastr';
import moment from 'moment';
import numeral from 'numeral';
import DateTime from 'react-datetime';

import * as api from '../services/api';
import history from '../services/history';

class MerchantSalesReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().startOf('day'),
            endDate: moment().endOf('day'),
            startDateOpen: false,
            endDateOpen: false,
            pageStatus: 0,
            processing: false,
            results: [],
            dateError: false,
            merchants: [],
            merchant: 'YIPTV'
        };
    }

    componentDidMount() {
        api.getAllMerchants().then((data) => {
            const sortedData = data.sort(function (a, b) {
                var nameA = a.name.toUpperCase();
                var nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            this.setState(() => ({ merchants: sortedData }));
        }).catch(() => {
            toastr.error('Error fetching merchants');
        });
    }

    onMerchantChange = (e) => {
        const value = e.target.value;
        this.setState(() => ({ merchant: value }));
    }

    onStartDateChange = (startDate) => {
        this.setState(() => ({ startDate: startDate.startOf('day') }));
        this.setState(() => ({ startDateOpen: false }), () => { this.checkDates(); });
    }

    onEndDateChange = (endDate) => {
        this.setState(() => ({ endDate: endDate.endOf('day') }));
        this.setState(() => ({ endDateOpen: false }), () => { this.checkDates(); });
    }

    onStartDateFocus = () => {
        this.setState(() => ({ startDateOpen: false }));
    }

    onEndDateFocus = () => {
        this.setState(() => ({ endDateOpen: false }));
    }

    checkDates = () => {
        const dateError = this.state.startDate.isAfter(this.state.endDate);
        this.setState(() => ({ dateError }));
    }

    toggleStartDateOpen = () => {
        this.setState(() => ({ startDateOpen: !this.state.startDateOpen }));
    }

    toggleEndDateOpen = () => {
        this.setState(() => ({ endDateOpen: !this.state.endDateOpen }));
    }

    back = () => {
        history.push('/reports');
    }

    formSubmit = (e) => {
        e.preventDefault();
        const data = { merchant: this.state.merchant, startDateUtc: this.getStartDate(this.convertDateToUtc(this.state.startDate)), endDateUtc: this.getEndDate(this.convertDateToUtc(this.state.endDate)) };
        this.setState(() => ({ pageStatus: 1 }));
        this.setState(() => ({ processing: true }));
        api.getMerchantSalesReport(data).then((data) => {
            this.setState(() => ({ results: data }));
            this.setState(() => ({ pageStatus: 2 }));
            this.setState(() => ({ processing: false }));
        }).catch(() => {
            this.setState(() => ({ results: [] }));
            this.setState(() => ({ pageStatus: 0 }));
            toastr.error('Error fetching in-app sales report');
            this.setState(() => ({ processing: false }));
        });
    }

    getStartDate = (date) => {
        return moment(date).startOf('day').toDate().toISOString();
    }

    getEndDate = (date) => {
        return moment(date).endOf('day').toDate().toISOString();
    }

    convertDateToUtc = (date) => {
        var offset = date.utcOffset();
        date = date.utc();
        date.add(offset, 'minutes');
        return date;
    }

    render() {
        const { pageStatus, processing, startDate, startDateOpen, endDate, endDateOpen, results, dateError, merchants, merchant } = this.state;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="form-box">
                        <div>&nbsp;</div>
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="col-md-12 text-center uppercase-text bolder-text">
                            <h2 className="header-color">Merchant Sales Report</h2>
                        </div>
                        <form name="form" id="form" onSubmit={this.formSubmit} noValidate>
                            <div className="full-form">
                                <span className="label-text">Merchant</span>
                                <br />
                                <select className="text-box dropdown-list" value={merchant} onChange={this.onMerchantChange}>
                                    {merchants.map((m, index) => (
                                        <option key={index} value={m.code}>{m.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="half-form">
                                <span className="label-text">Start Date</span>
                                <br />
                                <div className="input-group date-group">
                                    <DateTime
                                        value={startDate}
                                        dateFormat={'MM/DD/YYYY'}
                                        timeFormat={false}
                                        onChange={this.onStartDateChange}
                                        inputProps={{ readOnly: true, required: true, className: 'text-box form-control' }}
                                        closeOnSelect={true}
                                        open={startDateOpen}
                                        onFocus={this.onStartDateFocus}
                                        closeOnTab={false}
                                    />
                                    <span className="input-group-btn">
                                        <button onClick={this.toggleStartDateOpen} type="button" className="btn btn-default btn-calendar"><i className="glyphicon glyphicon-calendar"></i></button>
                                    </span>
                                </div>
                            </div>
                            <div className="half-form">
                                <span className="label-text">End Date</span>
                                <br />
                                <div className="input-group date-group">
                                    <DateTime
                                        value={endDate}
                                        dateFormat={'MM/DD/YYYY'}
                                        timeFormat={false}
                                        onChange={this.onEndDateChange}
                                        inputProps={{ readOnly: true, required: true, className: 'text-box form-control ' + (dateError ? 'text-box-error' : '') }}
                                        closeOnSelect={true}
                                        open={endDateOpen}
                                        onFocus={this.onEndDateFocus}
                                        closeOnTab={false}
                                    />
                                    <span className="input-group-btn">
                                        <button onClick={this.toggleEndDateOpen} type="button" className={'btn btn-default btn-calendar ' + (dateError ? 'btn-calendar-error' : '')}><i className="glyphicon glyphicon-calendar"></i></button>
                                    </span>
                                </div>
                                {dateError && <div className="error-message">End date cannot be before start date</div>}
                            </div>
                            <div className="full-form">
                                <button disabled={processing} type="submit" className="submit-button">Get Report</button>
                                <br /><br />
                            </div>
                            <div className="full-form"></div>
                            <div className="full-form"></div>
                        </form>
                    </div>
                    {pageStatus === 1 && <div className="col-md-12 table-holder">Fetching merchant sales report. Please wait...</div>}
                    {pageStatus === 2 && <div className="col-md-12 table-holder">
                        <table align="center">
                            <thead>
                                <tr>
                                    <th>Premium Sign Up</th>
                                    <th>Trial Sign Up</th>
                                    <th>Complimentary Sign Up</th>
                                    <th>Total Sign Up</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{numeral(results[1]).format('0,0')}</td>
                                    <td>{numeral(results[2]).format('0,0')}</td>
                                    <td>{numeral(results[3]).format('0,0')}</td>
                                    <td>{numeral(results[0]).format('0,0')}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div>&nbsp;</div>
                        <div className="col-md-12">Note:</div>
                        <div className="col-md-12">Reports are based on Eastern Time</div>
                        <div className="col-md-12">Reports are based on current customer information</div>
                    </div>}
                </div>
            </div>
        );
    }
}

export default MerchantSalesReport;
