import React from 'react';

const NotFound = () => (
    <div className="content-area">
        <div className="content-box">
            <div>&nbsp;</div>
            <div className="col-md-12 text-center uppercase-text bolder-text">
                <h2 className="header-color">
                    Page Not Found
                </h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <p className="text-center">
                    The page you are looking for does not exist
                </p>
            </div>
        </div>
    </div>
);

export default NotFound;
