import React from 'react';

import Header from '../widgets/header.component';
import Footer from '../widgets/footer.component';

const CommonRoute = ({ component: Component, ...rest }) => (
    <div className="wrapper">
        <div className="wrapper-body">
            <Header />
            <Component {...rest} />
        </div>
        <Footer />
    </div>
);

export default CommonRoute;
