import React from 'react';
import { connect } from 'react-redux';

import history from '../services/history';

const BigNavButton = ({ name, link, isAuthorized }) => (
    <div>
        {
            isAuthorized &&
            <div className="row text-center big-nav-btn-div">
                <button onClick={() => {
                    history.push(link);
                }} className="btn btn-primary big-nav-btn" >{name}</button>
            </div>
        }
    </div>
);

const checkIfAuthorized = (roles, userRole) => {
    if (roles.length < 0) {
        return false;
    }
    if (roles[0] === 'all') {
        return true;
    }
    return roles.includes(userRole);
};

const mapStateToProps = (state, ownProps) => ({
    isAuthorized: checkIfAuthorized(ownProps.roles, state.user.role)
});

export default connect(mapStateToProps)(BigNavButton);
