import React from 'react';

import SetPasswordForm from '../forms/set-password-form.component';
import toastr from '../services/toastr';
import history from '../services/history';
import * as api from '../services/api';

class SetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageStatus: 0
        };
    }

    componentDidMount() {
        api.checkPasswordToken(this.props.match.params.token).then(() => {
            this.setState(() => ({ pageStatus: 1 }));
        }).catch(() => {
            this.setState(() => ({ pageStatus: 2 }));
        });
    }

    setPassword = (user) => {
        api.setPassword({ token: this.props.match.params.token, password: user.password }).then(() => {
            toastr.success('Your password has been set. Please sign in.');
            history.push('/sign-in');
        }).catch(() => {
            toastr.error('Unable to set your password');
        });
    }

    render() {
        const { pageStatus } = this.state;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="form-box">
                        {pageStatus === 0 && <h4 className="text-center">Verifying password token...</h4>}
                        {pageStatus === 2 && <h4 className="text-center">Password token has expired or is invalid</h4>}
                        {pageStatus === 1 &&
                            <div>
                                <div className="header header-color center-form uppercase-text">
                                    <div>Set Your Account Password</div>
                                </div>
                                <div>&nbsp;</div>
                                <SetPasswordForm onSubmit={this.setPassword} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default SetPassword;
