export const getCardType = (value) => {
    if (!value) {
        return '';
    }
    if(/^(36|38|30[0-5])/.test(value)) {
        return 'dinersclub';
    }
    if(/^35/.test(value)) {
        return 'jcb';
    }
    if(/^(6011|65|64[4-9]|622)/.test(value)) {
        return 'discover';
    }
    if(/^(5[1-5]|222|272)/.test(value)) {
        return 'mastercard';
    }
    if(/^3[47]/.test(value)) {
        return 'amex';
    }
    if(/^4/.test(value)) {
        return 'visa';
    }
    return '';
};
