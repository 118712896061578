import React from 'react';
import ReactTable from 'react-table';
import toastr from 'toastr';
import moment from 'moment';
import { connect } from 'react-redux';
import WindowSizeListener from 'react-window-size-listener';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import * as api from '../services/api';
import history from '../services/history';
import ConfirmModal from '../widgets/confirm-modal.component';

class ComplimentaryCodes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            results: [],
            toggleStatusModalState: false,
            selectedComplimentaryCode: null,
            selectedUrl: ''
        };
    }

    componentDidMount() {
        api.getAllComplimentaryCodes().then((data) => {
            this.setState(() => ({ results: data }));
        }).catch(() => {
            toastr.error('Error fetching complimentary codes');
        });
    }

    columns = [{
        Header: 'View URL',
        id: 'url',
        accessor: d => d.code,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 85,
        sortable: false,
        Cell: row => <button className="btn btn-xs btn-primary" onClick={() => {
            this.viewUrl(row.value);
        }}>View URL</button>
    }, {
        Header: 'Toggle Status',
        id: 'toggle',
        accessor: d => d.code,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 120,
        sortable: false,
        Cell: row => <button className="btn btn-xs btn-primary" onClick={() => {
            this.showToggleStatusModal(row.value);
        }}>Toggle Status</button>
    }, {
        Header: 'Status',
        id: 'disabled',
        accessor: d => d.disabled ? 'Disabled' : 'Active',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 125,
        sortable: true,
        filterable: true,
        filterMethod: (filter, row) => {
            if (filter.value === "All") {
                return true;
            }
            return row[filter.id] === this.capitalize(filter.value);
        },
        Filter: ({ filter, onChange }) =>
            <select
                onChange={event => onChange(event.target.value)}
                className="grid-filter-dropdown"
                value={filter ? filter.value : "all"}
            >
                <option value="All"></option>
                <option value="Active">Active</option>
                <option value="Disabled">Disabled</option>
            </select>

    }, {
        Header: 'Code',
        id: 'code',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 350,
        sortable: true,
        filterable: true,
        accessor: d => d.code
    }, {
        Header: 'Requested By',
        id: 'requestedBy',
        accessor: d => d.requestedBy,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 150,
        filterable: true,
        sortable: true
    }, {
        Header: 'Department',
        id: 'department',
        accessor: d => d.department,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 150,
        filterable: true,
        sortable: true
    }, {
        Header: 'Reason',
        id: 'reason',
        accessor: d => d.reason,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 250,
        filterable: true,
        sortable: true
    }, {
        Header: 'Start Date',
        id: 'startDate',
        accessor: d => d.startDate,
        Cell: row => moment.utc(row.value).format('MMM D, YYYY'),
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 125,
        filterable: false,
        sortable: true
    }, {
        Header: 'End Date',
        id: 'endDate',
        accessor: d => d.endDate,
        Cell: row => moment.utc(row.value).format('MMM D, YYYY'),
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 125,
        filterable: false,
        sortable: true
    }, {
        Header: 'Duration (days)',
        id: 'duration',
        accessor: d => d.duration,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 155,
        filterable: false,
        sortable: true
    }, {
        Header: 'Maximum Accounts',
        id: 'maximumAccounts',
        accessor: d => d.maximumAccounts,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 185,
        filterable: false,
        sortable: true
    }, {
        Header: 'Account Count',
        id: 'accountCount',
        accessor: d => d.accountCount,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 155,
        filterable: false,
        sortable: true
    }];

    capitalize(value) {
        if (value && value.length > 1) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        if (value && value.length === 1) {
            return value.charAt(0).toUpperCase();
        }
        return value;
    }

    toggleComplimentaryCodeStatus = () => {
        api.toggleComplimentaryCodeStatus(this.state.selectedComplimentaryCode).then((data) => {
            toastr.success('Complimentary code status changed successfully');
            this.componentDidMount();
            this.hideToggleStatusModal();
        }).catch(() => {
            toastr.error('Unable to change complimentary code status');
            this.componentDidMount();
            this.hideToggleStatusModal();
        });
    }

    viewUrl = (code) => {
        this.setState(() => ({ selectedUrl: this.props.complimentarySignUpUrl + code }));
    }

    showToggleStatusModal = (code) => {
        this.setState(() => ({ toggleStatusModalState: true }));
        this.setState(() => ({ selectedComplimentaryCode: code }));
    }

    hideToggleStatusModal = () => {
        this.setState(() => ({ toggleStatusModalState: false }));
        this.setState(() => ({ selectedComplimentaryCode: null }));
    }

    onCopy = () => {
        toastr.success('Complimentary code URL copied to clipboard successfully');
    }

    render() {
        const { results, toggleStatusModalState, selectedUrl } = this.state;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="container">
                        <div>&nbsp;</div>
                        <div className="header header-color center-form uppercase-text">
                            <div>Complimentary Codes</div>
                        </div>
                        <div className="sub-header header-color">
                            &nbsp;
                        </div>
                        <div className="col-lg-12 text-center">
                            <button className="btn btn-primary" onClick={() => {
                                history.push('/create-complimentary-code');
                            }}>New Complimentary Code</button>
                        </div>
                        <div>
                            &nbsp;
                        </div>
                        <div>
                            <div className="complimentary-codes-table">
                                <ReactTable
                                    className="-striped -highlight"
                                    data={results}
                                    columns={this.columns}
                                    defaultPageSize={10}
                                    noDataText="No complimentary codes found"
                                    defaultFilterMethod={
                                        (filter, row) => {
                                            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">&nbsp;</div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="input-group">
                                    <input readOnly type="text" className="form-control" id="codeUrl" value={selectedUrl} name="codeUrl" />
                                    <span className="input-group-btn">
                                        <CopyToClipboard onCopy={this.onCopy} text={selectedUrl}>
                                            <button className="btn btn-primary" type="button">Copy</button>
                                        </CopyToClipboard>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <ConfirmModal
                            showYesButton={true}
                            showNoButton={true}
                            bsSize={'sm'}
                            show={toggleStatusModalState}
                            onHide={this.hideToggleStatusModal}
                            title={'YipTV Admin Portal'}
                            body={'Are you sure you want to toggle the status?'}
                            yesButtonClick={this.toggleComplimentaryCodeStatus}
                            noButtonClick={this.hideToggleStatusModal}
                        />
                        <WindowSizeListener onResize={window => {
                            if (window.windowWidth <= 767) {
                                this.hideToggleStatusModal();
                            }
                        }} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    complimentarySignUpUrl: state.config.complimentarySignUpUrl,
});

export default connect(mapStateToProps)(ComplimentaryCodes);
