import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import ChangeCreditCardForm from '../forms/change-credit-card-form.component';
import * as api from '../services/api';
import toastr from '../services/toastr';
import creditCardLogos from '../images/credit-cards.png';
import goDaddySeal from '../images/go-daddy-seal.gif';

class ChangeCreditCard extends React.Component {

    changeCreditCard = (data) => {
        window.Stripe.setPublishableKey(this.props.stripePublishableKey);
        window.Stripe.card.createToken({ name: data.cardName, number: data.cardNumber, cvc: data.cvv, exp_month: data.expiryDate.substring(0, 2), exp_year: data.expiryDate.substring(5), address_line1: data.address, address_city: data.city, address_state: data.state, address_zip: data.zipCode, address_country: 'US' }, (status, response) => {
            if (status !== 200) {
                toastr.error('Unable to add/change credit card due to a problem with the card. Please check the card details provided by the customer.');
            } else {
                const values = { ...data, email: this.props.customer };
                values.token = response.id;
                values.last4 = response.card.last4;
                values.brand = response.card.brand;
                values.funding = response.card.funding;
                values.country = 'US';
                values.cardNumber = undefined;
                values.cvv = undefined;
                values.expiryDate = undefined;
                api.changeCreditCard(values).then((data) => {
                    toastr.success('Customer\'s credit card has been added/changed successfully');
                    this.props.afterAction();
                }).catch((err) => {
                    toastr.error('Error adding/changing customer\'s credit card');
                    this.props.afterAction();
                });
            }
        });
    }

    goDaddySealClicked = () => {
        window.open('https://seal.godaddy.com/verifySeal?sealID=xMtT6BHQGZlXGWOQ3gqbjslvJy4XngLJqKU7BeqHlM1Y56nxL3TbUii3Weaw', 'goDaddy', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=608,height=508');
    }

    render() {
        const { show, onHide, customerDetails } = this.props;
        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton bsClass="customer-modal-header">
                </Modal.Header>
                <Modal.Body bsClass="customer-modal-body">
                    {customerDetails.cards && customerDetails.cards.length > 0 &&
                        <div className="full-form current-card">
                            <span className="message bold-text current-card-span">Current Card</span>
                            <br />
                            <span className="label-text">{customerDetails.cards[0].brand.toUpperCase()} **** **** **** {customerDetails.cards[0].last4}</span>
                        </div>
                    }
                    <div className="full-form">
                        <span className="message">Enter customer's new credit card details</span>
                    </div>
                    <div className="full-form">
                        <div className="cards-accepted-text">We accept the following credit cards</div>
                        <div><img className="img-responsive" src={creditCardLogos} width="300px" alt="" /></div>
                    </div>
                    <div>&nbsp;</div>
                    <ChangeCreditCardForm onSubmit={this.changeCreditCard} />
                    <div>&nbsp;</div>
                    <div className="full-form center-form">
                        <img className="clickable" onClick={this.goDaddySealClicked} alt="" src={goDaddySeal} />
                    </div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </Modal.Body>
                <Modal.Footer bsClass="customer-modal-footer">
                    &nbsp;
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    stripePublishableKey: state.config.stripePublishableKey
});

export default connect(mapStateToProps)(ChangeCreditCard);
