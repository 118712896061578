import React from 'react';

import CreateChannelTagValueForm from '../forms/create-channel-tag-value-form.component';
import toastr from '../services/toastr';
import history from '../services/history';
import * as api from '../services/api';

class CreateChannelTagValue extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            results: { code: '', name: '', values: [] },
            channelTagCode: null
        };
    }

    componentDidMount() {
        this.setState(() => ({ channelTagCode: this.props.match.params.channelTagCode }));
        api.getChannelTag(this.props.match.params.channelTagCode).then((data) => {
            this.setState(() => ({ results: data }));
        }).catch(() => {
            toastr.error('Error fetching channel tag');
        });
    }

    back = () => {
        history.push('/channel-tag-values/' + this.state.channelTagCode);
    }

    createChannelTagValue = (tag) => {
        const values = { code: tag.code, name: tag.name, channelTagCode: this.state.results.code };
        api.createChannelTagValue(values).then((data) => {
            toastr.success('New channel tag value created successfully');
            history.push('/channel-tag-values/' + this.state.channelTagCode);
        }).catch((err) => {
            if (err.message === 'ChannelTagValueExists') {
                toastr.error('Channel tag value code already exists');
            } else {
                toastr.error('Unable to create channel tag value');
            }
        });
    }

    render() {
        const { results } = this.state;
        const parent = { parentCode: results.code, parentName: results.name };
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="form-box">
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="header header-color center-form uppercase-text">
                            <div>New Channel Tag Value</div>
                        </div>
                        <div>&nbsp;</div>
                        <CreateChannelTagValueForm parent={parent} onSubmit={this.createChannelTagValue} />
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateChannelTagValue;
