import * as actionTypes from '../constants/action-types';

const initialAppState = [];

const channelLogosReducer = (state = initialAppState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_CHANNEL_LOGOS_SUCCESS:
            return action.data;
        default:
            return state;
    }
};

export default channelLogosReducer;
