import React from 'react';
import { connect } from 'react-redux';
import WindowSizeListener from 'react-window-size-listener';

import MenuButton from './menu-button.component';
import ConfirmModal from './confirm-modal.component';
import security from '../services/security';
import { setMenuState } from '../actions/app-state.action';
import { signOut } from '../actions/user.action';

class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            signOutModalState: false
        };
    }

    closeClick = () => {
        this.props.setMenuState(!this.props.menuState);
    }

    signOut = () => {
        this.props.setMenuState(!this.props.menuState);
        this.props.signOut().then(() => {
            window.location.reload();
        }).catch(() => {
            // do nothing
        });;
    }

    showSignOutModal = () => {
        this.setState(() => ({ signOutModalState: true }));
    }

    hideSignOutModal = () => {
        this.setState(() => ({ signOutModalState: false }));
    }

    render() {
        const { showHideClass } = this.props;
        const { signOutModalState } = this.state;

        return (
            <div>
                <div id="menuHolder" className={'left-slider-pane ' + showHideClass}>
                    <div className="row menu-first-row">
                        <div>
                            <button id="menuClose" onClick={this.closeClick} className="btn btn-danger btn-xs">Close</button>
                            <button id="menuSignOut" onClick={this.showSignOutModal} className="btn btn-danger btn-xs">Sign Out</button>
                        </div>
                    </div>
                    <MenuButton name="Home" link="/user-home" roles={security['user-home']} />
                    <MenuButton name="Customers" link="/customers" roles={security['customers']} />
                    <MenuButton name="Reports" link="/reports" roles={security['reports']} />
                    <MenuButton name="Customer Export" link="/customer-export" roles={security['customer-export']} />
                    <MenuButton name="Content Management" link="/content-management" roles={security['content-management']} />
                    <MenuButton name="Merchants" link="/merchants" roles={security['merchants']} />
                    <MenuButton name="Application Errors" link="/application-errors" roles={security['application-errors']} />
                    <MenuButton name="Complimentary Codes" link="/complimentary-codes" roles={security['complimentary-codes']} />
                    <MenuButton name="Admin Users" link="/admin-users" roles={security['admin-users']} />
                    <MenuButton name="Change My Password" link="/change-my-password" roles={security['change-my-password']} />
                </div>
                <ConfirmModal
                    showYesButton={true}
                    showNoButton={true}
                    bsSize={'sm'}
                    show={signOutModalState}
                    onHide={this.hideSignOutModal}
                    title={'YipTV Admin Portal'}
                    body={'Are you sure you want to sign out of YipTV Admin Portal?'}
                    yesButtonClick={this.signOut}
                    noButtonClick={this.hideSignOutModal}
                />
                <WindowSizeListener onResize={window => {
                    if (window.windowWidth <= 767) {
                        this.hideSignOutModal();
                    }
                }} />
            </div>
        );
    }
}

const getShowHideClass = (menuState) => {
    return menuState ? 'show-menu' : 'hide-menu';
};

const mapStateToProps = (state) => ({
    menuState: state.appState.menuState,
    showHideClass: getShowHideClass(state.appState.menuState),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    setMenuState: (menuState) => dispatch(setMenuState(menuState)),
    signOut: () => dispatch(signOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
