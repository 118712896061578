const getQueryString = (obj) => {
    return Object.keys(obj).reduce((a, k) => {
        a.push(k + '=' + encodeURIComponent(obj[k]));
        return a;
    }, []).join('&');
};

const arrayToQueryString = (arrayItem, key) => {
    let queryString = '&';
    for (let i = 0; i < arrayItem.length; i++) {
        queryString += key + '=' + arrayItem[i] + '&';
    }
    return queryString;
};

const getHeaders = () => {
    let headers = {
        'Content-Type': 'application/json',
        'If-Modified-Since': '0'
    };
    if (sessionStorage.getItem('token')) {
        headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
    }
    return headers;
};

const getFileUploadHeaders = () => {
    let headers = {
        'If-Modified-Since': '0'
    };
    if (sessionStorage.getItem('token')) {
        headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
    }
    return headers;
};

export const getAppConfig = async () => {
    const response = await fetch('/admin/api/get-app-config', getHeaders());
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const signIn = async (values) => {
    const response = await fetch('/admin/api/sign-in',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const signOut = async () => {
    const response = await fetch('/admin/api/sign-out',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({})
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const getUserProfile = async () => {
    const response = await fetch('/admin/api/get-user-profile',
        {
            method: 'GET',
            headers: getHeaders(),
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getUserHomeStatistics = async () => {
    const response = await fetch('/admin/api/get-user-home-statistics',
        {
            method: 'GET',
            headers: getHeaders(),
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getUserSignUpReport = async (values) => {
    const response = await fetch(`/admin/api/get-user-sign-up-report?affiliate=${values.affiliate}&complimentary=${values.complimentary}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getCardSalesReport = async (values) => {
    const response = await fetch(`/admin/api/get-card-sales-report?startDateUtc=${values.startDateUtc}&endDateUtc=${values.endDateUtc}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getInAppSalesReport = async (values) => {
    const response = await fetch(`/admin/api/get-in-app-sales-report?startDateUtc=${values.startDateUtc}&endDateUtc=${values.endDateUtc}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getMerchantSalesReport = async (values) => {
    const response = await fetch(`/admin/api/get-merchant-sales-report?merchant=${values.merchant}&startDateUtc=${values.startDateUtc}&endDateUtc=${values.endDateUtc}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getAllMerchants = async () => {
    const response = await fetch('/admin/api/get-all-merchants',
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getUtmSalesReport = async (values) => {
    let query = '';
    if (values.client && values.client !== '') {
        query += 'client=' + values.client + '&';
    }
    if (values.source && values.source !== '') {
        query += 'source=' + values.source + '&';
    }
    if (values.campaign && values.campaign !== '') {
        query += 'campaign=' + values.campaign + '&';
    }
    if (values.medium && values.medium !== '') {
        query += 'medium=' + values.medium + '&';
    }
    if (values.term && values.term !== '') {
        query += 'term=' + values.term + '&';
    }
    if (values.content && values.content !== '') {
        query += 'content=' + values.content + '&';
    }
    const response = await fetch(`/admin/api/get-utm-sales-report?${query}startDateUtc=${values.startDateUtc}&endDateUtc=${values.endDateUtc}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getAllUtmValues = async () => {
    const response = await fetch('/admin/api/get-all-utm-values',
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getUnverifiedPremiumUsers = async () => {
    const response = await fetch('/admin/api/get-unverified-premium-users',
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getUnpaidPremiumUsers = async () => {
    const response = await fetch('/admin/api/get-unpaid-premium-users',
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const verifyUser = async (email) => {
    const response = await fetch('/admin/api/verify-user',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ email: email })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const endPremiumSubscription = async (email) => {
    const response = await fetch('/admin/api/end-premium-subscription',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ email: email })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const changeMyPassword = async (values) => {
    const response = await fetch('/admin/api/change-my-password',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getAllAdminUsers = async () => {
    const response = await fetch('/admin/api/get-all-admin-users',
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getAdminUser = async (email) => {
    const response = await fetch(`/admin/api/get-admin-user?adminEmail=${email}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const createAdminUser = async (values) => {
    const response = await fetch('/admin/api/create-admin-user',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const updateAdminUser = async (values) => {
    const response = await fetch('/admin/api/update-admin-user',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const isEmailAvailable = async (email) => {
    const response = await fetch(`/admin/api/is-email-available?adminEmail=${email.toLowerCase().trim()}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const sendResetPasswordEmail = async (email) => {
    const response = await fetch('/admin/api/send-admin-user-reset-password-email',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ email: email })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const checkPasswordToken = async (token) => {
    const response = await fetch(`/admin/api/check-password-token?token=${token}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const setPassword = async (user) => {
    const response = await fetch('/admin/api/set-password',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(user)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const resetPassword = async (user) => {
    const response = await fetch('/admin/api/reset-password',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(user)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const getAllMercants = async () => {
    const response = await fetch('/admin/api/get-all-merchants',
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const toggleMerchantStatus = async (merchantCode) => {
    const response = await fetch('/admin/api/toggle-merchant-status',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ merchantCode: merchantCode })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const createMerchant = async (values) => {
    const response = await fetch('/admin/api/create-merchant',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const getAllComplimentaryCodes = async () => {
    const response = await fetch('/admin/api/get-all-complimentary-codes',
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const toggleComplimentaryCodeStatus = async (complimentaryCode) => {
    const response = await fetch('/admin/api/toggle-complimentary-code-status',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ complimentaryCode: complimentaryCode })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const createComplimentaryCode = async (values) => {
    const response = await fetch('/admin/api/create-complimentary-code',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const searchErrors = async (searchText) => {
    const response = await fetch(`/admin/api/search-errors?searchText=${searchText}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const emailUserList = async (values) => {
    const queryString = getQueryString(values);
    const response = await fetch(`/admin/api/email-user-list?${queryString}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const searchUsers = async (searchText) => {
    const response = await fetch(`/admin/api/search-users?searchText=${searchText}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getUserDetails = async (email) => {
    const response = await fetch(`/admin/api/get-user-details?email=${email}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const clearUserSessions = async (email) => {
    const response = await fetch('/admin/api/clear-user-sessions',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ email: email })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const cancelSubscription = async (email) => {
    const response = await fetch('/admin/api/cancel-subscription',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ email: email })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const revertCancelSubscription = async (email) => {
    const response = await fetch('/admin/api/revert-cancel-subscription',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ email: email })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const endAndRefundPremiumSubscription = async (email) => {
    const response = await fetch('/admin/api/end-and-refund-premium-subscription',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ email: email })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const endComplimentarySubscription = async (email) => {
    const response = await fetch('/admin/api/end-complimentary-subscription',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ email: email })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const suspendUser = async (email) => {
    const response = await fetch('/admin/api/suspend-user',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ email: email })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const unsuspendUser = async (email) => {
    const response = await fetch('/admin/api/unsuspend-user',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ email: email })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const addCredit = async (values) => {
    const response = await fetch('/admin/api/add-credit',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const addCharge = async (values) => {
    const response = await fetch('/admin/api/add-charge',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const emailInvoice = async (values) => {
    const queryString = getQueryString(values);
    const response = await fetch(`/admin/api/email-invoice?${queryString}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const addComplimentaryDays = async (values) => {
    const response = await fetch('/admin/api/add-complimentary-days',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const updateUserInfo = async (values) => {
    const response = await fetch('/admin/api/update-user-info',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const updatePreferences = async (values) => {
    const response = await fetch('/admin/api/update-preferences',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const changePassword = async (values) => {
    const response = await fetch('/admin/api/change-password',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const convertToComplimentary = async (values) => {
    const response = await fetch('/admin/api/convert-to-complimentary',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const updateSupportInfo = async (values) => {
    const response = await fetch('/admin/api/update-support-info',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const changeCreditCard = async (values) => {
    const response = await fetch('/admin/api/change-credit-card',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const getStates = async () => {
    const response = await fetch('/admin/api/get-states',
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const upgradeSubscription = async (values) => {
    const response = await fetch('/admin/api/upgrade-subscription',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const getMerchantPlans = async (merchant, types, isNewUser) => {
    var typesString = arrayToQueryString(types, 'types');
    const response = await fetch(`/admin/api/get-merchant-plans?merchant=${merchant}${typesString}isNewUser=${isNewUser}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getAllChannels = async () => {
    const response = await fetch('/admin/api/get-all-channels',
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const getAllChannelLogos = async () => {
    const response = await fetch('/admin/api/get-all-channel-logos',
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const uploadChannelLogo = async (body) => {
    const response = await fetch('/admin/api/upload-channel-logo',
        {
            method: 'POST',
            headers: getFileUploadHeaders(),
            body: body
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const getAllChannelTags = async () => {
    const response = await fetch('/admin/api/get-all-channel-tags',
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const createChannelTag = async (values) => {
    const response = await fetch('/admin/api/create-channel-tag',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const toggleChannelTagStatus = async (code) => {
    const response = await fetch('/admin/api/toggle-channel-tag-status',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ code: code })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const toggleChannelTagValueStatus = async (channelTagCode, channelTagValueCode) => {
    const response = await fetch('/admin/api/toggle-channel-tag-value-status',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ channelTagCode: channelTagCode, channelTagValueCode: channelTagValueCode })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const getChannelTag = async (code) => {
    const response = await fetch(`/admin/api/get-channel-tag?code=${code}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const createChannelTagValue = async (values) => {
    const response = await fetch('/admin/api/create-channel-tag-value',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const toggleChannelStatus = async (code) => {
    const response = await fetch('/admin/api/toggle-channel-status',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ code: code })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const createChannel = async (values) => {
    const response = await fetch('/admin/api/create-channel',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const getChannel = async (code) => {
    const response = await fetch(`/admin/api/get-channel?code=${code}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const updateChannel = async (values) => {
    const response = await fetch('/admin/api/update-channel',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(values)
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const getAllVods = async (filter) => {
    const response = await fetch(`/admin/api/get-all-vods?filter=${filter}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const deleteChannelLogo = async (fileName) => {
    const response = await fetch('/admin/api/delete-channel-logo',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ fileName: fileName })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};

export const getAllChannelPrograms = async (code) => {
    const response = await fetch(`/admin/api/get-all-channel-programs?code=${code}`,
        {
            method: 'GET',
            headers: getHeaders()
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const refreshChannelPrograms = async (code) => {
    const response = await fetch('/admin/api/refresh-channel-programs',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ code: code })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.json();
    return data;
};

export const toggleVodStatus = async (mediaId) => {
    const response = await fetch('/admin/api/toggle-vod-status',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ mediaId: mediaId })
        }
    );
    if (!response.ok) {
        throw Error(await response.text());
    }
    const data = await response.text();
    return data;
};