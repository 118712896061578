import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import Header from '../widgets/header.component';
import Footer from '../widgets/footer.component';

const PublicRoute = ({ isAuthenticated, component: Component, ...rest }) => (
    <Route {...rest}
        component={(props) => (
            isAuthenticated ?
                (<Redirect to="/user-home" />)
                :
                (
                    <div className="wrapper">
                        <div className="wrapper-body">
                            <Header />
                            <Component {...props} />
                        </div>
                        <Footer />
                    </div>
                )
        )}
    />
);

const mapStateToProps = (state) => ({
    isAuthenticated: !!(state.user && state.user.email)
});

export default connect(mapStateToProps)(PublicRoute);
