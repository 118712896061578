import React from 'react';
import ReactTable from 'react-table';
import toastr from 'toastr';
import WindowSizeListener from 'react-window-size-listener';

import * as api from '../services/api';
import history from '../services/history';
import ConfirmModal from '../widgets/confirm-modal.component';

class ChannelTags extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            results: [],
            toggleStatusModalState: false,
            selectedChannelTag: null
        };
    }

    componentDidMount() {
        api.getAllChannelTags().then((data) => {
            this.setState(() => ({ results: data }));
        }).catch(() => {
            toastr.error('Error fetching channel tags');
        });
    }

    columns = [{
        Header: 'Values',
        id: 'edit',
        accessor: d => d.code,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 70,
        sortable: false,
        filterable: false,
        Cell: row => <button className="btn btn-xs btn-primary" onClick={() => {
            history.push('/channel-tag-values/' + row.value);
        }}>Values</button>
    }, {
        Header: 'Toggle Status',
        id: 'toogle',
        accessor: d => d.code,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 120,
        sortable: false,
        filterable: false,
        Cell: row => <button className="btn btn-xs btn-primary" onClick={() => {
            this.showToggleStatusModal(row.value);
        }}>Toggle Status</button>
    }, {
        Header: 'Status',
        id: 'disabled',
        accessor: d => d.disabled ? 'Disabled' : 'Active',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 125,
        sortable: true,
        filterable: true,
        filterMethod: (filter, row) => {
            if (filter.value === "All") {
                return true;
            }
            return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) =>
            <select
                onChange={event => onChange(event.target.value)}
                className="grid-filter-dropdown"
                value={filter ? filter.value : "All"}
            >
                <option value="All"></option>
                <option value="Active">Active</option>
                <option value="Disabled">Disabled</option>
            </select>
    }, {
        Header: 'Code',
        id: 'code',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 100,
        sortable: true,
        filterable: true,
        accessor: d => d.code
    }, {
        Header: 'Name',
        id: 'name',
        accessor: d => d.name,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 300,
        filterable: true,
        sortable: true
    }];

    capitalize(value) {
        if (value && value.length > 1) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        if (value && value.length === 1) {
            return value.charAt(0).toUpperCase();
        }
        return value;
    }

    back = () => {
        history.push('/content-management');
    }

    toggleChannelTagStatus = () => {
        api.toggleChannelTagStatus(this.state.selectedChannelTag).then((data) => {
            toastr.success('Channel tag status changed successfully');
            this.componentDidMount();
            this.hideToggleStatusModal();
        }).catch(() => {
            toastr.error('Unable to change channel tag status');
            this.componentDidMount();
            this.hideToggleStatusModal();
        });
    }

    showToggleStatusModal = (code) => {
        this.setState(() => ({ toggleStatusModalState: true }));
        this.setState(() => ({ selectedChannelTag: code }));
    }

    hideToggleStatusModal = () => {
        this.setState(() => ({ toggleStatusModalState: false }));
        this.setState(() => ({ selectedChannelTag: null }));
    }

    render() {
        const { results, toggleStatusModalState } = this.state;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="container">
                        <div>&nbsp;</div>
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="header header-color center-form uppercase-text">
                            <div>Channel Tags</div>
                        </div>
                        <div className="sub-header header-color">
                            &nbsp;
                        </div>
                        <div className="col-lg-12 text-center">
                            <button className="btn btn-primary" onClick={() => {
                                history.push('/create-channel-tag');
                            }}>New Channel Tag</button>
                        </div>
                        <div>
                            &nbsp;
                        </div>
                        <div>
                            <div className="channel-tags-table">
                                <ReactTable
                                    className="-striped -highlight"
                                    data={results}
                                    columns={this.columns}
                                    defaultPageSize={10}
                                    noDataText="No channel tags found"
                                    defaultFilterMethod={
                                        (filter, row) => {
                                            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <ConfirmModal
                            showYesButton={true}
                            showNoButton={true}
                            bsSize={'sm'}
                            show={toggleStatusModalState}
                            onHide={this.hideToggleStatusModal}
                            title={'YipTV Admin Portal'}
                            body={'Are you sure you want to toggle the status?'}
                            yesButtonClick={this.toggleChannelTagStatus}
                            noButtonClick={this.hideToggleStatusModal}
                        />
                        <WindowSizeListener onResize={window => {
                            if (window.windowWidth <= 767) {
                                this.hideToggleStatusModal();
                            }
                        }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default ChannelTags;
