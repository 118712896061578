import React from 'react';

const Home = () => (
    <div className="content-area">
        <div className="content-box">
            <div>&nbsp;</div>
            <div className="col-md-12 text-center uppercase-text bolder-text">
                <h2 className="header-color">
                    YipTV Admin Portal
                </h2>
            </div>
        </div>
    </div>
);

export default Home;
