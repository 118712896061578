import React from 'react';
import { Modal } from 'react-bootstrap';

import ChangePasswordForm from '../forms/change-password-form.component';
import * as api from '../services/api';
import toastr from '../services/toastr';

class ChangePassword extends React.Component {

    changePassword = (data) => {
        const values = {...data, email: this.props.customer};
        api.changePassword(values).then((data) => {
            toastr.success('Customer\'s password updated successfully');
            this.props.afterAction();
        }).catch((err) => {
            toastr.error('Error updating customer\'s password');
            this.props.afterAction();
        });
    }

    render() {
        const { show, onHide } = this.props;
        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton bsClass="customer-modal-header">
                </Modal.Header>
                <Modal.Body bsClass="customer-modal-body">
                    <ChangePasswordForm onSubmit={this.changePassword} />
                </Modal.Body>
                <Modal.Footer bsClass="customer-modal-footer">
                    &nbsp;
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ChangePassword;
