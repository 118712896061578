import React from 'react';
import { connect } from 'react-redux';

import SignInForm from '../forms/sign-in-form.component';
import { signIn } from '../actions/user.action';
import toastr from '../services/toastr';

class SignIn extends React.Component {

    signIn = (user) => {
        this.props.signIn(user).then(() => {
            // do nothing
        }).catch((err) => {
            if (err.message === 'DisabledAccount') {
                toastr.error('Your account has been disabled. Please contact the administrator.');
            } else {
                toastr.error('Sign In failed');
            }
        });
    }

    render() {
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="form-box">
                        <div className="header header-color center-form uppercase-text">
                            <div>Sign In</div>
                        </div>
                        <SignInForm onSubmit={this.signIn} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    signIn: (user) => dispatch(signIn(user))
});

export default connect(undefined, mapDispatchToProps)(SignIn);
