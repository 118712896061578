import React from 'react';
import { Field, reduxForm } from 'redux-form';
import WindowSizeListener from 'react-window-size-listener';

import * as validations from '../services/validations';
import ConfirmModal from '../widgets/confirm-modal.component';
import toastr from '../services/toastr';
import history from '../services/history';
import * as api from '../services/api';

const roles = [
    { value: 'administrator', text: 'Administrator' },
    { value: 'finance', text: 'Finance' },
    { value: 'management', text: 'Management' },
    { value: 'marketing', text: 'Marketing' },
    { value: 'operations', text: 'Operations' },
    { value: 'support', text: 'Support' },
];

const validate = (values) => {
    const errors = {};
    if (validations.isEmpty(values.email)) {
        errors.email = 'Enter email address';
    } else if (!validations.isEmail(values.email)) {
        errors.email = 'Enter a valid email address';
    }
    if (validations.isEmpty(values.firstName)) {
        errors.firstName = 'Enter first name';
    } else if (!validations.isName(values.firstName)) {
        errors.firstName = 'Enter a valid first name';
    }
    if (validations.isEmpty(values.lastName)) {
        errors.lastName = 'Enter last name';
    } else if (!validations.isName(values.lastName)) {
        errors.lastName = 'Enter a valid last name';
    }
    if (validations.isEmpty(values.roleName)) {
        errors.roleName = 'Select a role';
    }
    return errors;
};

const renderField = ({ input, type, maxLength, readOnly, meta: { touched, error } }) => {
    return (
        <div>
            <input {...input} autoComplete="off" readOnly={readOnly} maxLength={maxLength} type={type} className={"text-box " + (touched && error ? "text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const renderDropDown = ({ input, options, defaultOptionText, meta: { touched, error } }) => {
    return (
        <div>
            <select {...input} className={"text-box drop-down-list " + (touched && error ? "text-box-error" : "")}>
                <option value="">{defaultOptionText}</option>
                {
                    options.map((opt, i) => {
                        return <option value={opt.value} key={i}>{opt.text}</option>;
                    })
                }
            </select>
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

class EditAdminUserForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            resetPasswordModalState: false
        };
    }

    showResetPasswordModal = () => {
        this.setState(() => ({ resetPasswordModalState: true }));
    }

    hideResetPasswordModal = () => {
        this.setState(() => ({ resetPasswordModalState: false }));
    };

    sendResetPasswordEmail = () => {
        api.sendResetPasswordEmail(this.props.email).then((data) => {
            toastr.success('The reset password email has been sent to the user');
            history.push('/admin-users');
        }).catch(() => {
            toastr.error('Unable to send reset password email to the user');
        });
    };

    render() {
        const { handleSubmit, submitting, editLoginUser } = this.props;
        const { resetPasswordModalState } = this.state;
        return (
            <div>
                <form onSubmit={handleSubmit} autoComplete="off" >
                    <div className="full-form">
                        <span className="label-text">Email</span>
                        <br />
                        <Field name="email" maxLength="50" readOnly={true} type="text" component={renderField} />
                    </div>
                    <div className="form-container">
                        <div className="half-form">
                            <span className="label-text">First Name</span><br />
                            <Field name="firstName" maxLength="20" type="text" component={renderField} />
                        </div>
                        <div className="half-form">
                            <span className="label-text">Last Name</span><br />
                            <Field name="lastName" maxLength="20" type="text" component={renderField} />
                        </div>
                    </div>
                    <div className="full-form">
                        <span className="label-text">Role</span><br />
                        <Field name="roleName" options={roles} defaultOptionText="Select role" component={renderDropDown} />
                    </div>
                    <div className="full-form">
                        <Field type="checkbox" component="input" id="disabled" name="disabled" />
                        <label className="purple" htmlFor="disabled">
                            <span>Disabled</span>
                        </label>
                    </div>
                    <div className="full-form">
                        <button type="submit" disabled={submitting} className="submit-button">Submit</button>
                    </div>
                </form >
                {editLoginUser &&
                    <div>
                        <div className="full-form text-center">
                            <button onClick={() => this.showResetPasswordModal()} className="btn btn-lg btn-link">Send Reset Password Email</button>
                        </div>
                        <ConfirmModal
                            showYesButton={true}
                            showNoButton={true}
                            bsSize={'sm'}
                            show={resetPasswordModalState}
                            onHide={this.hideResetPasswordModal}
                            title={'YipTV Admin Portal'}
                            body={'Are you sure you want to send reset password email to this user?'}
                            yesButtonClick={this.sendResetPasswordEmail}
                            noButtonClick={this.hideResetPasswordModal}
                        />
                        <WindowSizeListener onResize={window => {
                            if (window.windowWidth <= 767) {
                                this.hideResetPasswordModal();
                            }
                        }} />
                    </div>
                }
            </div>
        );
    }
};

export default reduxForm({ form: 'editAdminUser', validate, enableReinitialize: true })(EditAdminUserForm);
