import React from 'react';
import moment from 'moment';

const Footer = () => (
    <div className="legal-footer">
        <div className="legal-container">
            <div className="container">
                <p className="legal-paragraph">Copyright &copy; {moment().year()}</p>
            </div>
        </div>
    </div>
);

export default Footer;
