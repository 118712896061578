import moment from 'moment';

export const isEmpty = (value) => {
    if (!value) {
        return true;
    }
    if (value.trim() === '') {
        return true;
    }
    return false;
};

export const isStrongPassword = (value) => {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumbers = /\d/.test(value);
    const characterGroupCount = (hasUpperCase ? 1 : 0) + (hasNumbers ? 1 : 0);
    return ((value.length >= 6) && (characterGroupCount >= 2));
};

export const isEmail = (value) => {
    const regex = /^(([^+<>()[\]\\.,;:\s@"]+(\.[^+<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(value);
};

export const isName = (value) => {
    const regex = /^[a-zA-Z0-9\s\-,.']+$/;
    return regex.test(value);
};

export const isChannelName = (value) => {
    const regex = /^[a-zA-ZÀ-ÿ0-9\s\-&,.']+$/;
    return regex.test(value);
};

export const isAddress = (value) => {
    // eslint-disable-next-line
    const regex = /^[a-zA-Z0-9\s\-!@#$%&\(\)\+;:'",.\?/=\[\]<>]+$/;
    return regex.test(value);
};

export const isZipCode = (value) => {
    const regex = /^\d{5}$/;
    return regex.test(value);
};

export const isMerchantCode = (value) => {
    const regex = /^[A-Z]+$/;
    return regex.test(value);
};

export const isChannelCode = (value) => {
    const regex = /^[A-Z]{4}$/;
    return regex.test(value);
};

export const isInteger = (value) => {
    const regex = /^[0-9]+$/;
    return regex.test(value);
};

export const isDate = (value) => {
    if (!value) {
        return false;
    }
    const date = moment(value, 'MM/DD/YYYY');
    return date.isValid();
};

export const isAmount = (value) => {
    const regex = /^[0-9]*\.[0-9]{2}$/;
    return regex.test(value);
};

export const isDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) {
        return false;
    }
    const start = moment.utc(startDate, 'MM/DD/YYYY').startOf('day');
    const end = moment.utc(endDate, 'MM/DD/YYYY').endOf('day');
    return end.isSameOrAfter(start);
};

export const isUrl = (url) => {
    if (url.includes('?') || url.includes('=') || url.includes('&')) {
        return false;
    }
    return url.startsWith('http://') || url.startsWith('https://');
};

export const isCreditCard = (cardNumber) => {
    cardNumber = cardNumber.replace(/\D/g, '');
    if (/[^0-9]+/.test(cardNumber)) {
        return false;
    }
    if (parseInt(cardNumber, 10) === 0) {
        return false;
    }
    if ((cardNumber.indexOf('34') === 0 || cardNumber.indexOf('37') === 0) && cardNumber.length !== 15) {
        return false;
    }
    if ((cardNumber.indexOf('4') === 0 || cardNumber.indexOf('51') === 0 || cardNumber.indexOf('52') === 0 || cardNumber.indexOf('53') === 0 || cardNumber.indexOf('54') === 0 || cardNumber.indexOf('55') === 0) && cardNumber.length !== 16) {
        return false;
    }
    if ((cardNumber.indexOf('35') === 0 || cardNumber.indexOf('6011') === 0 || cardNumber.indexOf('622') === 0 || cardNumber.indexOf('64') === 0 || cardNumber.indexOf('65') === 0) && cardNumber.length !== 16) {
        return false;
    }
    if ((cardNumber.indexOf('30') === 0 || cardNumber.indexOf('36') === 0 || cardNumber.indexOf('38') === 0 || cardNumber.indexOf('39') === 0) && (cardNumber.length !== 16 && cardNumber.length !== 14)) {
        return false;
    }
    let check = 0, number = 0, even = false;
    for (var n = cardNumber.length - 1; n >= 0; n--) {
        var digit = cardNumber.charAt(n);
        number = parseInt(digit, 10);
        if (even) {
            if ((number *= 2) > 9) {
                number -= 9;
            }
        }
        check += number;
        even = !even;
    }
    return (check % 10) === 0;
};

export const isExpiryDate = (dateString) => {
    dateString = dateString.replace(/\D/g, '');
    if (!/^\d{6}$/.test(dateString)) {
        return false;
    }
    let today = new Date();
    let month = parseInt(dateString.substring(0, 2), 10);
    let year = parseInt(dateString.substring(2), 10);
    if (month < 1 || month > 12 || year < today.getFullYear() || year > 2099) {
        return false;
    }
    if (year === today.getFullYear()) {
        return month >= today.getMonth() + 1;
    }
    return true;
};

export const isCvv = (cvv, cardNumber) => {
    if (!cardNumber) {
        cardNumber = '';
    }
    if (cardNumber.indexOf('34') === 0 || cardNumber.indexOf('37') === 0) {
        return /^\d{4}$/.test(cvv);
    } else {
        return /^\d{3}$/.test(cvv);
    }
};
