import React from 'react';
import ReactTable from 'react-table';
import toastr from 'toastr';
import moment from 'moment';
import WindowSizeListener from 'react-window-size-listener';

import * as api from '../services/api';
import history from '../services/history';
import ConfirmModal from '../widgets/confirm-modal.component';

class Vods extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            results: [],
            filter: 'current',
            toggleStatusModalState: false,
            selectedVod: null
        };
    }

    componentDidMount() {
        this.getAllVods();
    }

    getAllVods = () => {
        api.getAllVods(this.state.filter).then((data) => {
            this.setState(() => ({ results: data }));
        }).catch(() => {
            toastr.error('Error fetching vods');
        });
    }

    onFilterChange = (e) => {
        const value = e.target.value;
        this.setState(() => ({ filter: value }), () => { this.getAllVods(); });
    }

    columns = [{
        Header: 'Toggle Status',
        id: 'toggle',
        accessor: d => d.mediaId,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 120,
        sortable: false,
        Cell: row => <button className="btn btn-xs btn-primary" onClick={() => {
            this.showToggleStatusModal(row.value);
        }}>Toggle Status</button>
    }, {
        Header: 'Status',
        id: 'disabled',
        accessor: d => d.disabled ? 'Disabled' : 'Active',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 110,
        sortable: true,
        filterable: true,
        filterMethod: (filter, row) => {
            if (filter.value === "All") {
                return true;
            }
            return row[filter.id] === this.capitalize(filter.value);
        },
        Filter: ({ filter, onChange }) =>
            <select
                onChange={event => onChange(event.target.value)}
                className="grid-filter-dropdown"
                value={filter ? filter.value : "all"}
            >
                <option value="All"></option>
                <option value="Active">Active</option>
                <option value="Disabled">Disabled</option>
            </select>

    }, {
        Header: 'Logo',
        id: 'logo',
        accessor: d => d.logo,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 75,
        sortable: false,
        filterable: false,
        Cell: row => (<div>
            <img width="100%" alt="" src={row.value} />
        </div>)
    }, {
        Header: 'Name',
        id: 'name',
        accessor: d => d.name,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 200,
        sortable: true,
        filterable: true
    }, {
        Header: 'Year',
        id: 'year',
        accessor: d => d.year,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 70,
        sortable: true,
        filterable: true
    }, {
        Header: 'Genre',
        id: 'genre',
        accessor: d => d.genre,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 100,
        filterable: true,
        sortable: true
    }, {
        Header: 'Rating',
        id: 'rating',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 75,
        sortable: true,
        filterable: true,
        accessor: d => d.rating
    }, {
        Header: 'Runtime',
        id: 'runtime',
        accessor: d => d.runtime,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 90,
        filterable: false,
        sortable: true
    }, {
        Header: 'Start Date',
        id: 'licenseStartDate',
        accessor: d => d.licenseStartDate,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 110,
        filterable: false,
        sortable: true,
        Cell: row => moment.utc(row.value).format('MMM D, YYYY'),
    }, {
        Header: 'End Date',
        id: 'licenseEndDate',
        accessor: d => d.licenseEndDate,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 110,
        filterable: false,
        sortable: true,
        Cell: row => moment.utc(row.value).format('MMM D, YYYY'),
    }, {
        Header: 'Media ID',
        id: 'mediaId',
        accessor: d => d.mediaId,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 350,
        filterable: true,
        sortable: true
    }, {
        Header: 'Description',
        id: 'description',
        accessor: d => d.description,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 2000,
        filterable: true,
        sortable: true
    }];

    capitalize(value) {
        if (value && value.length > 1) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        if (value && value.length === 1) {
            return value.charAt(0).toUpperCase();
        }
        return value;
    }

    back = () => {
        history.push('/content-management');
    }

    toggleVodStatus = () => {
        api.toggleVodStatus(this.state.selectedVod).then((data) => {
            toastr.success('VOD status changed successfully');
            this.getAllVods();
            this.hideToggleStatusModal();
        }).catch(() => {
            toastr.error('Unable to change VOD status');
            this.getAllVods();
            this.hideToggleStatusModal();
        });
    }

    showToggleStatusModal = (mediaId) => {
        this.setState(() => ({ toggleStatusModalState: true }));
        this.setState(() => ({ selectedVod: mediaId }));
    }

    hideToggleStatusModal = () => {
        this.setState(() => ({ toggleStatusModalState: false }));
        this.setState(() => ({ selectedVod: null }));
    }

    render() {
        const { results, filter, toggleStatusModalState } = this.state;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="container">
                        <div>&nbsp;</div>
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="header header-color center-form uppercase-text">
                            <div>VODs</div>
                        </div>
                        <div className="sub-header header-color">
                            &nbsp;
                        </div>
                        <div className="vod-filter">
                            <span className="label-text">Filter by:</span>
                            <br />
                            <select name="filter" value={filter} className="text-box drop-down-list text-box-full-width" onChange={this.onFilterChange}>
                                <option value="current">Current VODs</option>
                                <option value="expired">Expired VODs</option>
                                <option value="future">Future VODs</option>
                            </select>
                        </div>
                        <div className="vods-tabler">
                            &nbsp;
                        </div>
                        <div>
                            <div className="vods-table">
                                <ReactTable
                                    className="-striped -highlight"
                                    data={results}
                                    columns={this.columns}
                                    defaultPageSize={10}
                                    noDataText="No VODs found"
                                    defaultFilterMethod={
                                        (filter, row) => {
                                            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                        }
                                    }
                                    SubComponent={row => {
                                        return (
                                            <div>
                                                <div className="vod-actors-table">
                                                    <ReactTable
                                                        data={row.original.actors}
                                                        columns={[{
                                                            Header: 'Actors',
                                                            id: 'actors',
                                                            accessor: d => d,
                                                            headerClassName: 'grid-header',
                                                            className: 'grid-cell',
                                                            width: 200,
                                                            sortable: false,
                                                            filterable: false
                                                        }]}
                                                        defaultPageSize={row.original.actors.length}
                                                        showPagination={false}
                                                    />
                                                </div>
                                                <div className="vod-categories-table">
                                                    <ReactTable
                                                        data={row.original.categories}
                                                        columns={[{
                                                            Header: 'Categories',
                                                            id: 'categories',
                                                            accessor: d => d,
                                                            headerClassName: 'grid-header',
                                                            className: 'grid-cell',
                                                            width: 450,
                                                            sortable: false,
                                                            filterable: false
                                                        }]}
                                                        defaultPageSize={row.original.categories.length}
                                                        showPagination={false}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <ConfirmModal
                            showYesButton={true}
                            showNoButton={true}
                            bsSize={'sm'}
                            show={toggleStatusModalState}
                            onHide={this.hideToggleStatusModal}
                            title={'YipTV Admin Portal'}
                            body={'Are you sure you want to toggle the status?'}
                            yesButtonClick={this.toggleVodStatus}
                            noButtonClick={this.hideToggleStatusModal}
                        />
                        <WindowSizeListener onResize={window => {
                            if (window.windowWidth <= 767) {
                                this.hideToggleStatusModal();
                            }
                        }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Vods;
