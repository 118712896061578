import React from 'react';
import ReactTable from 'react-table';
import toastr from 'toastr';
import moment from 'moment';

import * as api from '../services/api';
import history from '../services/history';

class ChannelPrograms extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            results: [],
            channel: {},
            processing: false
        };
    }

    componentDidMount() {
        api.getChannel(this.props.match.params.channelCode).then((data) => {
            this.setState(() => ({ channel: data }));
        }).catch(() => {
            toastr.error('Error fetching channel details');
        });
        api.getAllChannelPrograms(this.props.match.params.channelCode).then((data) => {
            this.setState(() => ({ results: data }));
        }).catch(() => {
            toastr.error('Error fetching program guide');
        });
    }

    columns = [{
        Header: 'Start Time',
        id: 'startTime',
        accessor: d => d.startTime,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 190,
        sortable: true,
        filterable: false,
        Cell: row => moment.utc(row.value).format('MM/DD/YY hh:mm:ss A'),
    }, {
        Header: 'End Time',
        id: 'endTime',
        accessor: d => d.endTime,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 190,
        sortable: true,
        filterable: false,
        Cell: row => moment.utc(row.value).format('MM/DD/YY hh:mm:ss A'),
    }, {
        Header: 'Name',
        id: 'name',
        accessor: d => d.name,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 250,
        sortable: true,
        filterable: true
    }, {
        Header: 'Duration',
        id: 'duration',
        accessor: d => d.duration,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 110,
        sortable: true,
        Cell: row => row.value + ' minutes',
    }, {
        Header: 'Description',
        id: 'description',
        accessor: d => d.description,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 800,
        filterable: true,
        sortable: true
    }];

    capitalize(value) {
        if (value && value.length > 1) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        if (value && value.length === 1) {
            return value.charAt(0).toUpperCase();
        }
        return value;
    }

    back = () => {
        history.push('/channels');
    }

    refreshProgramGuide = () => {
        this.setState(() => ({ processing: true }));
        api.refreshChannelPrograms(this.props.match.params.channelCode).then((data) => {
            this.setState(() => ({ results: data }));
            this.setState(() => ({ processing: false }));
            toastr.success('Program guide refreshed successfully');
        }).catch(() => {
            this.setState(() => ({ processing: false }));
            toastr.error('Error refreshing program guide');
        });
    }

    render() {
        const { results, processing, channel } = this.state;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="container">
                        <div>&nbsp;</div>
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="header header-color center-form uppercase-text">
                            <div>Program Guide</div>
                        </div>
                        <div className="sub-header header-color">
                            &nbsp;
                        </div>
                        <div className="sub-header header-color center-form uppercase-text">
                            <div>{channel.name}</div>
                        </div>
                        <div className="sub-header header-color">
                            &nbsp;
                        </div>
                        <div className="col-lg-12 text-center">
                            <button className="btn btn-primary" onClick={() => {
                                this.refreshProgramGuide();
                            }}>Refresh Program Guide</button>
                        </div>
                        <div>&nbsp;</div>
                        {processing && <div className="progress progress-bar-width channel-programs-table">
                            <div className="progress-bar progress-bar-striped active progress-bar-completion-width" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>}
                        <div>
                            &nbsp;
                        </div>
                        <div>
                            <div className="channel-programs-table">
                                <ReactTable
                                    className="-striped -highlight"
                                    data={results}
                                    columns={this.columns}
                                    defaultPageSize={10}
                                    noDataText="No program guide found"
                                    defaultFilterMethod={
                                        (filter, row) => {
                                            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChannelPrograms;
