import React from 'react';
import { Modal } from 'react-bootstrap';

import UpdateSupportInfoForm from '../forms/update-support-info-form.component';
import * as api from '../services/api';
import toastr from '../services/toastr';

class UpdateSupportInfo extends React.Component {

    updateSupportInfo = (data) => {
        const values = { email: this.props.customer };
        values.altContact1 = data.altContact1 ? data.altContact1 : '';
        values.altContact2 = data.altContact2 ? data.altContact2 : '';
        values.address = data.address ? data.address : '';
        values.city = data.city ? data.city : '';
        values.state = data.state ? data.state : '';
        values.zipCode = data.zipCode ? data.zipCode : '';
        values.notes = data.notes ? data.notes : '';
        api.updateSupportInfo(values).then((data) => {
            toastr.success('Customer\'s support information updated successfully');
            this.props.afterAction();
        }).catch((err) => {
            toastr.error('Error updating customer\'s support information');
            this.props.afterAction();
        });
    }

    render() {
        const { show, onHide, customerDetails } = this.props;
        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton bsClass="customer-modal-header">
                </Modal.Header>
                <Modal.Body bsClass="customer-modal-body">
                    <UpdateSupportInfoForm initialValues={customerDetails} onSubmit={this.updateSupportInfo} />
                </Modal.Body>
                <Modal.Footer bsClass="customer-modal-footer">
                    &nbsp;
                </Modal.Footer>
            </Modal>
        );
    }
}

export default UpdateSupportInfo;
