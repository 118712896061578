import React from 'react';

import loader from '../images/loader.gif';

const Loading = () => (
    <div className="loader">
        <img className="loader-image" src={loader} alt="" />
    </div>
);

export default Loading;
