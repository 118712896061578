import React from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';

import ConvertToComplimentaryForm from '../forms/convert-to-complimentary-form.component';
import * as api from '../services/api';
import toastr from '../services/toastr';

class ConvertToComplimentary extends React.Component {

    convertToComplimentary = (data) => {
        const values = {
            ...data,
            email: this.props.customer,
            requestedBy: this.props.loggedInUserName,
            department: 'Admin Portal',
            startDate: this.getStartDate(this.convertDateToUtc(moment())),
            endDate: this.getEndDate(this.convertDateToUtc(moment())),
            maximumAccounts: 1
        };
        api.convertToComplimentary(values).then((data) => {
            toastr.success('Customer has been upgraded to complimentary subscription successfully');
            this.props.afterAction();
        }).catch((err) => {
            toastr.error('Error upgrading customer to complimentary subscription');
            this.props.afterAction();
        });
    }

    getStartDate = (date) => {
        return moment(date).startOf('day').toDate();
    }

    getEndDate = (date) => {
        return moment(date).endOf('day').toDate();
    }

    convertDateToUtc = (date) => {
        const offset = date.utcOffset();
        date = date.utc();
        date.add(offset, 'minutes');
        return date;
    }

    render() {
        const { show, onHide } = this.props;
        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton bsClass="customer-modal-header">
                </Modal.Header>
                <Modal.Body bsClass="customer-modal-body">
                    <ConvertToComplimentaryForm onSubmit={this.convertToComplimentary} />
                </Modal.Body>
                <Modal.Footer bsClass="customer-modal-footer">
                    &nbsp;
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedInUserName: state.user.firstName + ' ' + state.user.lastName
});

export default connect(mapStateToProps)(ConvertToComplimentary);
