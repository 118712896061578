import React from 'react';
import { Router, Switch } from 'react-router-dom';

import Home from '../pages/home.component';
import SignIn from '../pages/sign-in.component';
import SetPassword from '../pages/set-password.component';
import ResetPassword from '../pages/reset-password.component';
import UserHome from '../pages/user-home.component';
import Customers from '../pages/customers.component';
import ViewCustomer from '../pages/view-customer.component';
import AdminUsers from '../pages/admin-users.component';
import CreateAdminUser from '../pages/create-admin-user.component';
import EditAdminUser from '../pages/edit-admin-user.component';
import ContentManagement from '../pages/content-management.component';
import Channels from '../pages/channels.component';
import CreateChannel from '../pages/create-channel.component';
import EditChannel from '../pages/edit-channel.component';
import ChannelPrograms from '../pages/channel-programs.component';
import ChannelLogos from '../pages/channel-logos.component';
import ChannelTags from '../pages/channel-tags.component';
import ChannelTagValues from '../pages/channel-tag-values.component';
import CreateChannelTag from '../pages/create-channel-tag.component';
import CreateChannelTagValue from '../pages/create-channel-tag-value.component';
import Vods from '../pages/vods.component';
import Merchants from '../pages/merchants.component';
import CreateMerchant from '../pages/create-merchant.component';
import ComplimentaryCodes from '../pages/complimentary-codes.component';
import CreateComplimentaryCode from '../pages/create-complimentary-code.component';
import ApplicationErrors from '../pages/application-errors.component';
import CustomerExport from '../pages/customer-export.component';
import NotFound from '../pages/not-found.component';
import NotSupported from '../pages/not-supported.component';
import Reports from '../pages/reports.component';
import ChangeMyPassword from '../pages/change-my-password.component';
import CustomerSignUpReport from '../pages/customer-sign-up-report.component';
import CardSalesReport from '../pages/card-sales-report.component';
import InAppSalesReport from '../pages/in-app-sales-report.component';
import MerchantSalesReport from '../pages/merchant-sales-report.component';
import UtmSalesReport from '../pages/utm-sales-report.component';
import UnverifiedPremiumCustomerReport from '../pages/unverified-premium-customer-report.component';
import UnpaidPremiumCustomerReport from '../pages/unpaid-premium-customer-report.component';
import ScrollToTop from './scroll-to-top.component';
import PrivateRoute from './private-route.component';
import PublicRoute from './public-route.component';
import CommonRoute from './common-route.component';
import security from '../services/security';
import history from '../services/history';

const AppRouter = (props) => (
    <div>
        <Router history={history}>
            <div className="hidden-xs">
                <ScrollToTop>
                    <Switch>
                        <PublicRoute path="/" component={Home} exact={true} />
                        <PublicRoute path="/sign-in" component={SignIn} />
                        <PublicRoute path="/set-password/:token" component={SetPassword} />
                        <PublicRoute path="/reset-password/:token" component={ResetPassword} />
                        <PrivateRoute path="/customers/:searchText?" component={Customers} roles={security['customers']} />
                        <PrivateRoute path="/view-customer/:searchText/:email" component={ViewCustomer} roles={security['view-customer']} />
                        <PrivateRoute path="/admin-users" component={AdminUsers} roles={security['admin-users']} />
                        <PrivateRoute path="/create-admin-user" component={CreateAdminUser} roles={security['admin-users']} />
                        <PrivateRoute path="/edit-admin-user/:email" component={EditAdminUser} roles={security['admin-users']} />
                        <PrivateRoute path="/content-management" component={ContentManagement} roles={security['content-management']} />
                        <PrivateRoute path="/channels" component={Channels} roles={security['channels']} />
                        <PrivateRoute path="/create-channel" component={CreateChannel} roles={security['channels']} />
                        <PrivateRoute path="/edit-channel/:channelCode" component={EditChannel} roles={security['channels']} />
                        <PrivateRoute path="/channel-programs/:channelCode" component={ChannelPrograms} roles={security['channels']} />
                        <PrivateRoute path="/channel-logos" component={ChannelLogos} roles={security['channel-logos']} />
                        <PrivateRoute path="/channel-tags" component={ChannelTags} roles={security['channel-tags']} />
                        <PrivateRoute path="/channel-tag-values/:channelTagCode" component={ChannelTagValues} roles={security['channel-tags']} />
                        <PrivateRoute path="/create-channel-tag" component={CreateChannelTag} roles={security['channel-tags']} />
                        <PrivateRoute path="/create-channel-tag-value/:channelTagCode" component={CreateChannelTagValue} roles={security['channel-tags']} />
                        <PrivateRoute path="/vods" component={Vods} roles={security['vods']} />
                        <PrivateRoute path="/merchants" component={Merchants} roles={security['merchants']} />
                        <PrivateRoute path="/create-merchant" component={CreateMerchant} roles={security['merchants']} />
                        <PrivateRoute path="/complimentary-codes" component={ComplimentaryCodes} roles={security['complimentary-codes']} />
                        <PrivateRoute path="/create-complimentary-code" component={CreateComplimentaryCode} roles={security['complimentary-codes']} />
                        <PrivateRoute path="/application-errors" component={ApplicationErrors} roles={security['application-errors']} />
                        <PrivateRoute path="/customer-export" component={CustomerExport} roles={security['customer-export']} />
                        <PrivateRoute path="/user-home" component={UserHome} roles={security['user-home']} />
                        <PrivateRoute path="/reports" component={Reports} roles={security['reports']} />
                        <PrivateRoute path="/change-my-password" component={ChangeMyPassword} roles={security['change-my-password']} />
                        <PrivateRoute path="/customer-sign-up-report" component={CustomerSignUpReport} roles={security['customer-sign-up-report']} />
                        <PrivateRoute path="/card-sales-report" component={CardSalesReport} roles={security['card-sales-report']} />
                        <PrivateRoute path="/in-app-sales-report" component={InAppSalesReport} roles={security['in-app-sales-report']} />
                        <PrivateRoute path="/merchant-sales-report" component={MerchantSalesReport} roles={security['merchant-sales-report']} />
                        <PrivateRoute path="/utm-sales-report" component={UtmSalesReport} roles={security['utm-sales-report']} />
                        <PrivateRoute path="/unverified-premium-customer-report" component={UnverifiedPremiumCustomerReport} roles={security['unverified-premium-customer-report']} />
                        <PrivateRoute path="/unpaid-premium-customer-report" component={UnpaidPremiumCustomerReport} roles={security['unpaid-premium-customer-report']} />
                        <CommonRoute component={NotFound} />
                    </Switch>
                </ScrollToTop>
            </div>
        </Router>
        <NotSupported />
    </div>
);

export default AppRouter;
