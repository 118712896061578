import React from 'react';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import numeral from 'numeral';

import * as api from '../services/api';

class UserHome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            statistics: []
        };
    }

    componentDidMount() {
        api.getUserHomeStatistics().then((data) => {
            this.setState(() => ({ statistics: data }));
        }).catch(() => {
            toastr.error('Error fetching report');
        });
    }

    render() {
        const { statistics } = this.state;

        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="container">
                        <div>&nbsp;</div>
                        <div className="col-md-12 text-center uppercase-text bolder-text">
                            <h2 className="header-color">Customer Sign Up Report</h2>
                        </div>
                        {statistics.length > 0 ?
                            (<div className="col-md-12 table-holder">
                                <div className="text-center uppercase-text bold-text">Total Attribution</div>
                                <table align="center">
                                    <thead>
                                        <tr>
                                            <th className="row-headings"></th>
                                            <th>Today</th>
                                            <th>Yesterday</th>
                                            <th>2 Days Ago</th>
                                            <th>3 Days Ago</th>
                                            <th>7 Days Ago</th>
                                            <th>This Month</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="row-headings"><strong>Trial</strong></td>
                                            <td>{numeral(statistics[7]).format('0,0')}</td>
                                            <td>{numeral(statistics[8]).format('0,0')}</td>
                                            <td>{numeral(statistics[9]).format('0,0')}</td>
                                            <td>{numeral(statistics[10]).format('0,0')}</td>
                                            <td>{numeral(statistics[11]).format('0,0')}</td>
                                            <td>{numeral(statistics[12]).format('0,0')}</td>
                                            <td>{numeral(statistics[13]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="row-headings"><strong>Premium</strong></td>
                                            <td>{numeral(statistics[0]).format('0,0')}</td>
                                            <td>{numeral(statistics[1]).format('0,0')}</td>
                                            <td>{numeral(statistics[2]).format('0,0')}</td>
                                            <td>{numeral(statistics[3]).format('0,0')}</td>
                                            <td>{numeral(statistics[4]).format('0,0')}</td>
                                            <td>{numeral(statistics[5]).format('0,0')}</td>
                                            <td>{numeral(statistics[6]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="row-headings"><strong>Complimentary</strong></td>
                                            <td>{numeral(statistics[14]).format('0,0')}</td>
                                            <td>{numeral(statistics[15]).format('0,0')}</td>
                                            <td>{numeral(statistics[16]).format('0,0')}</td>
                                            <td>{numeral(statistics[17]).format('0,0')}</td>
                                            <td>{numeral(statistics[18]).format('0,0')}</td>
                                            <td>{numeral(statistics[19]).format('0,0')}</td>
                                            <td>{numeral(statistics[20]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="row-headings"><strong>Total</strong></td>
                                            <td>{numeral(statistics[0] + statistics[7] + statistics[14]).format('0,0')}</td>
                                            <td>{numeral(statistics[1] + statistics[8] + statistics[15]).format('0,0')}</td>
                                            <td>{numeral(statistics[2] + statistics[9] + statistics[16]).format('0,0')}</td>
                                            <td>{numeral(statistics[3] + statistics[10] + statistics[17]).format('0,0')}</td>
                                            <td>{numeral(statistics[4] + statistics[11] + statistics[18]).format('0,0')}</td>
                                            <td>{numeral(statistics[5] + statistics[12] + statistics[19]).format('0,0')}</td>
                                            <td>{numeral(statistics[6] + statistics[13] + statistics[20]).format('0,0')}</td>
                                        </tr>
                                        <tr>
                                            <td className="row-headings"><strong>Cancellation Requests</strong></td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>{numeral(statistics[21]).format('0,0')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="text-center">
                                    <Link className="btn btn-lg btn-link bold-text" to="/customer-sign-up-report">Detailed Report</Link>
                                </div>
                                <div className="col-md-12">Note:</div>
                                <div className="col-md-12">Reports are based on Eastern Time</div>
                                <div className="col-md-12">Reports are based on current customer information</div>
                            </div>)
                            :
                            (<div className="col-md-12 table-holder text-center">Fetching customer sign up report. Please wait...</div>)
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default UserHome;
