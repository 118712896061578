import React from 'react';
import { Field, reduxForm } from 'redux-form';

const languages = [
    { value: 'en', text: 'English' },
    { value: 'es', text: 'Español' }
];

const timeZones = [
    { value: 'America/New_York', text: 'Eastern Time' },
    { value: 'America/Chicago', text: 'Central Time' },
    { value: 'America/Denver', text: 'Mountain Time' },
    { value: 'America/Los_Angeles', text: 'Pacific Time' },
    { value: 'America/Phoenix', text: 'Arizona' },
    { value: 'America/Anchorage', text: 'Alaska' },
    { value: 'America/Adak', text: 'Aleutian Islands' },
    { value: 'America/Honolulu', text: 'Hawaii' },
    { value: 'America/Pago_Pago', text: 'Samoa' }
];

const renderDropDown = ({ input, options, meta: { touched, error } }) => {
    return (
        <div>
            <select {...input} className={"text-box drop-down-list " + (touched && error ? "text-box-error" : "")}>
                {
                    options.map((opt, i) => {
                        return <option value={opt.value} key={i}>{opt.text}</option>;
                    })
                }
            </select>
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const UpdatePreferencesForm = (props) => {
    const { handleSubmit, submitting } = props;
    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <span className="label-text">Select Time Zone</span>
            <br />
            <Field name="defaultTimeZone" options={timeZones} component={renderDropDown} />
            <br />
            <span className="label-text">Select Preferred Language</span>
            <br />
            <Field name="defaultLanguage" options={languages} component={renderDropDown} />
            <br />
            <Field type="checkbox" component="input" id="emailSmsSubscription" name="emailSmsSubscription" />
            <label className="purple" htmlFor="emailSmsSubscription">
                <span>Send updates to the phone number<sup>*</sup> or email customer signed up with</span>
            </label>
            <br />
            <br />
            <button type="submit" disabled={submitting} className="submit-button">Submit</button>
            <div className="full-form center-form">
                <span className="label-text-info"><sup>*</sup>Data rates may apply</span>
            </div>
        </form>
    );
};

export default reduxForm({ form: 'updatePreferences' })(UpdatePreferencesForm);
