import React from 'react';
import { Field, reduxForm } from 'redux-form';

import * as validations from '../services/validations';

const validate = (values) => {
    const errors = {};
    if (validations.isEmpty(values.code)) {
        errors.code = 'Enter channel tag code';
    } else if (!validations.isChannelCode(values.code)) {
        errors.code = 'Enter a valid channel tag code in uppercase';
    }
    if (validations.isEmpty(values.name)) {
        errors.name = 'Enter channel tag name';
    } else if (!validations.isName(values.name)) {
        errors.name = 'Enter a valid channel tag name';
    }
    return errors;
};

const renderField = ({ input, type, maxLength, meta: { touched, error } }) => {
    return (
        <div>
            <input {...input} autoComplete="off" maxLength={maxLength} type={type} className={"text-box " + (touched && error ? "text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const CreateChannelTagForm = (props) => {
    const { handleSubmit, submitting } = props;
    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <span className="label-text">Channel Tag Code</span>
            <br />
            <Field name="code" type="text" maxLength="4" component={renderField} />
            <br />
            <span className="label-text">Channel Tag Name</span><br />
            <Field name="name" maxLength="20" type="text" component={renderField} />
            <br />
            <button type="submit" disabled={submitting} className="submit-button">Create Channel Tag</button>
        </form>
    );
};

export default reduxForm({ form: 'createChannelTag', validate })(CreateChannelTagForm);
