import * as actionTypes from '../constants/action-types';
import * as api from '../services/api';

export const signInSuccess = (user) => (
    {
        type: actionTypes.SIGN_IN_SUCCESS,
        user
    }
);

export const signOutSuccess = () => (
    {
        type: actionTypes.SIGN_OUT_SUCCESS
    }
);

export const signIn = (values) => {
    return (dispatch, getState) => {
        return api.signIn(values).then((data) => {
            sessionStorage.setItem('token', data.token);
            return api.getUserProfile().then((data) => {
                dispatch(signInSuccess(data));
            }).catch((err) => {
                throw new Error(err.message);
            });
        }).catch((err) => {
            throw new Error(err.message);
        });
    };
};

export const loadUser = () => {
    return (dispatch, getState) => {
        return api.getUserProfile().then((data) => {
            dispatch(signInSuccess(data));
        }).catch((err) => {
            throw new Error(err.message);
        });
    };
};

export const signOut = () => {
    return (dispatch, getState) => {
        return api.signOut().then(() => {
            sessionStorage.removeItem('token');
            dispatch(signOutSuccess());
        }).catch((err) => {
            sessionStorage.removeItem('token');
            throw new Error(err.message);
        });
    };
};

export const signInFailure = () => {
    return signOut();
};
