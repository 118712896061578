import React from 'react';
import toastr from 'toastr';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import moment from 'moment';
import numeral from 'numeral';
import WindowSizeListener from 'react-window-size-listener';

import * as api from '../services/api';
import history from '../services/history';
import security from '../services/security';
import CustomerButton from '../widgets/customer-button.component';
import ConfirmModal from '../widgets/confirm-modal.component';
import AddCharge from '../widgets/add-charge.component';
import AddCredit from '../widgets/add-credit.component';
import EmailInvoice from '../widgets/email-invoice.component';
import AddComplimentaryDays from '../widgets/add-complimentary-days.component';
import UpdateUserInfo from '../widgets/update-user-info.component';
import UpdatePrefernces from '../widgets/update-preferences.component';
import ChangePassword from '../widgets/change-password.component';
import ConvertToComplimentary from '../widgets/convert-to-complimentary.component';
import UpdateSupportInfo from '../widgets/update-support-info.component';
import ChangeCreditCard from '../widgets/change-credit-card.component';
import UpgradeSubscription from '../widgets/upgrade-subscription.component';

class ViewCustomer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            searchText: '',
            results: null,
            customer: null,
            processing: false,
            confirmModalState: '',
            confirmModalBody: '',
            customerModalState: '',
            invoiceNumber: ''
        };
    }

    componentDidMount() {
        const searchText = this.props.match.params.searchText;
        const email = this.props.match.params.email;
        if (searchText && searchText.trim() !== '') {
            this.setState(() => ({ searchText: searchText }));
        }
        if (email && email.trim() !== '') {
            this.setState(() => ({ email: email }), () => { this.getUserDetails(); });
        }
    }

    emailInvoice = (value) => {
        this.setState(() => ({ invoiceNumber: value }), () => { this.showCustomerModal('email-invoice'); });
    }

    showCustomerModal = (value) => {
        this.setState(() => ({ customerModalState: value }));
    }

    hideCustomerModal = () => {
        this.setState(() => ({ customerModalState: '' }));
    }

    hideCustomerModalAndRefresh = () => {
        this.setState(() => ({ customerModalState: '' }));
        this.getUserDetails();
    }

    back = () => {
        history.push(`/customers/${this.state.searchText}/${this.state.email}`);
    }

    getUserDetails = () => {
        this.setState(() => ({ processing: true }));
        api.getUserDetails(this.state.email).then((data) => {
            this.setState(() => ({ results: data }));
            this.setState(() => ({ customer: data.user }));
            this.setState(() => ({ processing: false }));
        }).catch((err) => {
            this.setState(() => ({ results: {} }));
            this.setState(() => ({ processing: false }));
            if (err.message === 'UserNotFound') {
                toastr.error('Customer not found');
            } else {
                toastr.error('Error fetching customer details');
            }
        });
    }

    getFormattedUsername = () => {
        let username = this.state.email;
        if (username) {
            username = username.trim().toLowerCase();
        }
        if (/^\d{11}$/.test(username)) {
            return '(' + username.substring(1, 4) + ') ' + username.substring(4, 7) + '-' + username.substring(7);
        }
        return username;
    }

    getType = () => {
        if (this.state.customer) {
            if (this.state.customer.type === 'paid') {
                return 'Premium';
            }
            if (this.state.customer.type === 'free') {
                return 'Free';
            }
            if (this.state.customer.type === 'comp') {
                return 'Complimentary';
            }
            if (this.state.customer.type === 'none') {
                return 'None';
            }
            return this.state.customer.type;
        } else {
            return '';
        }
    }

    getStatus = () => {
        if (this.state.customer) {
            if (this.state.customer.status === 'registered') {
                return 'Unverified';
            }
            if (this.state.customer.status === 'active') {
                return 'Verified';
            }
            if (this.state.customer.status === 'suspended') {
                return 'Suspended';
            }
            return this.state.customer.status;
        } else {
            return '';
        }
    }

    getLanguage = (value) => {
        if (value) {
            if (value === 'en') {
                return 'English';
            }
            if (value === 'es') {
                return 'Spanish';
            }
            return value;
        } else {
            return '';
        }
    };

    getYesNo = (value) => {
        return value ? 'Yes' : 'No';
    };

    getFormattedDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            return this.pad(date.getUTCMonth() + 1) + '/' + this.pad(date.getUTCDate()) + '/' + date.getUTCFullYear();
        } else {
            return '';
        }
    };

    pad = (number) => {
        let num = String(number);
        if (num.length === 1) {
            num = '0' + num;
        }
        return num;
    }

    capitalize = (value) => {
        if (value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        } else {
            return '';
        }
    }

    negateCurrency = (value) => {
        value = value * -1;
        return numeral(value).format('$0.00');
    }

    showConfirmModal = (value) => {
        switch (value) {
            case 'verify-customer':
                this.setState(() => ({ confirmModalState: value }));
                this.setState(() => ({ confirmModalBody: 'Are you sure you want to verify this customer?' }));
                break;
            case 'reset-devices':
                this.setState(() => ({ confirmModalState: value }));
                this.setState(() => ({ confirmModalBody: 'Are you sure you want to reset customer\'s authorized devices?' }));
                break;
            case 'cancel-subscription-on-last-day':
                this.setState(() => ({ confirmModalState: value }));
                this.setState(() => ({ confirmModalBody: 'Customer\'s subscription will be cancelled on the last day of the current billing cycle which is ' + this.getFormattedDate(this.state.customer.subscriptions[0].endDate) + '. Are you sure you want to cancel customer\'s premium subscription?' }));
                break;
            case 'withdraw-cancellation':
                this.setState(() => ({ confirmModalState: value }));
                this.setState(() => ({ confirmModalBody: 'Customer\'s YipTV subscription is scheduled to be cancelled on ' + this.getFormattedDate(this.state.customer.subscriptions[0].cancelOn) + '. Are your sure you want to withdraw the cancellation?' }));
                break;
            case 'cancel-subscription-without-refund':
                this.setState(() => ({ confirmModalState: value }));
                this.setState(() => ({ confirmModalBody: 'Are you sure you want to cancel customer\'s premium subscription with immediate effect and without refund?' }));
                break;
            case 'cancel-subscription-with-refund':
                this.setState(() => ({ confirmModalState: value }));
                this.setState(() => ({ confirmModalBody: 'Are you sure you want to cancel customer\'s premium subscription with immediate effect and refund the amount?' }));
                break;
            case 'end-complimentary':
                this.setState(() => ({ confirmModalState: value }));
                this.setState(() => ({ confirmModalBody: 'Are you sure you want to cancel customer\'s complimentary subscription with immediate effect?' }));
                break;
            case 'suspend-customer':
                this.setState(() => ({ confirmModalState: value }));
                this.setState(() => ({ confirmModalBody: 'Are you sure you want to suspend this customer\'s account?' }));
                break;
            case 'unsuspend-customer':
                this.setState(() => ({ confirmModalState: value }));
                this.setState(() => ({ confirmModalBody: 'Are you sure you want to unsuspend this customer\'s account?' }));
                break;
            default:
                this.setState(() => ({ confirmModalState: '' }));
                this.setState(() => ({ confirmModalBody: '' }));
                break;
        }
    }

    hideConfirmModal = () => {
        this.setState(() => ({ confirmModalState: '' }));
        this.setState(() => ({ confirmModalBody: '' }));
    }

    confirmModalAction = () => {
        switch (this.state.confirmModalState) {
            case 'verify-customer':
                this.verifyCustomer();
                break;
            case 'reset-devices':
                this.resetDevices();
                break;
            case 'cancel-subscription-on-last-day':
                this.cancelSubscriptionOnLastDay();
                break;
            case 'withdraw-cancellation':
                this.withdrawCancellation();
                break;
            case 'cancel-subscription-without-refund':
                this.cancelSubscriptionWithoutRefund();
                break;
            case 'cancel-subscription-with-refund':
                this.cancelSubscriptionWithRefund();
                break;
            case 'end-complimentary':
                this.endComplimentary();
                break;
            case 'suspend-customer':
                this.suspendCustomer();
                break;
            case 'unsuspend-customer':
                this.unsuspendCustomer();
                break;
            default:
                break;
        }
    }

    verifyCustomer = () => {
        api.verifyUser(this.state.email).then(() => {
            this.hideConfirmModal();
            toastr.success('Customer has been verified successfully');
            this.getUserDetails();
        }).catch((err) => {
            this.hideConfirmModal();
            toastr.error('Error verifying customer');
            this.getUserDetails();
        });
    }

    resetDevices = () => {
        api.clearUserSessions(this.state.email).then(() => {
            this.hideConfirmModal();
            toastr.success('Customer devices have been reset successfully');
            this.getUserDetails();
        }).catch((err) => {
            this.hideConfirmModal();
            toastr.error('Error resetting customer devices');
            this.getUserDetails();
        });
    }

    cancelSubscriptionOnLastDay = () => {
        api.cancelSubscription(this.state.email).then(() => {
            this.hideConfirmModal();
            toastr.success('Customer\'s subscription has been cancelled successfully');
            this.getUserDetails();
        }).catch((err) => {
            this.hideConfirmModal();
            toastr.error('Error cancelling customer\'s subscription');
            this.getUserDetails();
        });
    }

    cancelSubscriptionWithoutRefund = () => {
        api.endPremiumSubscription(this.state.email).then(() => {
            this.hideConfirmModal();
            toastr.success('Customer\'s premium subscription has been cancelled successfully with immediate effect');
            this.getUserDetails();
        }).catch((err) => {
            this.hideConfirmModal();
            toastr.error('Error cancelling customer\'s premium subscription with immediate effect');
            this.getUserDetails();
        });
    }

    cancelSubscriptionWithRefund = () => {
        api.endAndRefundPremiumSubscription(this.state.email).then(() => {
            this.hideConfirmModal();
            toastr.success('Customer\'s premium subscription has been cancelled successfully with immediate effect and the payment has been refunded');
            this.getUserDetails();
        }).catch((err) => {
            this.hideConfirmModal();
            toastr.error('Error cancelling and refunding customer\'s premium subscription');
            this.getUserDetails();
        });
    }

    withdrawCancellation = () => {
        api.revertCancelSubscription(this.state.email).then(() => {
            this.hideConfirmModal();
            toastr.success('Cancel subscription request of customer successfully withdrawn');
            this.getUserDetails();
        }).catch((err) => {
            this.hideConfirmModal();
            toastr.error('Error withdrawing cancel subscription request of customer');
            this.getUserDetails();
        });
    }

    endComplimentary = () => {
        api.endComplimentarySubscription(this.state.email).then(() => {
            this.hideConfirmModal();
            toastr.success('Customer\'s complimentary subscription has been cancelled successfully with immediate effect');
            this.getUserDetails();
        }).catch((err) => {
            this.hideConfirmModal();
            toastr.error('Error cancelling customer\'s complimentary subscription with immediate effect');
            this.getUserDetails();
        });
    }

    suspendCustomer = (email) => {
        api.suspendUser(this.state.email).then(() => {
            this.hideConfirmModal();
            toastr.success('Customer suspended successfully');
            this.getUserDetails();
        }).catch((err) => {
            this.hideConfirmModal();
            toastr.error('Error suspending customer');
            this.getUserDetails();
        });
    }

    unsuspendCustomer = (email) => {
        api.unsuspendUser(this.state.email).then(() => {
            this.hideConfirmModal();
            toastr.success('Customer unsuspended successfully');
            this.getUserDetails();
        }).catch((err) => {
            this.hideConfirmModal();
            toastr.error('Error unsuspending customer');
            this.getUserDetails();
        });
    }

    render() {
        const { results, email, customer, confirmModalState, confirmModalBody, customerModalState, invoiceNumber } = this.state;
        const { freeViewSecondsAllowed } = this.props;
        return (
            <div className="content-area">
                <div className="content-box">
                    {customer && <div className="container">
                        <ConfirmModal
                            showYesButton={true}
                            showNoButton={true}
                            bsSize={'sm'}
                            show={confirmModalState !== ''}
                            onHide={this.hideConfirmModal}
                            title={'YipTV Admin Portal'}
                            body={confirmModalBody}
                            yesButtonClick={this.confirmModalAction}
                            noButtonClick={this.hideConfirmModal}
                        />
                        <WindowSizeListener onResize={window => {
                            if (window.windowWidth <= 767) {
                                this.hideConfirmModal();
                                this.hideCustomerModal();
                            }
                        }} />
                        <div>&nbsp;</div>
                        <div className="row text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center uppercase-text bolder-text"><h2 className="header-color">Customer Details</h2></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center"><h4 className="header-color bolder-text">{this.getFormattedUsername()}</h4></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 user-holder">
                                <table align="center">
                                    <tbody>
                                        <tr>
                                            <th>Type</th>
                                            <td>{this.getType()}</td>
                                            <th>Status</th>
                                            <td>{this.getStatus()}</td>
                                        </tr>
                                        <tr>
                                            <th>First Name</th>
                                            <td>{customer.firstName}</td>
                                            <th>Last Name</th>
                                            <td>{customer.lastName}</td>
                                        </tr>
                                        <tr>
                                            <th>Language</th>
                                            <td>{this.getLanguage(customer.preferences.defaultLanguage)}</td>
                                            <th>Communication</th>
                                            <td>{this.getYesNo(customer.preferences.emailSmsSubscription)}</td>
                                        </tr>
                                        <tr>
                                            <th>Merchant</th>
                                            <td>{customer.merchant}</td>
                                            <th>Sign Up Date</th>
                                            <td>{this.getFormattedDate(customer.createdAt)}</td>
                                        </tr>
                                        <tr>
                                            <th>Free Seconds Consumed</th>
                                            <td>{customer.freeSecondsConsumed} / {freeViewSecondsAllowed}</td>
                                            <th>Last Login Date</th>
                                            <td>{this.getFormattedDate(customer.lastLoginTime)}</td>
                                        </tr>
                                        <tr>
                                            <th>Last Payment Mode</th>
                                            <td>{this.capitalize(customer.lastPaymentMode)}</td>
                                            <th>In-App Subscription</th>
                                            <td>{this.getYesNo(customer.inAppSubscription)}</td>
                                        </tr>
                                        <tr>
                                            <th>Stripe Customer ID</th>
                                            <td>{customer.customer}</td>
                                            <th>Complimentary Valid Till</th>
                                            <td>{this.getFormattedDate(customer.validTill)}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4" className="align-center">
                                                {customer.status === 'registered' && <CustomerButton clickHandler={() => { this.showConfirmModal('verify-customer'); }} buttonClass="customer-btn-1" roles={security['verify-customer']} name="Verify Customer" />}
                                                {customer.status === 'active' && <CustomerButton clickHandler={() => { this.showCustomerModal('modify-name'); }} buttonClass="customer-btn-1" roles={security['modify-name']} name="Modify Name" />}
                                                {customer.status === 'active' && <CustomerButton clickHandler={() => { this.showCustomerModal('modify-preferences'); }} buttonClass="customer-btn-1" roles={security['modify-preferences']} name="Modify Preferences" />}
                                                {customer.status === 'active' && <CustomerButton clickHandler={() => { this.showCustomerModal('modify-password'); }} buttonClass="customer-btn-1" roles={security['modify-password']} name="Modify Password" />}
                                                {customer.status === 'active' && <CustomerButton clickHandler={() => { this.showConfirmModal('reset-devices'); }} buttonClass="customer-btn-1" roles={security['reset-devices']} name="Reset Devices" />}
                                                {customer.status === 'active' && customer.type === 'free' && <CustomerButton clickHandler={() => { this.showCustomerModal('upgrade-to-premium'); }} buttonClass="customer-btn-1" roles={security['upgrade-to-premium']} name="Upgrade to Premium" />}
                                                {customer.status === 'active' && customer.type === 'free' && <CustomerButton clickHandler={() => { this.showCustomerModal('upgrade-to-complimentary'); }} buttonClass="customer-btn-1" roles={security['upgrade-to-complimentary']} name="Upgrade to Complimentary" />}
                                                {customer.status === 'active' && customer.type === 'comp' && <CustomerButton clickHandler={() => { this.showConfirmModal('end-complimentary'); }} buttonClass="customer-btn-1" roles={security['end-complimentary']} name="End Complimentary" />}
                                                {customer.status === 'active' && customer.type === 'free' && <CustomerButton clickHandler={() => { this.showConfirmModal('suspend-customer'); }} buttonClass="customer-btn-1" roles={security['suspend-customer']} name="Suspend Customer" />}
                                                {customer.status === 'suspended' && <CustomerButton clickHandler={() => { this.showConfirmModal('unsuspend-customer'); }} buttonClass="customer-btn-1" roles={security['unsuspend-customer']} name="Unsuspend Customer" />}
                                                {customer.status === 'active' && customer.type === 'paid' && customer.cards && customer.cards.length === 0 && !customer.inAppSubscription && <CustomerButton clickHandler={() => { this.showCustomerModal('change-credit-card'); }} buttonClass="customer-btn-1" roles={security['change-credit-card']} name="Add Credit Card" />}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <UpdateUserInfo show={customerModalState === 'modify-name'} customer={email} customerDetails={{ firstName: customer.firstName, lastName: customer.lastName }} onHide={this.hideCustomerModal} afterAction={this.hideCustomerModalAndRefresh} />
                                <UpdatePrefernces show={customerModalState === 'modify-preferences'} customer={email} customerDetails={customer.preferences} onHide={this.hideCustomerModal} afterAction={this.hideCustomerModalAndRefresh} />
                                <ChangePassword show={customerModalState === 'modify-password'} customer={email} onHide={this.hideCustomerModal} afterAction={this.hideCustomerModalAndRefresh} />
                                <ConvertToComplimentary show={customerModalState === 'upgrade-to-complimentary'} customer={email} onHide={this.hideCustomerModal} afterAction={this.hideCustomerModalAndRefresh} />
                                <ChangeCreditCard show={customerModalState === 'change-credit-card'} customer={email} customerDetails={customer} onHide={this.hideCustomerModal} afterAction={this.hideCustomerModalAndRefresh} />
                                <UpgradeSubscription show={customerModalState === 'upgrade-to-premium'} customer={email} customerDetails={customer} onHide={this.hideCustomerModal} afterAction={this.hideCustomerModalAndRefresh} />
                            </div>
                        </div>
                        {
                            customer.type === 'paid' && customer.inAppSubscription && customer.inAppSubscriptionDetails && customer.inAppSubscriptionDetails.length === 1 &&
                            <div>
                                <div className="row">
                                    <div className="col-md-12 text-center"><h4 className="header-color bolder-text">In-App Subscription Details</h4></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 user-holder">
                                        <table align="center">
                                            <tbody>
                                                <tr>
                                                    <th>Device</th>
                                                    <td>{customer.merchant}</td>
                                                    <th>Plan</th>
                                                    <td>{customer.inAppSubscriptionDetails[0].plan}</td>
                                                </tr>
                                                <tr>
                                                    <th>Start Date</th>
                                                    <td>{this.getFormattedDate(customer.inAppSubscriptionDetails[0].startDate)}</td>
                                                    <th>End Date</th>
                                                    <td>{this.getFormattedDate(customer.inAppSubscriptionDetails[0].endDate)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            customer.type === 'paid' && !customer.inAppSubscription && customer.subscriptions && customer.subscriptions.length === 1 &&
                            <div>
                                <div className="row">
                                    <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Subscription Details</h4></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 user-holder">
                                        <table align="center">
                                            <tbody>
                                                <tr>
                                                    <th>Plan</th>
                                                    <td>{customer.subscriptions[0].plan}</td>
                                                    <th>Coupon</th>
                                                    <td>{customer.subscriptions[0].coupon}</td>
                                                </tr>
                                                <tr>
                                                    <th>Start Date</th>
                                                    <td>{this.getFormattedDate(customer.subscriptions[0].startDate)}</td>
                                                    <th>End Date</th>
                                                    <td>{this.getFormattedDate(customer.subscriptions[0].endDate)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Invoice Date</th>
                                                    <td>{this.getFormattedDate(customer.subscriptions[0].nextInvoiceDate)}</td>
                                                    <th>Cancel On</th>
                                                    <td>{this.getFormattedDate(customer.subscriptions[0].cancelOn)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Invoices</th>
                                                    <td>{customer.subscriptions[0].invoiceCount}</td>
                                                    <th>Payment Attempts</th>
                                                    <td>{customer.subscriptions[0].paymentAttemptCount}</td>
                                                </tr>
                                                {
                                                    customer.status === 'active' &&
                                                    <tr>
                                                        <td colSpan="4" className="align-center">
                                                            {!customer.subscriptions[0].cancelOn && <CustomerButton clickHandler={() => { this.showConfirmModal('cancel-subscription-on-last-day'); }} buttonClass="customer-btn-2" roles={security['cancel-subscription-on-last-day']} name="Cancel On Last Day of Billing Cycle"></CustomerButton>}
                                                            {customer.subscriptions[0].cancelOn && <CustomerButton clickHandler={() => { this.showConfirmModal('withdraw-cancellation'); }} buttonClass="customer-btn-2" roles={security['withdraw-cancellation']} name="Withdraw Cancellation"></CustomerButton>}
                                                            {!customer.subscriptions[0].cancelOn && <CustomerButton clickHandler={() => { this.showConfirmModal('cancel-subscription-without-refund'); }} buttonClass="customer-btn-2" roles={security['cancel-subscription-without-refund']} name="Cancel Immediately (No Refund)"></CustomerButton>}
                                                            {!customer.subscriptions[0].cancelOn && customer.lastPaymentMode === 'card' && <CustomerButton clickHandler={() => { this.showConfirmModal('cancel-subscription-with-refund'); }} buttonClass="customer-btn-2" roles={security['cancel-subscription-with-refund']} name="Cancel Subscription With Refund"></CustomerButton>}
                                                            {!customer.subscriptions[0].cancelOn && <CustomerButton clickHandler={() => { this.showCustomerModal('add-complimentary-days'); }} buttonClass="customer-btn-2" roles={security['add-complimentary-days']} name="Add Complimentary Days"></CustomerButton>}
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                        <AddComplimentaryDays show={customerModalState === 'add-complimentary-days'} customer={email} onHide={this.hideCustomerModal} afterAction={this.hideCustomerModalAndRefresh} />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            customer.type === 'paid' && !customer.inAppSubscription && customer.subscriptions && customer.subscriptions.length === 1 && customer.cards && customer.cards.length === 1 &&
                            <div>
                                <div className="row">
                                    <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Credit Card Details</h4></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 user-holder">
                                        <table align="center">
                                            <tbody>
                                                <tr>
                                                    <th>Name on Card</th>
                                                    <td>{customer.cards[0].name}</td>
                                                    <th>Card Number</th>
                                                    <td>XXXX-XXXX-XXXX-{customer.cards[0].last4}</td>
                                                </tr>
                                                <tr>
                                                    <th>Brand</th>
                                                    <td>{this.capitalize(customer.cards[0].brand)}</td>
                                                    <th>Funding</th>
                                                    <td>{this.capitalize(customer.cards[0].funding)}</td>
                                                </tr>
                                                {customer.status === 'active' && !customer.subscriptions[0].cancelOn && <tr>
                                                    <td colSpan="4" className="align-center">
                                                        <CustomerButton roles={security['change-credit-card']} buttonClass="customer-btn-1" clickHandler={() => { this.showCustomerModal('change-credit-card'); }} name="Change Credit Card"></CustomerButton>
                                                    </td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            customer.address &&
                            <div>
                                <div className="row">
                                    <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Address</h4></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 user-holder">
                                        <table align="center">
                                            <tbody>
                                                <tr>
                                                    <th>Address Line 1</th>
                                                    <td>{customer.address.line1}</td>
                                                    <th>Address Line 2</th>
                                                    <td>{customer.address.line2}</td>
                                                </tr>
                                                <tr>
                                                    <th>City</th>
                                                    <td>{customer.address.city}</td>
                                                    <th>State</th>
                                                    <td>{customer.address.state}</td>
                                                </tr>
                                                <tr>
                                                    <th>Zip Code</th>
                                                    <td>{customer.address.zipCode}</td>
                                                    <th>Country</th>
                                                    <td>{customer.address.country}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            customer.attribution &&
                            <div>
                                <div className="row">
                                    <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Attribution</h4></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 user-holder">
                                        <table align="center">
                                            <tbody>
                                                <tr>
                                                    <th>Client</th>
                                                    <td>{customer.attribution.client}</td>
                                                    <th>UTM Source</th>
                                                    <td>{customer.attribution.utmSource}</td>
                                                </tr>
                                                <tr>
                                                    <th>UTM Campaign</th>
                                                    <td>{customer.attribution.utmCampaign}</td>
                                                    <th>UTM Medium</th>
                                                    <td>{customer.attribution.utmMedium}</td>
                                                </tr>
                                                <tr>
                                                    <th>UTM Term</th>
                                                    <td>{customer.attribution.utmTerm}</td>
                                                    <th>UTM Content</th>
                                                    <td>{customer.attribution.utmContent}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            customer.facebook &&
                            <div>
                                <div className="row">
                                    <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Facebook Profile</h4></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 user-holder">
                                        <table align="center">
                                            <tbody>
                                                <tr>
                                                    <th>First Name</th>
                                                    <td>{customer.facebook.first_name}</td>
                                                    <th>Last Name</th>
                                                    <td>{customer.facebook.last_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Profile Link</th>
                                                    <td colSpan="3"><a href={customer.facebook.link} target="_blank" rel="noopener noreferrer">Visit customer's Facebook profile</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            customer.google &&
                            <div>
                                <div className="row">
                                    <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Google Profile</h4></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 user-holder">
                                        <table align="center">
                                            <tbody>
                                                <tr>
                                                    <th>First Name</th>
                                                    <td>{customer.google.given_name}</td>
                                                    <th>Last Name</th>
                                                    <td>{customer.google.family_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Language</th>
                                                    <td>{this.getLanguage(customer.google.locale)}</td>
                                                    <th>Gender</th>
                                                    <td>{this.capitalize(customer.google.gender)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Profile Link</th>
                                                    <td colSpan="3"><a href={customer.google.profile} target="_blank" rel="noopener noreferrer">Visit customer's Google profile</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            customer.twitter &&
                            <div>
                                <div className="row">
                                    <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Twitter Profile</h4></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 user-holder">
                                        <table align="center">
                                            <tbody>
                                                <tr>
                                                    <th>Full Name</th>
                                                    <td>{customer.twitter.name}</td>
                                                    <th>Location</th>
                                                    <td>{customer.twitter.location}</td>
                                                </tr>
                                                <tr>
                                                    <th>Language</th>
                                                    <td>{this.getLanguage(customer.twitter.lang)}</td>
                                                    <th>Time Zone</th>
                                                    <td>{customer.twitter.time_zone}</td>
                                                </tr>
                                                <tr>
                                                    <th>Profile Link</th>
                                                    <td colSpan="3"><a href={'https://twitter.com/intent/user?user_id=' + customer.twitter.id_str} target="_blank" rel="noopener noreferrer">Visit customer's Twitter profile</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        <div>
                            <div className="row">
                                <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Support Information</h4></div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 user-holder">
                                    <table align="center">
                                        <tbody>
                                            <tr>
                                                <th>Alternate Contact 1</th>
                                                <td>{customer.support ? customer.support.altContact1 : ''}</td>
                                                <th>Alternate Contact 2</th>
                                                <td>{customer.support ? customer.support.altContact2 : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Address</th>
                                                <td>{customer.support ? customer.support.address : ''}</td>
                                                <th>City</th>
                                                <td>{customer.support ? customer.support.city : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>State</th>
                                                <td>{customer.support ? customer.support.state : ''}</td>
                                                <th>Zip Code</th>
                                                <td>{customer.support ? customer.support.zipCode : ''}</td>
                                            </tr>
                                            <tr>
                                                <th>Notes</th>
                                                <td colSpan="3">
                                                    <textarea readOnly className="support-notes" value={customer.support ? customer.support.notes : ''}></textarea>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4" className="align-center">
                                                    {customer && <CustomerButton roles={security['modify-support-information']} buttonClass="customer-btn-1" clickHandler={() => { this.showCustomerModal('modify-support-information'); }} name="Modify Support Information"></CustomerButton>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <UpdateSupportInfo show={customerModalState === 'modify-support-information'} customer={email} customerDetails={customer.support} onHide={this.hideCustomerModal} afterAction={this.hideCustomerModalAndRefresh} />
                                </div>
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div className="row">
                            <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Ledger</h4></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Balance Due: {this.negateCurrency(results.data[0])}</h4></div>
                        </div>
                        <div className="row clear-both">
                            <div className="ledger-table">
                                <ReactTable
                                    className="-striped -highlight"
                                    data={results.data[1]}
                                    columns={this.ledgerColumns}
                                    defaultPageSize={10}
                                    noDataText="No ledger items found"
                                    defaultFilterMethod={
                                        (filter, row) => {
                                            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div className="row">
                            <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Invoices</h4></div>
                        </div>
                        <EmailInvoice show={customerModalState === 'email-invoice'} invoiceNumber={invoiceNumber} customer={email} customerDetails={customer} onHide={this.hideCustomerModal} afterAction={this.hideCustomerModalAndRefresh} />
                        <div className="row clear-both">
                            <div className="invoices-table">
                                <ReactTable
                                    className="-striped -highlight"
                                    data={results.data[2]}
                                    columns={this.invoicesColumns}
                                    defaultPageSize={10}
                                    noDataText="No invoices found"
                                    defaultFilterMethod={
                                        (filter, row) => {
                                            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div className="row">
                            <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Events</h4></div>
                        </div>
                        <div className="row clear-both">
                            <div className="events-table">
                                <ReactTable
                                    className="-striped -highlight"
                                    data={results.data[7]}
                                    columns={this.eventsColumns}
                                    defaultPageSize={10}
                                    noDataText="No events found"
                                    defaultFilterMethod={
                                        (filter, row) => {
                                            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div className="row">
                            <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Payments</h4></div>
                        </div>
                        <div className="row clear-both">
                            <div className="payments-table">
                                <ReactTable
                                    className="-striped -highlight"
                                    data={results.data[3]}
                                    columns={this.paymentsColumns}
                                    defaultPageSize={10}
                                    noDataText="No payments found"
                                    defaultFilterMethod={
                                        (filter, row) => {
                                            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div className="row">
                            <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Refunds</h4></div>
                        </div>
                        <div className="row clear-both">
                            <div className="refunds-table">
                                <ReactTable
                                    className="-striped -highlight"
                                    data={results.data[6]}
                                    columns={this.refundsColumns}
                                    defaultPageSize={10}
                                    noDataText="No refunds found"
                                    defaultFilterMethod={
                                        (filter, row) => {
                                            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div className="row">
                            <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Credits</h4></div>
                        </div>
                        {customer.status === 'active' && <div className="row">
                            <div className="col-md-12 text-center">
                                <CustomerButton clickHandler={() => { this.showCustomerModal('add-credit'); }} roles={security['add-credit']} name="Add Credit"></CustomerButton>
                            </div>
                            <div>&nbsp;</div>
                            <AddCredit show={customerModalState === 'add-credit'} customer={email} onHide={this.hideCustomerModal} afterAction={this.hideCustomerModalAndRefresh} />
                        </div>}
                        <div className="row clear-both">
                            <div className="credits-table">
                                <ReactTable
                                    className="-striped -highlight"
                                    data={results.data[4]}
                                    columns={this.creditsColumns}
                                    defaultPageSize={10}
                                    noDataText="No credits found"
                                    defaultFilterMethod={
                                        (filter, row) => {
                                            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                        }
                                    }
                                />
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div className="row">
                            <div className="col-md-12 text-center"><h4 className="header-color bolder-text">Charges</h4></div>
                        </div>
                        {customer.status === 'active' && <div className="row">
                            <div className="col-md-12 text-center">
                                <CustomerButton clickHandler={() => { this.showCustomerModal('add-charge'); }} roles={security['add-charge']} name="Add Charge"></CustomerButton>
                            </div>
                            <div>&nbsp;</div>
                            <AddCharge show={customerModalState === 'add-charge'} customer={email} onHide={this.hideCustomerModal} afterAction={this.hideCustomerModalAndRefresh} />
                        </div>}
                        <div className="row clear-both">
                            <div className="charges-table">
                                <ReactTable
                                    className="-striped -highlight"
                                    data={results.data[5]}
                                    columns={this.chargesColumns}
                                    defaultPageSize={10}
                                    noDataText="No charges found"
                                    defaultFilterMethod={
                                        (filter, row) => {
                                            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }

    ledgerColumns = [{
        Header: 'Date',
        id: 'createdAt',
        accessor: d => d.createdAt,
        Cell: row => moment.utc(row.value).format('MMM D, YYYY'),
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 125,
        sortable: true
    }, {
        Header: 'Description',
        id: 'description',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 675,
        sortable: true,
        accessor: d => d.description
    }, {
        Header: 'Amount',
        id: 'amount',
        accessor: d => d.amount,
        Cell: row => <div style={{ textAlign: "right" }}>{numeral(row.value).format('$0.00')}</div>,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 100,
        sortable: true
    }];

    invoicesColumns = [{
        Header: 'Email',
        id: 'email',
        accessor: d => d.number,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 65,
        sortable: false,
        Cell: row => <button className="btn btn-xs btn-primary" onClick={() => {
            this.emailInvoice(row.value);
        }}>Email</button>
    }, {
        Header: 'Date',
        id: 'createdAt',
        accessor: d => d.createdAt,
        Cell: row => moment.utc(row.value).format('MMM D, YYYY'),
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 125,
        sortable: true
    }, {
        Header: 'Number',
        id: 'number',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 100,
        sortable: true,
        accessor: d => d.number
    }, {
        Header: 'Start Date',
        id: 'startDate',
        accessor: d => d.startDate,
        Cell: row => moment.utc(row.value).format('MMM D, YYYY'),
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 170,
        sortable: true
    }, {
        Header: 'End Date',
        id: 'endDate',
        accessor: d => d.endDate,
        Cell: row => moment.utc(row.value).format('MMM D, YYYY'),
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 170,
        sortable: true
    }, {
        Header: 'Amount',
        id: 'amount',
        accessor: d => d.amount,
        Cell: row => <div style={{ textAlign: "right" }}>{numeral(row.value).format('$0.00')}</div>,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 100,
        sortable: true
    }];

    eventsColumns = [{
        Header: 'Date',
        id: 'date',
        accessor: d => d.date,
        Cell: row => moment.utc(row.value).format('MMM D, YYYY'),
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 125,
        sortable: true
    }, {
        Header: 'Event',
        id: 'event',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 425,
        sortable: true,
        filterable: true,
        accessor: d => d.event
    }, {
        Header: 'Details',
        id: 'description',
        accessor: d => d.description,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 750,
        sortable: true,
        filterable: true
    }];

    paymentsColumns = [{
        Header: 'Date',
        id: 'createdAt',
        accessor: d => d.createdAt,
        Cell: row => moment.utc(row.value).format('MMM D, YYYY'),
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 125,
        sortable: true
    }, {
        Header: 'Type',
        id: 'type',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 80,
        sortable: true,
        accessor: d => d.type
    }, {
        Header: 'Amount',
        id: 'amount',
        accessor: d => d.amount,
        headerClassName: 'grid-header',
        Cell: row => <div style={{ textAlign: "right" }}>{numeral(row.value).format('$0.00')}</div>,
        className: 'grid-cell',
        width: 100,
        sortable: true
    }, {
        Header: 'Invoice #',
        id: 'invoiceNumber',
        accessor: d => d.invoiceNumber,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 115,
        sortable: true
    }, {
        Header: 'Merchant',
        id: 'merchant',
        accessor: d => d.merchant,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 115,
        sortable: true
    }, {
        Header: 'Stripe Charge ID',
        id: 'stripeChargeId',
        accessor: d => d.details ? d.details.id : '',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 275,
        sortable: true
    }];

    refundsColumns = [{
        Header: 'Date',
        id: 'createdAt',
        accessor: d => d.createdAt,
        Cell: row => moment.utc(row.value).format('MMM D, YYYY'),
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 125,
        sortable: true
    }, {
        Header: 'Type',
        id: 'type',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 80,
        sortable: true,
        accessor: d => d.type
    }, {
        Header: 'Amount',
        id: 'amount',
        accessor: d => d.amount,
        headerClassName: 'grid-header',
        Cell: row => <div style={{ textAlign: "right" }}>{numeral(row.value).format('$0.00')}</div>,
        className: 'grid-cell',
        width: 100,
        sortable: true
    }, {
        Header: 'Invoice #',
        id: 'invoiceNumber',
        accessor: d => d.invoiceNumber,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 115,
        sortable: true
    }, {
        Header: 'Stripe Refund ID',
        id: 'stripeRefundId',
        accessor: d => d.details ? d.details.id : '',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 275,
        sortable: true
    }];

    creditsColumns = [{
        Header: 'Date',
        id: 'createdAt',
        accessor: d => d.createdAt,
        Cell: row => moment.utc(row.value).format('MMM D, YYYY'),
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 125,
        sortable: true
    }, {
        Header: 'Amount',
        id: 'amount',
        Cell: row => <div style={{ textAlign: "right" }}>{numeral(row.value).format('$0.00')}</div>,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 100,
        sortable: true,
        accessor: d => d.amount
    }, {
        Header: 'Description',
        id: 'description',
        accessor: d => d.description,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 575,
        sortable: true
    }];

    chargesColumns = [{
        Header: 'Date',
        id: 'createdAt',
        accessor: d => d.createdAt,
        Cell: row => moment.utc(row.value).format('MMM D, YYYY'),
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 125,
        sortable: true
    }, {
        Header: 'Amount',
        id: 'amount',
        Cell: row => <div style={{ textAlign: "right" }}>{numeral(row.value).format('$0.00')}</div>,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 100,
        sortable: true,
        accessor: d => d.amount
    }, {
        Header: 'Description',
        id: 'description',
        accessor: d => d.description,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 575,
        sortable: true
    }];
}

const mapStateToProps = (state, ownProps) => ({
    freeViewSecondsAllowed: state.config.freeViewSecondsAllowed
});

export default connect(mapStateToProps)(ViewCustomer);
