import React from 'react';
import { connect } from 'react-redux';

import CreateChannelForm from '../forms/create-channel-form.component';
import toastr from '../services/toastr';
import history from '../services/history';
import * as api from '../services/api';

class CreateChannel extends React.Component {

    back = () => {
        history.push('/channels');
    }

    createChannel = (channel) => {
        var data = JSON.parse(JSON.stringify(channel));
        data.logo = data.logo.key;
        api.createChannel(data).then((data) => {
            toastr.success('New channel created successfully');
            history.push('/channels');
        }).catch((err) => {
            if (err.message === 'ChannelExists') {
                toastr.error('Channel code already exists');
            } else {
                toastr.error('Unable to create channel');
            }
        });
    }

    render() {
        const { channelLogoUrl } = this.props;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="form-box">
                        <div className="text-center">
                            <button onClick={this.back} className="btn btn-lg btn-link">&lt; Back</button>
                        </div>
                        <div className="header header-color center-form uppercase-text">
                            <div>New Channel</div>
                        </div>
                        <div>&nbsp;</div>
                        <CreateChannelForm channelLogoUrl={channelLogoUrl} onSubmit={this.createChannel} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    channelLogoUrl: state.config.channelLogoUrl,
});

export default connect(mapStateToProps)(CreateChannel);
