import React from 'react';
import toastr from 'toastr';
import moment from 'moment';
import ReactTable from 'react-table';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import * as api from '../services/api';

class ApplicationErrors extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            results: [],
            processing: false,
            formError: false,
            selectedError: '',
            touched: false
        };
    }

    columns = [{
        Header: 'View',
        id: 'view',
        accessor: d => d.description,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 50,
        sortable: false,
        Cell: row => <button className="btn btn-xs btn-primary" onClick={() => {
            this.viewError(row.value);
        }}>View</button>
    }, {
        Header: 'Date',
        id: 'createdAt',
        accessor: d => d.createdAt,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 125,
        sortable: true,
        filterable: false,
        Cell: row => moment.utc(row.value).format('MMM D, YYYY'),
    }, {
        Header: 'Description',
        id: 'description',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 700,
        sortable: true,
        filterable: true,
        accessor: d => d.description
    }];

    viewError = (error) => {
        this.setState(() => ({ selectedError: error }));
    };

    onSearchTextChange = (e) => {
        const searchText = e.target.value;
        this.setState(() => ({ searchText }));
        if (searchText.trim().length === 0) {
            this.setState(() => ({ formError: true }));
        } else {
            this.setState(() => ({ formError: false }));
        }
    }

    formSubmit = (e) => {
        e.preventDefault();
        if (this.state.searchText.trim().length === 0) {
            this.setState(() => ({ touched: true }));
            this.setState(() => ({ formError: true }));
            return;
        }
        this.setState(() => ({ processing: true }));
        api.searchErrors(this.state.searchText).then((data) => {
            this.setState(() => ({ results: data }));
            this.setState(() => ({ processing: false }));
        }).catch((err) => {
            this.setState(() => ({ results: [] }));
            this.setState(() => ({ processing: false }));
            if (err.message === 'NoErrorsFound') {
                toastr.error('No matching errors found');
            } else if (err.message === 'TooManyErrorsFound') {
                toastr.error('Too many results found. Please refine your search.');
            } else {
                toastr.error('Error fetching matching errors');
            }
        });
    }

    onBlur = () => {
        this.setState(() => ({ touched: true }));
    }

    onCopy = () => {
        toastr.success('Error copied to clipboard successfully');
    }

    render() {
        const { results, processing, formError, selectedError, touched } = this.state;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="container">
                        <div className="col-md-12 text-center uppercase-text bolder-text">
                            <h2 className="header-color">Application Errors</h2>
                        </div>
                        <div>&nbsp;</div>
                        <div className="form-box">
                            <form name="form" id="form" onSubmit={this.formSubmit} noValidate autoComplete="off">
                                <span className="label-text">Search for text in Errors collection</span>
                                <br />
                                <input autoComplete="off" type="text" maxLength="100" name="searchText" onBlur={this.onBlur} onChange={this.onSearchTextChange} className="text-box" />
                                {formError && touched && <div className="error-message">Enter search text</div>}
                                <br />
                                <br />
                                <button disabled={processing} type="submit" className="submit-button">Search</button>
                            </form>
                        </div>
                        {results.length > 0 &&
                            <div>
                                <div>
                                    &nbsp;
                                </div>
                                <div>
                                    <div className="application-errors-table">
                                        <ReactTable
                                            className="-striped -highlight"
                                            data={results}
                                            columns={this.columns}
                                            defaultPageSize={10}
                                            noDataText="No errors found"
                                            defaultFilterMethod={
                                                (filter, row) => {
                                                    return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                <div>&nbsp;</div>
                                <div className="application-errors-table">
                                    <div className="input-group">
                                        <textArea readOnly className="form-control application-errors-text-area" id="error" name="error">
                                            {selectedError}
                                        </textArea>
                                        <span className="input-group-btn">
                                            <CopyToClipboard onCopy={this.onCopy} text={selectedError}>
                                                <button className="btn btn-primary application-errors-copy-btn" type="button">Copy</button>
                                            </CopyToClipboard>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ApplicationErrors;
