import React from 'react';
import ReactTable from 'react-table';
import toastr from 'toastr';

import * as api from '../services/api';
import history from '../services/history';

class AdminUsers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            results: []
        };
    }

    componentDidMount() {
        api.getAllAdminUsers().then((data) => {
            this.setState(() => ({ results: data }));
        }).catch(() => {
            toastr.error('Error fetching admin users');
        });
    }

    columns = [{
        Header: 'Edit',
        id: 'edit',
        accessor: d => d.email,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 50,
        sortable: false,
        Cell: row => <button className="btn btn-xs btn-primary" onClick={() => {
            history.push('/edit-admin-user/' + row.value);
        }}>Edit</button>
    }, {
        Header: 'Status',
        id: 'disabled',
        accessor: d => d.disabled ? 'Disabled' : 'Active',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 125,
        sortable: true,
        filterable: true,
        filterMethod: (filter, row) => {
            if (filter.value === "All") {
                return true;
            }
            return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) =>
            <select
                onChange={event => onChange(event.target.value)}
                className="grid-filter-dropdown"
                value={filter ? filter.value : "All"}
            >
                <option value="All"></option>
                <option value="Active">Active</option>
                <option value="Disabled">Disabled</option>
            </select>

    }, {
        Header: 'Name',
        id: 'fullName',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 300,
        sortable: true,
        filterable: true,
        accessor: d => d.firstName + ' ' + d.lastName
    }, {
        Header: 'Email',
        id: 'email',
        accessor: d => d.email,
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 250,
        filterable: true,
        sortable: true
    }, {
        Header: 'Role',
        id: 'role',
        headerClassName: 'grid-header',
        className: 'grid-cell',
        width: 150,
        sortable: true,
        filterable: true,
        accessor: d => this.capitalize(d.role.title),
        filterMethod: (filter, row) => {
            if (filter.value === "All") {
                return true;
            }
            return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) =>
            <select
                onChange={event => onChange(event.target.value)}
                className="grid-filter-dropdown"
                value={filter ? filter.value : "All"}
            >
                <option value="All"></option>
                <option value="Support">Support</option>
                <option value="Operations">Operations</option>
                <option value="Marketing">Marketing</option>
                <option value="Finance">Finance</option>
                <option value="Management">Management</option>
                <option value="Administrator">Administrator</option>
            </select>
    }];

    capitalize(value) {
        if (value && value.length > 1) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        if (value && value.length === 1) {
            return value.charAt(0).toUpperCase();
        }
        return value;
    }

    render() {
        const { results } = this.state;
        return (
            <div className="content-area">
                <div className="content-box">
                    <div className="container">
                        <div>&nbsp;</div>
                        <div className="header header-color center-form uppercase-text">
                            <div>Admin Users</div>
                        </div>
                        <div className="sub-header header-color">
                            &nbsp;
                        </div>
                        <div className="col-lg-12 text-center">
                            <button className="btn btn-primary" onClick={() => {
                                history.push('/create-admin-user');
                            }}>New Admin User</button>
                        </div>
                        <div>
                            &nbsp;
                        </div>
                        <div>
                            <div className="admin-users-table">
                                <ReactTable
                                    className="-striped -highlight"
                                    data={results}
                                    columns={this.columns}
                                    defaultPageSize={10}
                                    noDataText="No admin users found"
                                    defaultFilterMethod={
                                        (filter, row) => {
                                            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminUsers;
