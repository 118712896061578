import React from 'react';
import { Field, reduxForm } from 'redux-form';
import MaskInput from 'react-maskinput';

import * as validations from '../services/validations';
import * as utils from '../services/utils';
import * as api from '../services/api';
import toastr from '../services/toastr';

const validate = (values) => {
    const errors = {};
    if (validations.isEmpty(values.cardName)) {
        errors.cardName = 'Enter name as on credit card';
    } else if (!validations.isName(values.cardName)) {
        errors.cardName = 'Enter a valid name';
    }
    if (validations.isEmpty(values.cardNumber)) {
        errors.cardNumber = 'Enter credit card number';
    } else if (!validations.isCreditCard(values.cardNumber)) {
        errors.cardNumber = 'Enter a valid credit card number';
    }
    if (validations.isEmpty(values.expiryDate)) {
        errors.expiryDate = 'Enter credit card expiry date';
    } else if (!validations.isExpiryDate(values.expiryDate)) {
        errors.expiryDate = 'Enter a valid credit card expiry date';
    }
    if (validations.isEmpty(values.cvv)) {
        errors.cvv = 'Enter CVV';
    } else if (!validations.isCvv(values.cvv, values.cardNumber)) {
        errors.cvv = 'Enter a valid CVV';
    }
    if (validations.isEmpty(values.address)) {
        errors.address = 'Enter address as on credit card';
    } else if (!validations.isAddress(values.address)) {
        errors.address = 'Enter a valid address';
    }
    if (validations.isEmpty(values.city)) {
        errors.city = 'Enter city as on credit card';
    } else if (!validations.isAddress(values.city)) {
        errors.city = 'Enter a valid city';
    }
    if (validations.isEmpty(values.state)) {
        errors.state = 'Enter state as on credit card';
    }
    if (validations.isEmpty(values.zipCode)) {
        errors.zipCode = 'Enter zip code as on credit card';
    } else if (!validations.isZipCode(values.zipCode)) {
        errors.zipCode = 'Enter a valid zip code';
    }
    return errors;
};

const renderField = ({ input, type, maxLength, meta: { touched, error } }) => {
    return (
        <div>
            <input {...input} autoComplete="off" maxLength={maxLength} type={type} className={"text-box " + (touched && error ? "text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const renderMaskedField = ({ input, type, mask, cardType, maxLength, meta: { touched, error } }) => {
    if (!cardType) {
        cardType = '';
    }
    return (
        <div>
            <MaskInput {...input} mask={mask} autoComplete="off" maxLength={maxLength} type={type} className={"text-box " + cardType + (touched && error ? " text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const renderDropDown = ({ input, options, defaultOptionText, meta: { touched, error } }) => {
    return (
        <div>
            <select {...input} className={"text-box drop-down-list " + (touched && error ? "text-box-error" : "")}>
                <option value="">{defaultOptionText}</option>
                {
                    options.map((opt, i) => {
                        return <option value={opt.code} key={i}>{opt.name}</option>;
                    })
                }
            </select>
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

class ChangeCreditCardForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            states: [],
            cardNumberMask: '0000 0000 0000 0000',
            cardType: ''
        };
    }

    componentDidMount() {
        api.getStates().then((data) => {
            this.setState(() => ({ states: data }));
        }).catch(() => {
            toastr.error('Error fetching states');
        });
    }

    onCardNumberChange = (e) => {
        const cardNumber = e.target.value;
        if (cardNumber && (cardNumber.startsWith('34') || cardNumber.startsWith('37'))) {
            this.setState(() => ({ cardNumberMask: '0000 000000 00000' }));
        } else {
            this.setState(() => ({ cardNumberMask: '0000 0000 0000 0000' }));
        }
        const cardType = utils.getCardType(cardNumber);
        if (this.state.cardType !== cardType) {
            this.setState(() => ({ cardType }));
        }
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        const { states, cardNumberMask, cardType } = this.state;
        return (
            <form onSubmit={handleSubmit} autoComplete="off">
                <span className="label-text">Name on credit card</span>
                <br />
                <Field name="cardName" type="text" maxLength="128" component={renderField} />
                <br />
                <span className="label-text">Credit card number</span>
                <br />
                <Field name="cardNumber" mask={cardNumberMask} cardType={cardType} onChange={this.onCardNumberChange} maxLength="19" type="text" component={renderMaskedField} />
                <br />
                <span className="label-text">Expiration date (MM/YYYY)</span>
                <br />
                <Field name="expiryDate" mask="00 / 0000" maxLength="7" type="text" component={renderMaskedField} />
                <br />
                <span className="label-text">CVV (3 digit security code)</span>
                <br />
                <Field name="cvv" maxLength="4" type="password" component={renderField} />
                <br />
                <span className="label-text">Address</span>
                <br />
                <Field name="address" maxLength="80" type="text" component={renderField} />
                <br />
                <span className="label-text">City</span>
                <br />
                <Field name="city" maxLength="80" type="text" component={renderField} />
                <br />
                <span className="label-text">State</span>
                <br />
                <Field name="state" options={states} defaultOptionText="Select state" component={renderDropDown} />
                <br />
                <span className="label-text">Zip code</span>
                <br />
                <Field name="zipCode" maxLength="5" type="text" component={renderField} />
                <br />
                <button type="submit" disabled={submitting} className="submit-button">Submit</button>
                <br />
                <div>&nbsp;</div>
                {submitting && <div className="progress progress-bar-width">
                    <div className="progress-bar progress-bar-striped active progress-bar-completion-width" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                    </div>
                </div>}
            </form>
        );
    }
};

export default reduxForm({ form: 'changeCreditCard', validate })(ChangeCreditCardForm);
