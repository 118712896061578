import React from 'react';

import BigNavButton from '../widgets/big-nav-button.component';
import security from '../services/security';

const ContentManagement = () => (
    <div className="content-area">
        <div className="content-box">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center uppercase-text bolder-text">
                        <h2 className="header-color">Content Management</h2>
                    </div>
                </div>
                <div className="row">&nbsp;</div>
                <BigNavButton button name="Channels" link="/channels" roles={security["channels"]} />
                <BigNavButton button name="Channel Logos" link="/channel-logos" roles={security["channel-logos"]} />
                <BigNavButton button name="Channel Tags" link="/channel-tags" roles={security["channel-tags"]} />
                <BigNavButton button name="VODs" link="/vods" roles={security["vods"]} />
            </div>
        </div>
    </div>
);

export default ContentManagement;
