import React from 'react';
import { connect } from 'react-redux';

class PasswordStrengthBar extends React.Component {

    constructor(props) {
        super(props);
        this.colors = ['#F00', '#F90', '#E6E600', '#33CC33', '#1CAF9A'];
    }

    getBarColors(password) {
        let bars = this.setBarColors([], 5, '#DDD');
        const strength = this.getStrengthIndexAndColor(password);
        this.setBarColors(bars, strength.index, strength.color);
        return bars;
    }

    setBarColors(bars, count, color) {
        for (let i = 0; i < count; i++) {
            bars[i] = color;
        }
        return bars;
    }

    getStrengthIndexAndColor(password) {
        return this.getColor(this.measureStrength(password));
    }

    getColor(force) {
        let index = 0;
        if (force <= 10) {
            index = 0;
        } else if (force <= 20) {
            index = 1;
        } else if (force <= 30) {
            index = 2;
        } else if (force <= 40) {
            index = 3;
        } else {
            index = 4;
        }
        return { index: index + 1, color: this.colors[index] };
    }

    measureStrength(password) {
        if (!password) {
            return 5;
        }
        const regex = /[$*()\\=+@%&#-/:-?{-~!"^_`[\]]/g;
        const lowerLetters = /[a-z]+/.test(password);
        const upperLetters = /[A-Z]+/.test(password);
        const numbers = /[0-9]+/.test(password);
        const symbols = regex.test(password);
        const flags = [lowerLetters, upperLetters, numbers, symbols];
        const passedMatches = flags.filter(flag => {
            return flag === true;
        }).length;
        if (password.length < 6 || passedMatches === 0) {
            return 5;
        } else if (passedMatches === 1) {
            return 15;
        } else if (passedMatches === 2) {
            return 25;
        } else if (passedMatches === 3) {
            return 35;
        }
        return 45;
    }

    render() {
        const bars = this.getBarColors(this.props.passwordValue);
        return (
            <div id="strength">
                <ul id="strengthBar">
                    <li className="point" style={{backgroundColor: bars[0]}}></li>
                    <li className="point" style={{backgroundColor: bars[1]}}></li>
                    <li className="point" style={{backgroundColor: bars[2]}}></li>
                    <li className="point" style={{backgroundColor: bars[3]}}></li>
                    <li className="point" style={{backgroundColor: bars[4]}}></li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    passwordValue: state.form[ownProps.barForm] ? state.form[ownProps.barForm].values ? state.form[ownProps.barForm].values[ownProps.barField] : '' : ''
});

export default connect(mapStateToProps, undefined)(PasswordStrengthBar);
