import React from 'react';
import { Field, reduxForm } from 'redux-form';

import * as validations from '../services/validations';

const validate = (values) => {
    const errors = {};
    if (validations.isEmpty(values.amount)) {
        errors.amount = 'Enter the amount to be credited';
    } else if (!validations.isAmount(values.amount)) {
        errors.amount = 'Enter a valid amount';
    } else if (parseFloat(values.amount, 10) < 0.01 || parseFloat(values.amount, 10) > 1000) {
        errors.amount = 'Enter an amount between 0.01 and 1000';
    }
    if (validations.isEmpty(values.description)) {
        errors.description = 'Enter a reason';
    } else if (!validations.isName(values.description)) {
        errors.description = 'Enter a valid reason';
    }
    return errors;
};

const renderField = ({ input, type, maxLength, meta: { touched, error } }) => {
    return (
        <div>
            <input {...input} autoComplete="off" maxLength={maxLength} type={type} className={"text-box " + (touched && error ? "text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const AddCreditForm = (props) => {
    const { handleSubmit, submitting } = props;
    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <span className="label-text">Amount to be credited ($)</span>
            <br />
            <Field name="amount" type="text" maxLength="7" component={renderField} />
            <br />
            <span className="label-text">Reason for credit</span><br />
            <Field name="description" maxLength="1000" type="text" component={renderField} />
            <br />
            <button type="submit" disabled={submitting} className="submit-button">Add</button>
        </form>
    );
};

export default reduxForm({ form: 'addCredit', validate })(AddCreditForm);
