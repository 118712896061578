import React from 'react';
import { Field, reduxForm } from 'redux-form';

import * as validations from '../services/validations';
import PasswordStrengthBar from '../widgets/password-strength-bar.component';

const validate = (values) => {
    const errors = {};
    if (validations.isEmpty(values.password)) {
        errors.password = 'Enter your password';
    } else if (!validations.isStrongPassword(values.password)) {
        errors.password = 'Password needs to be at least 6 characters, contain 1 uppercase letter and 1 number';
    };
    return errors;
};

const renderField = ({ input, type, maxLength, passwordStrengthBar, meta: { touched, error } }) => {
    return (
        <div>
            <input {...input} autoComplete="off" maxLength={maxLength} type={type} className={"text-box " + (touched && error ? "text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
            {passwordStrengthBar && <PasswordStrengthBar barForm={passwordStrengthBar[0]} barField={passwordStrengthBar[1]} />}
        </div>
    );
};

const ResetPasswordForm = (props) => {
    const { handleSubmit, submitting } = props;
    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <span className="label-text">Password</span><br />
            <Field name="password" maxLength="20" type="password" component={renderField} passwordStrengthBar={['resetPassword', 'password']} />
            <br />
            <button type="submit" disabled={submitting} className="submit-button">Submit</button>
        </form>
    );
};

export default reduxForm({ form: 'resetPassword', validate })(ResetPasswordForm);
