import React from 'react';
import { Field, reduxForm } from 'redux-form';

import * as validations from '../services/validations';

const validate = (values) => {
    const errors = {};
    if (validations.isEmpty(values.code)) {
        errors.code = 'Enter channel tag value code';
    } else if (!validations.isChannelCode(values.code)) {
        errors.code = 'Enter a valid channel tag value code in uppercase';
    }
    if (validations.isEmpty(values.name)) {
        errors.name = 'Enter channel tag value name';
    } else if (!validations.isName(values.name)) {
        errors.name = 'Enter a valid channel tag value name';
    }
    return errors;
};

const renderField = ({ input, type, maxLength, meta: { touched, error } }) => {
    return (
        <div>
            <input {...input} autoComplete="off" maxLength={maxLength} type={type} className={"text-box " + (touched && error ? "text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const CreateChannelTagValueForm = (props) => {
    const { handleSubmit, submitting, parent } = props;
    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <span className="label-text">Channel Tag Code</span>
            <br />
            <input name="parentCode" className="text-box" type="text" maxLength="4" value={parent.parentCode} readOnly="true" />
            <br/>
            <br />
            <span className="label-text">Channel Tag Name</span>
            <br />
            <input name="parentName" className="text-box" type="text" maxLength="20" value={parent.parentName} readOnly="true" />
            <br />
            <br />
            <span className="label-text">Channel Tag Value Code</span>
            <br />
            <Field name="code" type="text" maxLength="4" component={renderField} />
            <br />
            <span className="label-text">Channel Tag Value Name</span>
            <br />
            <Field name="name" maxLength="20" type="text" component={renderField} />
            <br />
            <button type="submit" disabled={submitting} className="submit-button">Create Channel Tag Value</button>
        </form>
    );
};

export default reduxForm({ form: 'createChannelTagValue', validate })(CreateChannelTagValueForm);
