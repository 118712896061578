import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { DropdownList } from 'react-widgets';

import * as validations from '../services/validations';
import * as api from '../services/api';
import toastr from '../services/toastr';

const types = [
    { value: 'Radio', text: 'Radio' },
    { value: 'TV', text: 'TV' }
];

const validate = (values) => {
    const errors = {};
    if (validations.isEmpty(values.code)) {
        errors.code = 'Enter channel code';
    } else if (!validations.isChannelCode(values.code)) {
        errors.code = 'Enter a valid channel code in uppercase';
    }
    if (validations.isEmpty(values.name)) {
        errors.name = 'Enter channel name';
    } else if (!validations.isChannelName(values.name)) {
        errors.name = 'Enter a valid channel name';
    }
    if (validations.isEmpty(values.url)) {
        errors.url = 'Enter channel URL';
    } else if (!validations.isUrl(values.url)) {
        errors.url = 'Enter a valid channel URL';
    }
    if (validations.isEmpty(values.order)) {
        errors.order = 'Enter channel order';
    } else if (!validations.isInteger(values.order)) {
        errors.order = 'Enter a valid channel order';
    }
    if (!validations.isEmpty(values.metadataId) && !validations.isInteger(values.metadataId)) {
        errors.metadataId = 'Enter a valid Gracenote ID';
    }
    if (validations.isEmpty(values.type)) {
        errors.type = 'Select a channel type';
    }
    if (validations.isEmpty(values.logo ? values.logo.key : null)) {
        errors.logo = 'Select a channel logo';
    }
    if (validations.isEmpty(values.genre)) {
        errors.genre = 'Select a channel genre';
    }
    if (validations.isEmpty(values.language)) {
        errors.language = 'Select a channel language';
    }
    if (validations.isEmpty(values.origin)) {
        errors.origin = 'Select a channel origin';
    }
    if (validations.isEmpty(values.audience)) {
        errors.audience = 'Select a channel audience';
    }
    return errors;
};

const renderField = ({ input, type, maxLength, readOnly, meta: { touched, error } }) => {
    return (
        <div>
            <input {...input} autoComplete="off" readOnly={readOnly} maxLength={maxLength} type={type} className={"text-box " + (touched && error ? "text-box-error" : "")} />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const renderDropDown = ({ input, options, dataKey, text, defaultOptionText, meta: { touched, error } }) => {
    return (
        <div>
            <select {...input} className={"text-box drop-down-list " + (touched && error ? "text-box-error" : "")}>
                <option value="">{defaultOptionText}</option>
                {
                    options.map((opt, i) => {
                        return <option value={opt[dataKey]} key={i}>{opt[text]}</option>;
                    })
                }
            </select>
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

const ListItem = ({ item }) => (
    <span>
        <img alt="" width="25%" src={item.url} />
        <span>{item.key ? item.key : 'Select channel logo'}</span>
    </span>
);

const renderDropDownList = ({ input, options, dataKey, text, meta: { touched, error } }) => {
    return (
        <div>
            <DropdownList {...input} className={(touched && error ? "rw-widget-error" : "")}
                data={options}
                valueField={dataKey}
                textField={text}
                itemComponent={ListItem}
                onChange={input.onChange} />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

class EditChannelForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            genres: [],
            languages: [],
            origins: [],
            audiences: []
        };
    }

    componentDidMount() {
        api.getAllChannelTags().then((data) => {
            let values;
            const genres = data.find((i) => i.code === 'GENR');
            if (genres) {
                values = genres.values;
                values.sort(this.alphabeticalSort);
                this.setState(() => ({ genres: values }));
            }
            const languages = data.find((i) => i.code === 'LANG');
            if (languages) {
                values = languages.values;
                values.sort(this.alphabeticalSort);
                this.setState(() => ({ languages: values }));
            }
            const origins = data.find((i) => i.code === 'ORGN');
            if (origins) {
                values = origins.values;
                values.sort(this.alphabeticalSort);
                this.setState(() => ({ origins: values }));
            }
            const audiences = data.find((i) => i.code === 'AUDC');
            if (audiences) {
                values = audiences.values;
                values.sort(this.alphabeticalSort);
                this.setState(() => ({ audiences: values }));
            }
        }).catch(() => {
            toastr.error('Error fetching channel tags');
        });
    }

    alphabeticalSort = (a, b) => {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    render() {
        const { logos, handleSubmit, submitting, logo, channelLogoUrl } = this.props;
        let logoList = logos.map((i) => ({ key: i.Key, text: i.Key, url: channelLogoUrl + i.Key }));
        logoList.unshift({ key: '', text: 'Select channel logo', url: '' });
        const { genres, languages, origins, audiences } = this.state;
        const logoStyle = {
            backgroundSize: 'auto 100%',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: '98%',
            backgroundPositionY: '50%',
            backgroundPosition: '98% 50%',
            backgroundImage: (logo ? 'url(' + channelLogoUrl + logo + ')' : 'none')
        };
        return (
            <form onSubmit={handleSubmit} autoComplete="off">
                <div className="full-form">
                    <span className="label-text">Code</span>
                    <br />
                    <Field name="code" maxLength="4" type="text" readOnly={true} component={renderField} />
                </div>
                <div className="full-form">
                    <span className="label-text">Name</span><br />
                    <Field name="name" maxLength="30" type="text" component={renderField} />
                </div>
                <div className="full-form">
                    <span className="label-text">URL</span><br />
                    <Field name="url" maxLength="512" type="text" component={renderField} />
                </div>
                <div className="full-form">
                    <span className="label-text">Order</span><br />
                    <Field name="order" maxLength="10" type="text" component={renderField} />
                    <span className="label-text-info">(1000 for alphabetical ordering, less than 1000 for top and greater than 1000 for bottom)</span>
                </div>
                <div className="full-form">
                    <span className="label-text">Gracenote Station ID</span><br />
                    <Field name="metadataId" maxLength="10" type="text" component={renderField} />
                </div>
                <div className="full-form position-relative">
                    <div className="logo-holder" style={logoStyle}></div>
                    <span className="label-text">Logo</span><br />
                    <Field name="logo" options={logoList} dataKey="key" text="text" defaultOptionText="Select channel logo" component={renderDropDownList} />
                </div>
                <div className="full-form">
                    <span className="label-text">Type</span><br />
                    <Field name="type" options={types} dataKey="value" text="text" defaultOptionText="Select channel type" component={renderDropDown} />
                </div>
                <div className="full-form">
                    <span className="label-text">Genre</span><br />
                    <Field name="genre" options={genres} dataKey="code" text="name" defaultOptionText="Select channel genre" component={renderDropDown} />
                </div>
                <div className="full-form">
                    <span className="label-text">Language</span><br />
                    <Field name="language" options={languages} dataKey="code" text="name" defaultOptionText="Select channel language" component={renderDropDown} />
                </div>
                <div className="full-form">
                    <span className="label-text">Origin</span><br />
                    <Field name="origin" options={origins} dataKey="code" text="name" defaultOptionText="Select channel origin" component={renderDropDown} />
                </div>
                <div className="full-form">
                    <span className="label-text">Audience</span><br />
                    <Field name="audience" options={audiences} dataKey="code" text="name" defaultOptionText="Select channel audience" component={renderDropDown} />
                </div>
                <div className="full-form">
                    <button type="submit" disabled={submitting} className="submit-button">Submit</button>
                </div>
            </form>
        );
    };
}

const mapStateToProps = (state, ownProps) => ({
    logos: state.channelLogos
});

EditChannelForm = reduxForm({ form: 'editChannel', validate, enableReinitialize: true })(connect(mapStateToProps)(EditChannelForm));

const selector = formValueSelector('editChannel');
EditChannelForm = connect(state => {
    const logo = selector(state, 'logo');
    return {
        logo: typeof logo === 'string' ? logo : typeof logo === 'undefined' ? null : logo.key
    };
})(EditChannelForm);

export default EditChannelForm;
